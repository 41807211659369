import { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { GlobalContext } from "../component/GlobalContext";
import { useParams, Link, useLocation } from "react-router-dom";
import TopButtons from "../component/TopButtons";
import Table from "../component/Table";
import Error from "./Error";

import { resetSchema } from "../actions";

import clipboard from "../resources/clipboard.png";
import run from "../resources/run.png";
import codeCopied from "../resources/copied.png";
import reset from "../resources/reset.png";
import section from "../resources/section.png";
import goTop from "../resources/go_top.png";
import pinFull from "../resources/pin_full.png";
import pinEmpty from "../resources/pin_empty.png";
import "./Wiki.css";

const ScrollTop = ({ bodyRef }) => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const scrolled = bodyRef.current.scrollTop;
    setVisible(scrolled > 250);
  };

  const handleScrollToTop = () => {
    bodyRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    bodyRef.current.addEventListener("scroll", handleScroll);
  }, [bodyRef]);

  return (
    <div className={`go-top ${visible ? "show" : "hide"}`}>
      <img src={goTop} alt="go-top-img" onClick={handleScrollToTop}></img>
    </div>
  );
};

const Index = ({ index, introduction }) => {
  const handleScroll = (e) => {
    const id = e.target.id;
    const sectionTop = document
      .getElementById(`section-${id}`)
      .getBoundingClientRect().top;
    const headerHeight = document
      .getElementById("web-header")
      .getBoundingClientRect().height;

    if (sectionTop) {
      document.getElementById("wiki-body").scrollTo({
        top: sectionTop - headerHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="index-container">
      <div className="index">
        <h2 className="index-title">Index</h2>
        {index.map((item, i) => (
          <Link to={item.id} className="index-elem-container" key={`div-${i}`}>
            <img
              src={section}
              alt="index-bullet"
              key={`img-${i}`}
              id={item.id}
              onClick={handleScroll}
            />
            <p
              key={`p-${i}`}
              id={item.id}
              className="index-elem"
              onClick={handleScroll}
            >
              {item.title}
              <br key={`br-${i}`} />
            </p>
          </Link>
        ))}
      </div>
      {introduction}
    </div>
  );
};

const Grammar = ({ grammar }) => {
  return (
    <div className="grammar-container">
      <div className="grammar">
        {grammar.map((item, itemIndex) => {
          return (
            <div key={itemIndex} className="sub-grammar">
              <div key={itemIndex + 1} className="grammar-top">
                <span key={itemIndex + 2}>{item.nonterminal}</span>
              </div>
              <div key={itemIndex + 3} className="grammar-children">
                {item.terminals.map((terminal, termIndex) => {
                  return <p key={termIndex}> {terminal}</p>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CodeExample = ({ language, commands, id }) => {
  const { userInfo, runCode } = useContext(GlobalContext);
  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState(commands);
  const message = useRef([]);
  const [showMessage, setShowMessage] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [pinned, setPinned] = useState(false);
  const timeOut = useRef(null);
  const [update, setUpdate] = useState(false);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error al copiar el texto:", error);
      });
  };

  const handleMouseEnter = () => {
    clearTimeout(timeOut.current);
    setShowDiv(true);
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    if (showMessage && !pinned) {
      setShowMessage(false);
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        setShowDiv(false);
        message.current = [];
      }, 1000);
    }
  };

  const fetchOnce = async (command) => {
    await runCode({
      code: command,
      token: userInfo.token,
    })
      .then(async (data) => {
        message.current.push(
          <p
            className="response-message"
            key={`runCodeMsg-${message.current.length}`}
          >
            {data.msg}
            <br />
          </p>
        );
        if (data.output && data.output.length !== 0) {
          const keys = [];
          for (const k in data.output[0]) {
            keys.push(k);
          }
          message.current.push(
            <Table
              output={data.output}
              keys={keys}
              key={`runCodeTable-${message.current.length}`}
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
        message.current.push(
          <p
            className="response-message"
            key={`runCodeMsg-${message.current.length}`}
          >
            {err.toString()}
          </p>
        );
      });
  };

  const setMessage = () => {
    setUpdate(!update);
    setShowMessage(true);
    setShowDiv(true);
    clearTimeout(timeOut.current);
    if (!pinned) {
      timeOut.current = setTimeout(() => {
        setShowMessage(false);
        clearTimeout(timeOut.current);
        timeOut.current = setTimeout(() => {
          message.current = [];
          setShowDiv(false);
        }, 1000);
      }, 3500);
    }
  };

  const handleRunCode = async () => {
    if (id === "multiline") {
      const commands = code.split("\n");
      for (let i = 0; i < commands.length; i++) {
        if (commands[i] !== "") {
          await fetchOnce(commands[i], i);
        } else {
          message.current.push(
            <p className="response-message" key={`blank-space-${i}`}>
              <br key={`blank-space-${-i}`} />
            </p>
          );
        }
      }
    } else {
      await fetchOnce(code.replace(/\n/g, "").replace(/\t/g, ""), 1);
    }
    setMessage();
  };

  const handleReset = async () => {
    await resetSchema({ token: userInfo.token }).then((data) => {
      message.current.push(
        <p
          className="response-message"
          key={`runCodeMsg-${message.current.length}`}
        >
          {data.msg}
        </p>
      );
      setMessage();
    });
  };

  const handleSetPinned = () => {
    setPinned(!pinned);
    clearTimeout(timeOut.current);
    if (pinned) {
      setShowMessage(false);
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        message.current = [];
        setShowDiv(false);
      }, 1000);
    } else {
      handleMouseEnter();
    }
  };

  return (
    <div className="code-tester-container">
      <header>
        <div className="code-header">
          <label className="code-header-label">{language}</label>
          <div className="header-actions">
            <div
              className="code-header-btn"
              onClick={handleSetPinned}
              title={`${
                pinned
                  ? "Hide messages automatically"
                  : "Pin the result messages"
              }`}
            >
              <img src={pinned ? pinFull : pinEmpty} alt="clipboard" />
            </div>
            <div className="code-header-btn rdb" onClick={handleReset}>
              <img src={reset} alt="reset" />
              Reset DB
            </div>
            <div className="code-header-btn" onClick={handleCopyCode}>
              <img src={copied ? codeCopied : clipboard} alt="clipboard" />
              {copied ? "Code copied!" : "Copy code!"}
            </div>
            <div className="code-header-btn rcb" onClick={handleRunCode}>
              <img src={run} alt="run" />
              Run code!
            </div>
          </div>
        </div>
      </header>
      <div id={id} className="code">
        <textarea
          className="code-command"
          rows={9}
          onChange={handleCodeChange}
          defaultValue={code}
        />
      </div>
      <div className="code-footer">
        {showDiv && (
          <motion.div
            className="show-message"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseEnter}
            initial={{ opacity: 1 }}
            animate={{ opacity: showMessage ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            {message.current.map((item) => item)}
          </motion.div>
        )}
      </div>
    </div>
  );
};

const SQLExample = ({ language, commands, id }) => {
  const [copied, setCopied] = useState(false);
  const [code, setCode] = useState(commands);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error al copiar el texto:", error);
      });
  };

  return (
    <div className="code-tester-container">
      <header>
        <div className="code-header">
          <label className="code-header-label">{language}</label>
          <div className="header-actions">
            <div className="code-header-btn" onClick={handleCopyCode}>
              <img src={copied ? codeCopied : clipboard} alt="clipboard" />
              {copied ? "Code copied!" : "Copy code!"}
            </div>
          </div>
        </div>
      </header>
      <div id={id} className="code">
        <textarea
          className="code-command"
          rows={9}
          onChange={handleCodeChange}
          defaultValue={code}
        />
      </div>
    </div>
  );
};

const Quote = ({ quote, author }) => {
  return (
    <div className="quote-container">
      <div className="quote-wrapper">
        <div className="quote-bar"></div>
        <p className="quote-text">{quote}</p>
      </div>
      <p className="quote-author">{author}</p>
    </div>
  );
};

const Sidebar = ({ componentPath, wikiSections, wikiComponents }) => {
  return (
    <div className="summary-container">
      <ul className="summary">
        {wikiSections.map((item, index) => {
          return (
            <div key={`section-div-${index}`}>
              <p className="section" key={`wiki-section-${index}`}>
                {item}
              </p>
              {wikiComponents.map((comp, index) => {
                if (comp.section === item) {
                  return (
                    <Link to={comp.url} key={`component-link-${index}`}>
                      <li
                        className={`section-elem ${
                          componentPath === comp.path ? "selected" : ""
                        }`}
                        key={`wiki-component-${index}`}
                      >
                        {comp.title}
                      </li>
                    </Link>
                  );
                }
                return;
              })}
            </div>
          );
        })}
      </ul>
    </div>
  );
};

const WikiSection = ({ id, title, elems }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="wiki-section" id={id}>
      <div className="section-title">
        <img
          src={section}
          alt="section-arrow"
          className={`arrow ${isExpanded ? "rotate" : ""}`}
          onClick={toggleExpanded}
        />
        <h3 className="wiki-subt" onClick={toggleExpanded}>
          {title}
        </h3>
      </div>
      <span className="line" />
      <div className={`elems-section ${isExpanded ? "show" : "hide"}`}>
        {elems}
      </div>
    </section>
  );
};

const Introduction = () => {
  const introduction = [
    <p className="wiki-text" key="introduction-1">
      Back to the old times in IT (this is just about 40 or 50 years ago)
      programmers used to write... programs. <br />
    </p>,
    <p className="wiki-text" key={"introduction-2"}>
      Each program was a single, solid, monolitic unit performing a concrete
      process among many others. In a scenario dominated by batch processing,
      programs used to read an input and write an output, normally stored in
      files, even on tape or hard disk. Interactive programs were later designed
      in a similar way, where the terminal was seen just as another input (the
      keyboard) or output (the screen) device.
      <br />
    </p>,
    <p className="wiki-text" key={"introduction-3"}>
      But interactive programs presented also other challenges: end users were
      working simultaneously, performing small units of work in comparison to
      the large transactions of batch processing. File locking and consistency
      assurance needed to be handled repeatedly among all the programs accessing
      the same files.
      <br />
      <br />
    </p>,
  ];

  const index = [
    {
      id: 1,
      title: "SQL to the rescue",
    },
    {
      id: 2,
      title: "OOP to the rescue",
    },
    {
      id: 3,
      title: "What are we trying to solve?",
    },
    {
      id: 4,
      title: "FQL to the rescue",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">A bit of history</h2>
      <br />
      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="SQL to the rescue"
        elems={[
          <p className="wiki-text" key={1}>
            At the beginning of the 70s, databases were born, coming to solve
            these issues and standardize data access for all programs. No more
            need to ensure consistency on each program, you just declare it once
            and it will be ensured by the database management system.
            Interestingly, the new solution also needed to elaborate new
            concepts and solve problems that arise only when trying to resolve
            something from a new perspective. By solving data access issues in a
            general way, vague concepts needed to be precisely defined and new
            ones came on place. Transactions and the need for characteristics
            like atomicity and isolation became then clear whilst consistency
            and durability were already well-known since working with random
            files.
            <br />
            <br />
          </p>,
          <h2 className="wiki-subt" key={10}>
            Same old problem
          </h2>,
          <p className="wiki-text" key={11}>
            Did it ever happen to you, to let your room or apartment become
            totally chaotic ? If you are male and ever lived alone, you probably
            did. But one day (specially when receiving visitors of the other
            sex) you decide to organize it better. It seems that after arranging
            a couple of things or cleaning here and there, it will be done. But
            then, it turns up that the big problems were hiding other ones and
            you end up struggling with the things and your progress is blocked
            at a certain point, no matter how much effort you make. Something
            similar can happen also in other areas, like IT.
          </p>,
          <p className="wiki-text" key={12}>
            With SQL, a new layer was introduced between data and programs,
            encapsulating several common features and exposing data as the
            result of a request expressed in a certain language. But then,
            programs
          </p>,
          <p className="wiki-text" key={13}>
            When programmers needed to write just programs that were running
            isolated from each other, a single unit of code was perfectly
            understandable for the author and (sometimes) also for other people
            that needed to maintain it. But as complexity increased, so did the
            size of these programs that were then partitioned into modules,
            modules that sometimes could even be reused among other programs and
            then ... it was not possible to modify these modules again, without
            affecting the other programs that were using them.
          </p>,
          <p className="wiki-text" key={14}>
            Again, you have an object that is being handled by many others
            (before it was the data, now are the modules) and you cannot easily
            maintain it without considering how each other is using it.
            <br />
            <br />
          </p>,
        ]}
      />
      <WikiSection
        id="section-2"
        title="OOP to the rescue"
        elems={[
          <p className="wiki-text" key={2}>
            If encapsulating data in a database was a good solution for data
            access, why not to try the same for the structure of the programs?
          </p>,
          <p className="wiki-text" key={20}>
            In this case, the interface was not a language (programs are already
            written in one) but public methods.
          </p>,
          <p className="wiki-text" key={21}>
            Object Oriented Programming enforced concepts already known from
            modular programming, like cohesion, connection, etc., but it also
            brought them to a new level by a change in the paradigm.
          </p>,
          <p className="wiki-text" key={22}>
            Even though the roots of this approach are coming from a different
            source, they also end up encapsulating data but additionally, an
            object is also defined by its "behavior", i.e. the logic around the
            data.
            <br />
            <br />
          </p>,
        ]}
      />
      <WikiSection
        id="section-3"
        title="What are we trying to solve?"
        elems={[
          <Quote
            key={3}
            quote={
              "The formulation of a problem is often more essential than its solution, which may be merely a matter of mathematical or experimental skill."
            }
            author={"Albert Einstein"}
          />,
          <p className="wiki-text" key={38}>
            According to this short tale of IT history, there was a first
            paradigm related to programming that we can call a "black box"
            approach. The idea is that a program can be defined by its
            input/output and how the result is achieved should be just
            implementation details.
          </p>,
          <p className="wiki-text" key={30}>
            Later, when data was encapsulated into databases a new layer was
            introduced. The output of a request is the corresponding response
            and at DB layer it can also produce modifications in the data being
            stored. From this point of view, the "core" of a system resides in
            its data. A database model was for many years, the centric
            representation based on which people communicate and programs were
            built.
          </p>,
          <p className="wiki-text" key={31}>
            Then, due to maintainability problems remaining in the system box, a
            new approach proposed to proceed further with the partition and
            encapsulation of the problem domain until each single object
            containing data and behavior is strongly cohesive and loosly coupled
            with the rest, facilitating its maintenance and reuse.
          </p>,
          <p className="wiki-text" key={32}>
            Further refinements of this approach proposed different
            classifications of the myriad of resulting objects, so that those
            responsible for data access, business logic or user interface can be
            grouped and the work better organized.
          </p>,
          <p className="wiki-text" key={33}>
            In this OO approach however, focus was moved from data storage to
            object lifecycle. A user request can be modelled as a series of
            interactions (object messages) sent among them and the data
            repository was sometimes even seen by extrem positions as a
            necessary evil. The core of a system is now the object model and its
            persistance is only needed to keep the state between uptimes. The
            name of the most famous java persistence framework (Hibernate)
            cleary represents this idea.
            <br />
            <br />
          </p>,
          <h2 className="wiki-subt" key={34}>
            Same old problem
          </h2>,
          <p className="wiki-text" key={35}>
            Many years ago, IT was struggling each time several programs were
            accessing and modifying records in the same random file. Data was
            encapsulated, exposed through a well-defined interface (SQL) and
            problem solved. Like in any problem chain, once a problem is solved,
            the other ones become visible. Next was then code organization, so
            code units were grouped around the data they were handling or into
            helper classes and later classified according to the layer they
            belong to. So far, so good. Except that end users are still unhappy,
            specially in large corporations.
          </p>,
          <p className="wiki-text" key={36}>
            One of the reasons for this dissatisfaction might be that end users
            do not understand (and are not interested into) the technical
            details of the solution. Being a technical person this might be seen
            as a problem of the end user, but the fact is that they are the
            customers who are paying to get the work done. Imagine now that you
            hire construction specialists to build your new house and after
            years of viewing the never-ending construction, these specialists
            are more and more happy, because technical improvements allow them
            to demolish and rebuild the same parts time after time, better and
            better. They might be right, and each local enhancement is certainly
            that, a local enhancement. But you are interested in getting the
            whole house finished.
          </p>,
          <p className="wiki-text" key={37}>
            It looks like IT sometimes makes small "jumps" every time it is
            struggling with the same issues time after time. Like if you decide
            to close the door of your chaotic room or just throw everything
            away, a new way of viewing the things normally helps to approach the
            topic from a different angle and then, the solution becomes evident.
            <br />
            <br />
          </p>,
        ]}
      />
      <WikiSection
        id="section-4"
        title="FQL to the rescue"
        elems={[
          <p className="wiki-text" key={4}>
            We can talk about databases or about programming approaches like
            procedural, object-oriented, functional, logical. But in none of
            these cases, we will be talking about what the end user really
            wants. The harsh reality is that they do not care about these
            topics. For them, the final result is the only thing that matters
            and being in their position, where IT is nothing else than a mean to
            achieve a goal, they are totally right.
          </p>,
          <p className="wiki-text" key={40}>
            An important point when you try to solve a problem is to define its
            domain. In the case of Databases, it was data. When talking about
            OOP, it was code and how to organize it. In FQL the domain is (not
            surprisingly) Forms.
            <br />
            <br />
          </p>,
          <h2 className="wiki-subt" key={41}>
            What are FORMS?
          </h2>,
          <p className="wiki-text" key={42}>
            A FORM is just a USER TRANSACTION.
          </p>,
          <p className="wiki-text" key={43}>
            It is called FORM and not User Transaction, because the intention is
            to bring everything closer to the end user. For an end user, it is
            much easier to understand that there is a Form "Customers" rather
            than a User Transaction "Customers". A form is something a user can
            immediately visualize (and this is the intention). Without coming to
            the detailed characteristics of FQL, we will see here how a
            different domain affects the way of thinking and solving problems.
            <br />
            <br />
          </p>,
        ]}
      />
    </motion.div>
  );
};

const CreateForm = () => {
  const createFormGrammar = [
    {
      nonterminal: "create-form ::=",
      terminals: ['"create" "form" id "(" data-spec {"," data-spec } ")";'],
    },
    {
      nonterminal: "data-spec ::=",
      terminals: ["data-definition |", "data-reference;"],
    },
    {
      nonterminal: " data-definition ::=",
      terminals: ["id data-type {data-definition-constraint};"],
    },

    {
      nonterminal: "data-type ::=",
      terminals: [
        '"number" |',
        '"boolean" |',
        '"text" |',
        '"date" |',
        '"time" |',
        '"file" |',
        '"image"; ',
      ],
    },
    {
      nonterminal: "data-definition-constraint ::=",
      terminals: ['"not null" |', '"unique" ;'],
    },
    {
      nonterminal: "data-reference ::=",
      terminals: [
        'id "references" [ref-cardinality] ref-path [ref-constraint] [ref-including];',
      ],
    },
    {
      nonterminal: "ref-cardinality ::=",
      terminals: ["int |", 'int ".." int |', 'int ".." "many";'],
    },
    {
      nonterminal: "ref-path ::=",
      terminals: ["label-path |", ' label-path ["." path-last-item];'],
    },
    {
      nonterminal: "label-path ::=",
      terminals: ['id {"." id} ;'],
    },
    {
      nonterminal: "path-last-item ::=",
      terminals: ['"*" 		    	|', '"(" id {"," id} ")" 	;'],
    },
    {
      nonterminal: "ref-including ::=",
      terminals: ['"including" data-spec |', '"including" "(" data-spec {"," data-spec} ")" ;'],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Defining Forms",
    },
    {
      id: 2,
      title: "Create Form grammar",
    },
    {
      id: 3,
      title: "Simple Form (no References)",
    },
    {
      id: 4,
      title: "Simple Form (with References)",
    },
    {
      id: 5,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      The 'create form' command allows the user to do exactly that: create a new
      form. This new form is as manageable as you can imagine: any name you want
      and any parameters you want to request. The parameters can be of any type:
      text, number, boolean, date, a file, or simply a reference to another
      previously created form.
    </p>,
    <p className="wiki-text" key="introduction-2">
      Also you can make this parameters unique or totally unique, which
      difference we will explain later. The magic continues when you can set the
      cardinality of the references from 1 to many, many to many or better yet
      from any integer to other as you like, of course being consistent with
      what you want to achieve. <br />
      Let's dive in!
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Create Form </h2>
      <br />
      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="Defining Forms"
        elems={[
          <p className="wiki-text" key={1}>
            If you consider Form Query Language (FQL) as a Domain Specific
            Language (DSL), then the Forms are the Domain on top of which FQL
            applies.
          </p>,
          <p className="wiki-text" key={10}>
            You can consider a Form also as a Transaction, but it is preferred
            to call it a Form, because it is an easier concept to grasp by the
            end user. Almost every Internet user has sometime filled out a form,
            even on paper or an electronic one (generally, web-based).
            Transactions on the other hand, imply some other concepts that are
            automatically handled by an application base and, even though useful
            to know them, it is not a requirement. Just choose the concept (Form
            or Transaction) that you feel more comfortable with, but please use
            the word "Form" when comunicating with others.
          </p>,
        ]}
      />
      <WikiSection
        id="section-2"
        title="Create Form grammar"
        elems={[
          <p className="wiki-text" key={2}>
            Let's check the grammar for the CREATE FORM command:{"\n"}
          </p>,
          <Grammar key={20} grammar={createFormGrammar} />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Simple Form (no References)"
        elems={[
          <p className="wiki-text" key={3}>
            Let's begin with some simple FQL code. The create form command
            allows us to create new Forms with its particular properties. For
            example, now we are creating a new Form named Country, which will
            store the country name, its capital and its extension.
          </p>,
          <p className="wiki-text" key={30}>
            <br />
            Summarizing:
          </p>,
          <ul className="wiki-text" key={31}>
            <li key={310}>New form named Country will be created</li>
            <li key={311}>
              Parameters:
              <ul key={312}>
                <li key={313}>Name (unique and not null)</li>
                <li key={314}>Capital (not null)</li>
                <li key={315}>Extension</li>
              </ul>
            </li>
          </ul>,
          <CodeExample
            key={32}
            language="fql (create form)"
            commands={
              "create form Country(\n\t\tname text not null unique, \n\t\tcapital text not null, \n\t\textension number)"
            }
            id="no-references"
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Simple Form (with References)"
        elems={[
          <p className="wiki-text" key={4}>
            Now, let's see an example on how to use simple references. Here we
            create a Form named Adresses with a parameter named country which
            references to the previously created form Country.
          </p>,
          <p className="wiki-text" key={40}>
            <br />
            Summarizing:
          </p>,
          <ul className="wiki-text" key={41}>
            <li>New form named Address will be created</li>
            <li>
              Parameters:
              <ul>
                <li>Street number</li>
                <li>Zip Code</li>
                <li>Country (references a country from the form Country)</li>
              </ul>
            </li>
          </ul>,
          <CodeExample
            key={42}
            language="fql (create form)"
            commands={
              "create form Address (\n\t\tstreet_number number, \n\t\tzip_code number,\n\t\tcountry references Country.name)"
            }
            id="references"
          />,
          <p className="wiki-text" key={5}>
            The following example will show us the magic of the references,
            first we create a form named Jobs that we will use as a reference
            later.
          </p>,
          <p className="wiki-text" key={50}>
            <br />
            Summarizing:
          </p>,
          <ul className="wiki-text" key={51}>
            <li>New form named Jobs will be created</li>
            <li>
              Parameters:
              <ul>
                <li>Name (unique and not null)</li>
              </ul>
            </li>
          </ul>,
          <CodeExample
            key={52}
            language="fql (create form)"
            commands={"create form Jobs (\n\t\tname text not null unique)"}
            id="references"
          />,
          <p className="wiki-text" key={53}>
            <br />
            Here,
          </p>,
          <ul className="wiki-text" key={54}>
            <li key={540}>New form named Persons will be created</li>
            <li key={541}>
              Parameters:
              <ul key={542}>
                <li key={543}>Name (unique and not null)</li>
                <li key={544}>Age</li>
                <li key={545}>
                  Address (references an address from the form Address)
                </li>
                <li key={546}>
                  Jobs (references a job from the form Job with cardinality up
                  to 3 jobs for 1 person)
                </li>
              </ul>
            </li>
          </ul>,
          <CodeExample
            key={55}
            language="fql (create form)"
            commands={
              "create form Persons (\n\t\tname text not null unique,\n\t\tage number,\n\t\taddress references Address.(street_number, zip_code, country),\n\t\tjobs references 1..3 Jobs.name unique)"
            }
            id="references"
          />,
          <p className="wiki-text" key={56}>
            Indeed we saw how a Person can have an address and multiple jobs.
            Let's see one last example, where:
          </p>,
          <ul className="wiki-text" key={57}>
            <li key={570}>New form named Company will be created</li>
            <li key={571}>
              Parameters:
              <ul key={572}>
                <li key={573}>Name (unique and not null)</li>
                <li key={574}>Manager (references a totally unique Person) </li>
                <li key={575}>
                  Employees (references some persons in one Company)
                </li>
              </ul>
            </li>
          </ul>,
          <CodeExample
            key={58}
            language="fql (create form)"
            commands={
              "create form Company (\n\t\tname text not null unique,\n\t\tmanager references 1 Persons.name totally unique,\n\t\temployees references 1..many Persons.name unique)"
            }
            id="references"
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={6}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={60}
            language="fql (create form)"
            commands="create form MyForm (params paramsType)"
          />,
        ]}
      />
    </motion.div>
  );
};

const ShowForms = () => {
  const showFormsGrammar = [
    {
      nonterminal: "show-forms ::=",
      terminals: ['"show" "forms" {id}'],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Show Forms grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Showing a Form",
    },
    {
      id: 4,
      title: "Showing multiple Forms",
    },
    {
      id: 5,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      While we are so busy creating forms and adding information to our forms,
      it might be useful to be able to know what forms we have already created
      and its properties. The 'show forms' command allows the user to fetch from
      one to multiple forms from the database. The showed list contains the
      forms requested and their parameters.
    </p>,
    <p className="wiki-text" key="introduction-2">
      For showing all the forms we have previously added, we just execute "show
      forms". This command will retrieve a list with all the forms we have
      created earlier. Instead if we want to show a specific form, we can
      execute the previous command along with a form name. As a result, we will
      obtain a list with the parameters of that form and their type.
      <br />
      Let's get into it!
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Show Forms </h2>
      <br />

      <Index index={index} introduction={introduction} />

      <WikiSection
        id="section-1"
        title="Show Forms grammar"
        elems={[
          <p className="wiki-text" key={1}>
            Let's check the grammar for the SHOW FORMS command:
          </p>,
          <Grammar key={3} grammar={showFormsGrammar} />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={2}>
            Let's run this commands that will allow us to demonstrate the use of
            the "show forms" command.
          </p>,
          <CodeExample
            key={20}
            language={"fql(create form)"}
            id={"multiline"}
            commands={
              "create form Contacts (name text, mobile_number number, email text, birthdate date)\ncreate form Emails (from references 1 Contacts.email, to references 1..many Contacts.email, topic text, body text)"
            }
          />,
        ]}
      />

      <WikiSection
        id="section-3"
        title="Showing a Form"
        elems={[
          <p className="wiki-text" key={4}>
            When we want to show a form we can execute "show forms" followed by
            the ID of the form we want to retrieve information about. It will
            give us the name of the form and its parameters with each type.
            Check the following code:
          </p>,
          <CodeExample
            key={5}
            language="fql (show forms)"
            commands={"show forms Contacts"}
          />,
        ]}
      />

      <WikiSection
        id="section-4"
        title="Showing multiple Forms"
        elems={[
          <p className="wiki-text" key={6}>
            If you want to show more than one form you can execute the command
            follwed by a list of IDs separated by space. You will retrieve each
            form with all its parameters.
          </p>,
          <CodeExample
            key={7}
            language="fql (show forms)"
            commands={"show forms Contacts Emails"}
          />,
        ]}
      />

      <WikiSection
        id="section-5"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={8}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={9}
            language="fql (show forms)"
            commands="show forms MyForm"
          />,
        ]}
      />
    </motion.div>
  );
};

const RemoveForms = () => {
  const removeFormsGrammar = [
    {
      nonterminal: "remove-forms ::=",
      terminals: ['"remove" "forms" id'],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Remove Forms grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Removing a Form",
    },
    {
      id: 4,
      title: "Removing some forms",
    },
    {
      id: 5,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      After creating a lot of forms or just a few ones we might realize we don't
      need some of them, or maybe we just made a mistake creating one of them.
      In that case we can delete those forms by executing the command "remove
      forms". Removing a form will not be allowed if any other form references
      any parameter of the one we are about to delete.
    </p>,
    <p className="wiki-text" key="introduction-2">
      WARNING!!
      <br /> Removing a form will not be reversible and the data will be
      permanently lost.
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Remove Forms </h2>
      <br />
      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="Remove Forms grammar"
        elems={[
          <p className="wiki-text" key={1}>
            Let's check the grammar for the REMOVE FORM command:
          </p>,
          <Grammar key={3} grammar={removeFormsGrammar} />,
        ]}
      />
      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={2}>
            Let's run this command that will allow us to demonstrate the use of
            the "remove forms" command.
          </p>,
          <CodeExample
            key={20}
            id="multiline"
            language={"fql(create form)"}
            commands={
              "create form Notes (title text, body text, author text, started_date date)\ncreate form Voice_Notes (title text, audio file, author text, started_date date)\ncreate form Calendar_Events (name text not null, description text, start_date date, end_date date)"
            }
          />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Removing a Form"
        elems={[
          <p className="wiki-text" key={3}>
            Deleting a form is as simple as executing the example code, but it
            is very dangerous and irreversible. Be sure that what you want to
            delete is not longer required. Example code below:
          </p>,
          <CodeExample
            key={30}
            language="fql (remove forms)"
            commands={"remove forms Notes"}
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Removing some forms"
        elems={[
          <p className="wiki-text" key={4}>
            Deleting some forms at once is also possible. Be sure that what you
            want to delete is not longer required. Example code below:
          </p>,
          <CodeExample
            key={30}
            language="fql (remove forms)"
            commands={"remove forms Voice_Notes Calendar_Events"}
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={5}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={50}
            language="fql (remove forms)"
            commands="remove forms MyForm"
          />,
        ]}
      />
    </motion.div>
  );
};

const CreateNew = () => {
  const createNewGrammar = [
    {
      nonterminal: "create-new ::=",
      terminals: ['"create" ["new"] id ["values"] "(" value-list ")";'],
    },

    {
      nonterminal: "value-list ::=",
      terminals: [
        'literal {"," value-list } |',
        '"(" value-list ")" {"," value-list } ;',
      ],
    },
    {
      nonterminal: "literal ::=",
      terminals: [
        "text |",
        '"null" |',
        '"true" |',
        '"false" |',
        "number |",
        "int;",
      ],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Create New grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Creating new entries",
    },
    {
      id: 4,
      title: "New entries with references",
    },
    {
      id: 5,
      title: "New entries with multiples references",
    },
    {
      id: 6,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      The 'create new' command allows the user to add new entries to the
      previously created forms. This new entries have to comply with the
      parameters we have defined for each form. Although we decided to name the
      command as 'create new', we can execute just 'create' and the ID of the
      form we are going to add a new entry to.
    </p>,
    <p className="wiki-text" key="introduction-2">
      Let's go deeper!
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Create New </h2>
      <br />
      <Index index={index} introduction={introduction} />

      <WikiSection
        id="section-1"
        title="Create New grammar"
        elems={[
          <p className="wiki-text" key={1}>
            Let's check the grammar for the CREATE NEW command:{"\n"}
          </p>,
          <Grammar key={10} grammar={createNewGrammar} />,
        ]}
      />
      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={2}>
            Let's run this command that will allow us to demonstrate the use of
            the "create new" command.
          </p>,
          <CodeExample
            key={20}
            language={"fql(create form)"}
            id={"multiline"}
            commands={
              "create form Nationalities (name text)\ncreate form Directors (name text, nationality references Nationalities.name)\ncreate form Movies(name text, length number, director references 1..3 Directors.name)"
            }
          />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Creating new entries"
        elems={[
          <p className="wiki-text" key={3}>
            Adding a new entry cannot be simplier. Let's see how to add some
            data to the form Nationalities, previously created.
          </p>,
          <CodeExample
            key={30}
            language="fql (create new)"
            commands={
              'create new Nationalities values ("Cuban")\ncreate new Nationalities values ("Japanese")\ncreate new Nationalities values ("Uruguayan")'
            }
            id="multiline"
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="New entries with references"
        elems={[
          <p className="wiki-text" key={4}>
            This new entry of the form Directors need a name and a reference to
            a nationality. To fulfill the reference requirement we must insert
            either the first parameter of the referenced form or all the
            parameters. For example:
          </p>,

          <CodeExample
            key={40}
            language="fql (create new)"
            commands={
              'create Directors ("Leonardo Padura", "Cuban")\ncreate Directors ("Enrique Colina", "Cuban")\ncreate Directors ("Hayao Miyazaki", "Japanese")\ncreate Directors ("Isao Takahata", "Japanese")\ncreate Directors ("Federico Veiroj", "Uruguayan")'
            }
            id="multiline"
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="New entries with multiples references"
        elems={[
          <p className="wiki-text" key={51}>
            If we add some entries to the form Movies we can see how we can set
            a list of directors for one movie as we defined it before. Check it
            out:
          </p>,
          <CodeExample
            key={52}
            language="fql (create new)"
            id="multiline"
            commands={
              "create Movies values ('Acne', 87, 'Federico Veiroj')\ncreate Movies values ('Castle in the Sky', 124, ('Hayao Miyazaki','Isao Takahata'))\ncreate Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Enrique Colina')) "
            }
          />,
        ]}
      />
      <WikiSection
        id="section-6"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={6}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={60}
            language="fql (create new)"
            commands="create new MyNewEntry"
          />,
        ]}
      />
    </motion.div>
  );
};

const GetCase = () => {
  const getCaseGrammar = [
    {
      nonterminal: "get-case ::=",
      terminals: [
        '"get" id ["(" label-path {"," label-path} ")"] ["with" logical-expression];',
      ],
    },
    {
      nonterminal: "logical-expression ::=",
      terminals: ['logical-term { "or" logical-term };'],
    },
    {
      nonterminal: "logical-term ::=",
      terminals: ['logical-factor { "and" logical-factor };'],
    },
    {
      nonterminal: "logical-factor ::=",
      terminals: ["comparison |", '"(" logical-expression ")";'],
    },
    {
      nonterminal: "comparison ::=",
      terminals: ["limits-comparison |", "simple-comparison ;"],
    },
    {
      nonterminal: "limits-comparison ::=",
      terminals: ['value ["not"] "between" value "and" value;'],
    },
    {
      nonterminal: "simple-comparison ::=",
      terminals: [
        'unary-operator "(" value ")" |',
        "value relational-operator value;",
      ],
    },
    {
      nonterminal: "unary-operator ::=",
      terminals: ['["not"] "null";'],
    },
    {
      nonterminal: "value ::=",
      terminals: ["literal |", "label-path ;"],
    },
    {
      nonterminal: "label-path ::=",
      terminals: ['id {"." id} ;'],
    },
    {
      nonterminal: "relational-operator ::=",
      terminals: [
        '["not"] "eq" 		| ',
        '["not"] "equal" 	|',
        '["not"] "=" 		|',
        '"<>" 			|',
        '"!=" 			|',
        '["not" | "!"] "<" 	|',
        '["not" | "!"] ">" 	|',
        '"<=" 			|',
        '">=" 			;',
      ],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Get Case grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Simpliest Get Case",
    },
    {
      id: 4,
      title: "Get Case Constraints",
    },
    {
      id: 5,
      title: "Logical expressions",
    },
    {
      id: 6,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      It's time to learn how to retrieve some information from the forms. Now we
      can fetch one, some or all the entries we have previously added to our
      forms.
    </p>,
    <p className="wiki-text" key="introduction-2">
      The logical expressions will give us the power to filter the information
      we want to obtain from the forms. The can be as simple as you want and
      also the can be as nested as you need them to be.
      <br />
      Let's get down to business!
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Get Case </h2>
      <br />
      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="Get Case grammar"
        elems={[
          <p className="wiki-text" key={1}>
            Let's check the grammar for the GET command:{"\n"}
          </p>,
          <Grammar key={10} grammar={getCaseGrammar} />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={2}>
            Let's run this commands that will allow us to demonstrate the use of
            the "get" command.
          </p>,
          <CodeExample
            key={20}
            language={"fql(create form)"}
            id={"multiline"}
            commands={
              "create form Pet_Owners (name text, age number)\ncreate form Pets(name text, animal text, breed text, owner references 1 Pet_Owners.(name, age))"
            }
          />,
          <p className="wiki-text" key={21}>
            Now, let's add some cases to those forms.
          </p>,
          <CodeExample
            key={22}
            language={"fql(create new)"}
            id={"multiline"}
            commands={
              "create new Pet_Owners ('Louis', 15)\ncreate new Pet_Owners ('Andrew', 24)\ncreate new Pet_Owners ('Ana', 63)\n\ncreate Pets('Loki','dog', 'Pekingese', 'Andrew')\ncreate Pets('Tiger','cat', 'Persian', 'Ana')\ncreate Pets('Nina','dog', 'Labrador', 'Louis')\ncreate Pets('Fluff','cat', 'Siamese', 'Ana')"
            }
          />,
        ]}
      />

      <WikiSection
        id="section-3"
        title="Simpliest Get Case"
        elems={[
          <p className="wiki-text" key={3}>
            If we wish to retrieve all the entries of an entire form we can
            execute the 'get' command, optionally followed by the ID and the
            parameters we require, of the form we want to get info from. Code
            example below show us how to fetch all the data from the form Pets.
          </p>,
          <CodeExample
            key={30}
            language="fql (get case)"
            commands={"get Pets (breed)"}
            id="no-references"
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Get Case Constraints"
        elems={[
          <p className="wiki-text" key={4}>
            Of course we don't want to always show all the entries of a form.
            The 'get... with...' command allows us to retrieve one or some
            entries depending on the conditions we specify. For example we are
            going to fetch all the Pets from Ana:
          </p>,
          <CodeExample
            key={40}
            language="fql (get case)"
            commands={"get Pets (name, breed)\n\twith owner.name = 'Ana'"}
            id="references"
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="Logical expressions"
        elems={[
          <p className="wiki-text" key={5}>
            The magic continues when we are able to define logical expressions
            that let us filter the information we want to retrieve from the
            form. The following command will fetch all the dogs who have owners
            olders than 20 years.
          </p>,
          <CodeExample
            key={50}
            language="fql (get case)"
            commands={
              "get Pets (name, breed, owner.name, owner.age)\n\twith animal = 'dog'\n\tand owner.age > 20"
            }
          />,
        ]}
      />
      <WikiSection
        id="section-6"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={6}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={60}
            language="fql (get case)"
            commands="get MyForm"
          />,
        ]}
      />
    </motion.div>
  );
};

const ModifyCase = () => {
  const modifyCaseGrammar = [
    {
      nonterminal: "modify-case ::=",
      terminals: [
        '"modify" id ["values"] "(" value-list ")" ["with" logical-expression];',
      ],
    },
    {
      nonterminal: "logical-expression ::=",
      terminals: ['logical-term { "or" logical-term };'],
    },
    {
      nonterminal: "logical-term ::=",
      terminals: ['logical-factor { "and" logical-factor };'],
    },
    {
      nonterminal: "logical-factor ::=",
      terminals: ["comparison |", '"(" logical-expression ")";'],
    },
    {
      nonterminal: "comparison ::=",
      terminals: ["limits-comparison |", "simple-comparison ;"],
    },
    {
      nonterminal: "limits-comparison ::=",
      terminals: ['value ["not"] "between" value "and" value;'],
    },
    {
      nonterminal: "simple-comparison ::=",
      terminals: [
        'unary-operator "(" value ")" |',
        "value relational-operator value;",
      ],
    },
    {
      nonterminal: "unary-operator ::=",
      terminals: ['["not"] "null";'],
    },
    {
      nonterminal: "value ::=",
      terminals: ["literal |", "label-path ;"],
    },
    {
      nonterminal: "label-path ::=",
      terminals: ['id {"." id} ;'],
    },
    {
      nonterminal: "relational-operator ::=",
      terminals: [
        '["not"] "eq" 		| ',
        '["not"] "equal" 	|',
        '["not"] "=" 		|',
        '"<>" 			|',
        '"!=" 			|',
        '["not" | "!"] "<" 	|',
        '["not" | "!"] ">" 	|',
        '"<=" 			|',
        '">=" 			;',
      ],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Modify Case grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Modifying cases",
    },
    {
      id: 4,
      title: "Modifying with references",
    },
    {
      id: 5,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Modify Case </h2>
      <br />
      <Index index={index} introduction={introduction} />

      <WikiSection
        id="section-1"
        title="Modify Case grammar"
        elems={[
          <p className="wiki-text" key={1}>
            Let's check the grammar for the MODIFY command:{"\n"}
          </p>,
          <Grammar key={10} grammar={modifyCaseGrammar} />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={2}>
            Let's run this commands that will allow us to demonstrate the use of
            the "modify" command.
          </p>,
          <CodeExample
            key={20}
            language={"fql(create form)"}
            id="multiline"
            commands={
              "create form Companies (name text)\ncreate form Products (name text, designer references Companies.name, price number)"
            }
          />,
          <p className="wiki-text" key={21}>
            Now, let's add some cases to that forms.
          </p>,
          <CodeExample
            key={22}
            language={"fql(create new)"}
            id={"multiline"}
            commands={
              "create new Companies ('Apple')\ncreate new Companies ('Google')\n\ncreate new Products ('MacBook Pro M1', 'Apple', 1299)\ncreate new Products ('iPhone 12 Pro', 'Google', 499)\ncreate new Products ('AirPods Pro 1st Gen', 'Apple', 399)"
            }
          />,
        ]}
      />

      <WikiSection
        id="section-3"
        title="Modifying cases"
        elems={[
          <p className="wiki-text" key={3}>
            Well, now that we have some information on the form Products let's
            suppose that the AirPods are in offer and they no longer cost $399;
            instead they will cost $299. <br />
            For that purpose we first run a get command to retrieve the
            "fql_version" attribute. That attribute allows us to know the actual
            version of a case inside a form and avoids the info to be corrupted
            if someone modifies a command while we are trying to do it as well.
          </p>,
          <CodeExample
            key={30}
            language="fql (get case)"
            commands={"get Products with name='AirPods Pro 1st Gen'"}
            id="no-references"
          />,
          <p className="wiki-text" key={31}>
            Now that we know the fql_version of the "AirPods Pro 1st Gen" we can
            proceed to modify it.
          </p>,
          <CodeExample
            key={32}
            language="fql (modify case)"
            commands={
              "modify Products ('AirPods Pro 1st Gen','Apple', 299) with\n\tname='AirPods Pro 1st Gen'\n\tand price=399\n\tand fql_version=0"
            }
            id="no-references"
          />,
          <p className="wiki-text" key={33}>
            If we run this commands we can check that the case was updated and
            also its "fql_version" attribute
          </p>,
          <CodeExample
            key={34}
            language="fql (get case)"
            commands={"get Products with name='AirPods Pro 1st Gen'"}
            id="no-references"
          />,
        ]}
      />

      <WikiSection
        id="section-4"
        title="Modifying with references"
        elems={[
          <p className="wiki-text" key={4}>
            If the case that we want to modify has a reference to another form
            we must be sure that the fql_version of that entry in the referenced
            form is correct. <br />
            For example, we, on purpose, made a mistake on the designer of the
            "iPhone 12 Pro". The correct way to fix that is the following:
          </p>,
          <CodeExample
            key={40}
            language="fql (modify case)"
            commands={
              "modify Products ('iPhone 12 Pro', 'Apple', 499)\n\twith name = 'iPhone 12 Pro'\n\tand fql_version = 0\n\tand designer.fql_version = 0"
            }
            id="references"
          />,
        ]}
      />

      <WikiSection
        id="section-5"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={5}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={50}
            language="fql (modify case)"
            commands="modify MyNewEntry values (params)"
          />,
        ]}
      />
    </motion.div>
  );
};

const RemoveCase = () => {
  const removeCaseGrammar = [
    {
      nonterminal: "remove-case ::=",
      terminals: ['"remove" id ["with" logical-expression];'],
    },
    {
      nonterminal: "logical-expression ::=",
      terminals: ['logical-term { "or" logical-term };'],
    },
    {
      nonterminal: "logical-term ::=",
      terminals: ['logical-factor { "and" logical-factor };'],
    },
    {
      nonterminal: "logical-factor ::=",
      terminals: ["comparison |", '"(" logical-expression ")";'],
    },
    {
      nonterminal: "comparison ::=",
      terminals: ["limits-comparison |", "simple-comparison ;"],
    },
    {
      nonterminal: "limits-comparison ::=",
      terminals: ['value ["not"] "between" value "and" value;'],
    },
    {
      nonterminal: "simple-comparison ::=",
      terminals: [
        'unary-operator "(" value ")" |',
        "value relational-operator value;",
      ],
    },
    {
      nonterminal: "unary-operator ::=",
      terminals: ['["not"] "null";'],
    },
    {
      nonterminal: "value ::=",
      terminals: ["literal |", "label-path ;"],
    },
    {
      nonterminal: "label-path ::=",
      terminals: ['id {"." id} ;'],
    },
    {
      nonterminal: "relational-operator ::=",
      terminals: [
        '["not"] "eq" 		| ',
        '["not"] "equal" 	|',
        '["not"] "=" 		|',
        '"<>" 			|',
        '"!=" 			|',
        '["not" | "!"] "<" 	|',
        '["not" | "!"] ">" 	|',
        '"<=" 			|',
        '">=" 			;',
      ],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Remove Case grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Removing entries",
    },
    {
      id: 4,
      title: "The 'remove ... with ...' command",
    },
    {
      id: 5,
      title: "Logical expressions",
    },
    {
      id: 6,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      We had learned how to remove an entire form. Let's see now how to delete
      some entries from a specific form. We can go from removing all the entries
      and reseting it to blank to filtering which entries we want to delete
      using logical expressions.
    </p>,
    <p className="wiki-text" key="introduction-2">
      WARNING!!
      <br /> Removing a form entry will not be reversible and the data will be
      permanently lost.
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Remove Case </h2>
      <br />
      <Index index={index} introduction={introduction} />

      <WikiSection
        id="section-1"
        title="Remove Case grammar"
        elems={[
          <p className="wiki-text" key={2}>
            Let's check the grammar for the REMOVE command:{"\n"}
          </p>,
          <Grammar key={20} grammar={removeCaseGrammar} />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={2}>
            Let's run this commands that will allow us to demonstrate the use of
            the "remove case" command.
          </p>,
          <CodeExample
            key={20}
            language={"fql(create form)"}
            id={"multiline"}
            commands={
              "create form Genres(name text)\ncreate form Songs (name text, author text, genre text, year number)"
            }
          />,
          <p className="wiki-text" key={21}>
            Now, let's add some cases to that form.
          </p>,
          <CodeExample
            key={22}
            language={"fql(create new)"}
            id={"multiline"}
            commands={
              "create new Genres('rock')\ncreate new Genres('pop')\ncreate new Genres('reggaeton')\ncreate new Genres('classical')\n\ncreate new Songs ('Careless Whisper', 'George Michael', 'pop-soul', 1984)\ncreate new Songs ('Imagine', 'John Lenon', 'pop-rock', 1971)\ncreate new Songs ('Gasolina', 'Daddy Yankee', 'reggaeton', 2004)\ncreate new Songs ('Despacito', 'Luis Fonsi', 'pop-latino', 2017)"
            }
          />,
        ]}
      />

      <WikiSection
        id="section-3"
        title="Removing entries"
        elems={[
          <p className="wiki-text" key={3}>
            If we wish to remove all the entries of an entire form we can
            execute the 'remove' command followed by the ID of the form we want
            to empty. Code example below show us how to remove all the data from
            the form Genres.
          </p>,
          <CodeExample
            key={30}
            language="fql (remove case)"
            commands={"remove Genres"}
            id="no-references"
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="The 'remove ... with ...' command"
        elems={[
          <p className="wiki-text" key={4}>
            Of course we don't want to always delete all the entries. The
            'remove... with...' command allows us to remove one or some entries
            depending on the conditions we specify. For example we are going to
            remove all the Songs newer than 2000:
          </p>,
          <CodeExample
            key={40}
            language="fql (remove case)"
            commands={"remove Songs with year > 2000"}
            id="references"
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="Logical expressions"
        elems={[
          <p className="wiki-text" key={5}>
            The magic continues when we are able to define logical expressions
            that let us filter the information we want to delete from the form.
            The following command will remove all the pop-rock Songs from the
            70s.
          </p>,
          <CodeExample
            key={50}
            language="fql (remove case)"
            commands={
              "remove Songs\n\t with year > 1970\n\t and year < 1980\n\t and genre='pop-rock'"
            }
          />,
        ]}
      />

      <WikiSection
        id="section-6"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={6}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={60}
            language="fql (remove case)"
            commands="remove MyEntry"
          />,
        ]}
      />
    </motion.div>
  );
};

const DefineFunction = () => {
  const defineFunctionGrammar = [
    {
      nonterminal: "define-function ::= ",
      terminals: ['"define" "function" id argument-list function-body;'],
    },
    {
      nonterminal: "argument-list ::=",
      terminals: ['"(" argument {"," argument} ")";'],
    },
    {
      nonterminal: "argument ::=",
      terminals: ['id | "&optional" | "&rest";'],
    },
    {
      nonterminal: "function-body ::=",
      terminals: ['"{" lisp-forms "}";'],
    },
    {
      nonterminal: "lisp-forms ::=",
      terminals: ["{ lisp-form };"],
    },
    {
      nonterminal: "lisp-form ::=",
      terminals: ['"(" { lisp-form } ")" |', "lisp-atom ;"],
    },
    {
      nonterminal: "lisp-atom ::=",
      terminals: ["symbol | number | string;"],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Define Function grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Define a function for validation",
    },
    {
      id: 4,
      title: "Add users",
    },
    {
      id: 5,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Define Function</h2>
      <br />

      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="Define Function grammar"
        elems={[
          <p className="wiki-text" key={10}>
            Let's check the grammar for the DEFINE FUNCTION command:{"\n"}
          </p>,
          <Grammar key={11} grammar={defineFunctionGrammar} />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={20}>
            Let's run this commands that will allow us to demonstrate the use of
            the "define function" command.
          </p>,
          <CodeExample
            key={21}
            language={"fql(create form)"}
            commands={
              "create form Members (name text unique not null, age number)"
            }
          />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Define a function for validation"
        elems={[
          <p className="wiki-text" key={30}>
            Suppose we want to define a function that raises an error depending
            on the user's age.
          </p>,
          <CodeExample
            key={31}
            language={"fql (define function)"}
            id="multiline"
            commands={
              'define function raise_age_error(age) {\n    (error "Too young to get a job: ~A" age)\n}'
            }
          />,
          <p className="wiki-text" key={32}>
            See <Link to="/old/wiki/validations">"Validations"</Link> for how to
            define a rule. Here's a rule to refuse users under 18 years old:
          </p>,
          <CodeExample
            key={33}
            language={"fql (define rule)"}
            id="multiline"
            commands={
              "define rule NO_TEENS_WITH_JOBS: on Members\n\twhen age < 18 then CALL raise_age_error(age)"
            }
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Add users"
        elems={[
          <p className="wiki-text" key={40}>
            Now, try to add a couple of users and let's see what happens.
          </p>,
          <CodeExample
            key={41}
            language={"fql (create new)"}
            id="multiline"
            commands={
              'create new Members ("MEIKO", 19)\ncreate new Members ("Miku Hatsune", 16)'
            }
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={50}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={51}
            language="fql (define function)"
            commands="define function my_function() { }"
          />,
        ]}
      />
    </motion.div>
  );
};

const IsUsedBy = () => {
  const IsUsedByGrammar = [
    {
      nonterminal: "is-used-by ::= ",
      terminals: ["is-used-by-header is-used-by-in-groups-of;"],
    },
    {
      nonterminal: "is-used-by-header ::=",
      terminals: ['id "is" "used" "by" id is-used-by-through;'],
    },
    {
      nonterminal: "is-used-by-through ::=",
      terminals: ['"through" id "." "(" id "," id "," "id" ")";'],
    },
    {
      nonterminal: "is-used-by-in-groups-of ::=",
      terminals: [
        '"in" "groups" "." "(" id ")" "of" group-value ".." group-value;',
      ],
    },
    {
      nonterminal: "group-value ::=",
      terminals: ['number | id "." id;'],
    },
  ];

  const index = [
    {
      id: 1,
      title: "Is Used By grammar",
    },
    {
      id: 2,
      title: "Previous commands",
    },
    {
      id: 3,
      title: "Use the Is Used By command",
    },
    {
      id: 4,
      title: "Add RESERVATIONS",
    },
    {
      id: 5,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Define Function</h2>
      <br />

      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="Is Used By Grammar"
        elems={[
          <p className="wiki-text" key={10}>
            Let's check the grammar for the IS USED BY command:{"\n"}
          </p>,
          <Grammar key={11} grammar={IsUsedByGrammar} />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={20}>
            Let's run this commands that will allow us to demonstrate the use of
            the "is used by" command.
          </p>,
          <CodeExample
            key={21}
            id="multiline"
            language={"fql(create form)"}
            commands={
              "create form HOTEL_ROOMS(room_number number not null unique, floor number not null, single_beds number not null, double_beds number not null, capacity number)\ncreate form GUESTS(guest_number number not null unique, name text not null, phone text not null)\ncreate form RESERVATIONS(guest references GUESTS.name, capacity number not null, single_beds number, double_beds number, from_date date, until_date date, room_assigned references HOTEL_ROOMS.room_number)"
            }
          />,
          <p className="wiki-text" key={20}>
            Now, let's add some cases.
          </p>,
          <CodeExample
            key={21}
            id="multiline"
            language={"fql(create new)"}
            commands={
              'create HOTEL_ROOMS(1000, 1, 3, 0, 3)\ncreate HOTEL_ROOMS(1001, 1, 1, 1, 2)\ncreate HOTEL_ROOMS(1002, 2, 3, 1, 4)\ncreate HOTEL_ROOMS(1003, 2, 4, 0, 4)\ncreate HOTEL_ROOMS(1004, 3, 1, 5, 6)\ncreate GUESTS(10, "Leandro", "+LLLL126589")\ncreate GUESTS(11, "Felix", "+FFFF346589")\ncreate GUESTS(12, "Eitaro", "+EEEE14354589")\ncreate GUESTS(13, "Fernando", "+YYYY9136546589")\ncreate GUESTS(14, "Alvaro", "+AAAA129430389")\ncreate GUESTS(15, "Carlos", "+CCCC126923289")'
            }
          />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Use the Is Used By command"
        elems={[
          <p className="wiki-text" key={30}>
            Suppose we want to define a function that raises an error depending
            on the user's age.
          </p>,
          <CodeExample
            key={31}
            language={"fql (is used by)"}
            id="multiline"
            commands={
              "HOTEL_ROOMS is used by GUESTS through RESERVATIONS.(room_assigned, from_date, until_date) in groups.(capacity) of 1..HOTEL_ROOMS.capacity"
            }
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Add RESERVATIONS"
        elems={[
          <p className="wiki-text" key={40}>
            Now, try to add a couple of RESERVATIONS and let's see what happens.
          </p>,
          <CodeExample
            key={41}
            language={"fql (create new)"}
            id="multiline"
            commands={
              'create RESERVATIONS("Felix", 2, 3, 0, "07-10-2024", "07-17-2024", 1000)\ncreate RESERVATIONS("Leandro", 4, 0, 4, "06-08-2024", "06-15-2024", 1003)\ncreate RESERVATIONS("Fernando", 1, 3, 0, "06-28-2024", "07-05-2024", 1000)\ncreate RESERVATIONS("Eitaro", 2, 3, 0, "07-06-2024", "07-13-2024", 1000)\ncreate RESERVATIONS("Alvaro", 2, 3, 0, "07-11-2024", "07-13-2024", 1000)\ncreate RESERVATIONS("Carlos", 11, 3, 0, "07-20-2024", "07-24-2024", 1000)'
            }
          />,
        ]}
      />
      <WikiSection
        id="section-5"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={50}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={51}
            language="fql (define function)"
            commands="define function my_function() { }"
          />,
        ]}
      />
    </motion.div>
  );
};

const RulesIntro = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Rules Intro </h2>
      <br />
      <p className="wiki-text" key="introduction-1">
        FQL can be seen as a Domain Specific Language (DSL) whose domain is (no
        surprisingly) Forms. And since a Form can be used to hold any kind of
        data, you end up having a DSL to manage any information system. Just
        store any information of any kind in a Form, and you have the full power
        at your disposal. WebForms are nothing new, and although FQL was
        conceived from the beginning with foreign key constraints and other
        validations, this is, nowadays, something also easy to find in other
        solutions. What makes a real difference is when we start talking about
        Rules that are defined in a language that is "readable" for any
        non-technical user.
      </p>
      <br />
      <WikiSection
        id="section-1"
        title="Defining Rules"
        elems={[
          <p className="wiki-text" key={1}>
            Rules are a distinctive aspect of FQL, where its full potential can
            be better appreciated.
          </p>,
          <p className="wiki-text" key={10}>
            Before FQL, end users could only test an IT system like a
            "black-box". Given a context and certain input, if the result was
            the expected one and this happened in a consistent manner, we could
            assume that the system is behaving properly. But as production
            "bugs" show, we cannot be 100% sure.
          </p>,
          <br key={15} />,
          <h2 className="wiki-subt" key={11}>
            More rules or... more about Rules
          </h2>,
          <p className="wiki-text" key={12}>
            FQL Rules define the system behavior in a human-readable manner. Not
            everyone might be able to write and express the rules in the best
            possible way, but everyone should be able to read, understand, and
            confirm that this is the expected system behavior.
          </p>,
          <p className="wiki-text" key={13}>
            Rules can be subdivided in different groups, like Validations,
            Concepts, Actions, etc.
          </p>,
          <p className="wiki-text" key={14}>
            Please check each specific section for more info.
          </p>,
        ]}
      />
    </motion.div>
  );
};

const Validations = () => {
  const index = [
    {
      id: 0,
      title: "Previous commands",
    },
    {
      id: 1,
      title: "Defining Validations",
    },
    {
      id: 2,
      title: "Validations grammar",
    },
    {
      id: 3,
      title: "Validated examples",
    },
    {
      id: 4,
      title: "Test some FQL code!",
    },
  ];

  const introduction = [
    <p className="wiki-text" key={"introduction-1"}>
      Rules are a distinctive aspect of FQL, where its full potential can be
      better appreciated. Rules can be subdivided in different groups, like
      Validations, Concepts, Actions, etc.
    </p>,
    <p className="wiki-text" key={"introduction-2"}>
      Validations as a specific kind of Rule, allows us to check if a constraint
      is fulfilled and define invalid cases when it does.
    </p>,
  ];

  const validationsGrammar = [
    {
      nonterminal: "validation ::=",
      terminals: [
        'validation-header validation-on validation-when "is" "invalid";',
      ],
    },
    {
      nonterminal: "validation-header ::=",
      terminals: ['"define" "rule" id ":";'],
    },
    {
      nonterminal: "validation-on ::=",
      terminals: ['"on" id |', "id;"],
    },
    {
      nonterminal: "validation-when ::=",
      terminals: ['"when" logical-expression;'],
    },
    {
      nonterminal: "logical-expression ::=",
      terminals: ['logical-term { "or" logical-term };'],
    },
    {
      nonterminal: "logical-term ::=",
      terminals: ['logical-factor { "and" logical-factor };'],
    },
    {
      nonterminal: "logical-factor ::=",
      terminals: ["comparison |", '"(" logical-expression ")";'],
    },
    {
      nonterminal: "comparison ::=",
      terminals: ["limits-comparison |", "simple-comparison ;"],
    },
    {
      nonterminal: "limits-comparison ::=",
      terminals: ['value ["not"] "between" value "and" value;'],
    },
    {
      nonterminal: "simple-comparison ::=",
      terminals: [
        'unary-operator "(" value ")" |',
        "value relational-operator value;",
      ],
    },
    {
      nonterminal: "unary-operator ::=",
      terminals: ['["not"] "null";'],
    },
    {
      nonterminal: "value ::=",
      terminals: ["literal |", "label-path ;"],
    },
    {
      nonterminal: "label-path ::=",
      terminals: ['id {"." id} ;'],
    },
    {
      nonterminal: "relational-operator ::=",
      terminals: [
        '["not"] "eq" 		| ',
        '["not"] "equal" 	|',
        '["not"] "=" 		|',
        '"<>" 			|',
        '"!=" 			|',
        '["not" | "!"] "<" 	|',
        '["not" | "!"] ">" 	|',
        '"<=" 			|',
        '">=" 			;',
      ],
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Validations</h2>
      <br />
      <Index index={index} introduction={introduction} />

      <WikiSection
        id="section-0"
        title="Previous commands"
        elems={[
          <p className="wiki-text" key={0}>
            Let's run this commands that will allow us to demonstrate the use of
            the validations.
          </p>,
          <CodeExample
            key={0.1}
            language={"fql(create form)"}
            id={"multiline"}
            commands={
              "create form Items (name text, model text, price number)\ncreate form Store (address text, items references 1..many Items.(name, model, price))"
            }
          />,
        ]}
      />

      <WikiSection
        id="section-1"
        title="Definings Validations"
        elems={[
          <p className="wiki-text" key={1}>
            It cannot be easier to define a rule over a Form than, in a readable
            manner, invalidate cases when they don't accomplish a set of
            restrictions. Let's better see an example.
          </p>,
          <p className="wiki-text" key={10}>
            We have previously defined the form Items. Check out how to define a
            rule:
          </p>,
          <CodeExample
            key={11}
            language="fql (validations)"
            commands={
              'define rule NO_LAPTOPS: on Items when name = "Laptop" is invalid'
            }
            id="no-references"
          />,
          <p className="wiki-text" key={12}>
            So, if we try now to add a new entry to that form with item name
            "Laptop", an error is thrown.
          </p>,
          <CodeExample
            key={13}
            language="fql (create new)"
            commands={'create new Items values ("Laptop", "M132AXX", 450)'}
            id="no-references"
          />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="Validations grammar"
        elems={[
          <p className="wiki-text" key={2}>
            Let's check the grammar for the Validations:
          </p>,
          <Grammar key={20} grammar={validationsGrammar} />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Validated examples"
        elems={[
          <p className="wiki-text" key={3}>
            Let's add now a new rule that doesn't allow expensive items to
            register on the Store form
          </p>,
          <CodeExample
            key={30}
            language="fql (validations)"
            commands={
              "define rule NO_EXPENSIVE_ITEMS: on Store when items.price > 500 is invalid"
            }
            id="no-references"
          />,
          <p className="wiki-text" key={31}>
            Let's see how it works then:
          </p>,
          <CodeExample
            key={32}
            language="fql (create new)"
            commands={
              "create new Items ('SmartWatch', 'SM34A', 300)\ncreate new Items ('iPhone 12', 'M3456/A', 650)\n\ncreate new Store('2701 NW Ave.', ('SmartWatch', 'iPhone 12'))"
            }
            id="multiline"
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Test some FQL code!"
        elems={[
          <p className="wiki-text" key={4}>
            Feel free to test some code!
          </p>,
          <CodeExample
            key={40}
            language="fql (validations)"
            commands="remove MyEntry"
          />,
        ]}
      />
    </motion.div>
  );
};

// const Actions = () => {
//   return (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       exit={{ opacity: 0 }}
//     >
//       <h2 className="wiki-title">Actions </h2>
//       <br />
//     </motion.div>
//   );
// };

const AdvancedExamples = () => {
  const index = [
    {
      id: 1,
      title: "Setting the basis",
    },
    {
      id: 2,
      title: "More basis...",
    },
    {
      id: 3,
      title: "The magic begins",
    },
    {
      id: 4,
      title: "Defining some rules",
    },
    {
      id: 5,
      title: "Getting faster and simplier",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      After learning the basics of FQL you might ask: is that all?
    </p>,
    <p className="wiki-text" key="introduction-2">
      Well, obviously not. <br key={0} />
      This section is dedicated to show more advanced examples that will help
      you realize how powerful FQL can be and still, so simple, friendly, and
      concise. Creating some forms, adding values, setting rules, actions, and
      retrieving info with clear constraints and conditions in a manner that is
      readable for the non-technical user. Yes, it may seem like magic ;)
    </p>,
    <p className="wiki-text" key="introduction-3">
      Let's have a look!
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Advanced examples </h2>
      <br />

      <Index index={index} introduction={introduction} />

      <WikiSection
        id="section-1"
        title="Setting the basis"
        elems={[
          <p className="wiki-text" key={1}>
            Let's create some Forms that will help us out showing the strength
            of FQL:{"\n"}
          </p>,
          <CodeExample
            key={10}
            language="fql (create form)"
            commands={
              "create form Nations (name text not null unique)\ncreate form Employments (name text, medium_salary number)\ncreate form Users(username text not null unique, age number, jobs references 0..3 Employments.name, country references Nations.name)\ncreate form Website(name text not null, url text not null, users references 1..many Users.(username, age, jobs.name, country.name))"
            }
            id="multiline"
          />,
        ]}
      />

      <WikiSection
        id="section-2"
        title="More basis..."
        elems={[
          <p className="wiki-text" key={2}>
            Let's add some info to the Form Nations. It will be useful later.
          </p>,
          <CodeExample
            key={20}
            language="fql (create new)"
            commands={
              "create new Nations values ('Cuba')\ncreate new Nations values ('Japan')\ncreate new Nations values ('Uruguay')\ncreate new Nations values ('Argentina')"
            }
            id="multiline"
          />,
          <p className="wiki-text" key={21}>
            Let's add some cases to the Form Jobs.
          </p>,
          <CodeExample
            key={22}
            language="fql (create new)"
            commands={
              "create new Employments values ('Doctor', 4000)\ncreate new Employments values ('Scientist', 3500)\ncreate new Employments values ('Musician', 3000)\ncreate new Employments values ('Actor', 2800)\ncreate new Employments values ('Engineer', 2100)\ncreate new Employments values ('Freelancer', 900)"
            }
            id="multiline"
          />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="The magic begins"
        elems={[
          <p className="wiki-text" key={3}>
            As we saw before, rules are a distinctive and a powerful aspect of
            FQL, where its full potential can be better appreciated. Let's
            define one validation.
          </p>,
          <CodeExample
            key={30}
            language="fql (rules)"
            commands={
              "define rule NO_TEENS_WITH_JOBS: \n\t\ton Users when not null (jobs.name)\n\t\t and age < 18 is invalid"
            }
            id="rule-1"
          />,
          <p className="wiki-text" key={31}>
            Let's see what happens now when we start inserting new cases on the
            "ruled" Form:
          </p>,
          <CodeExample
            key={32}
            language="fql (create new)"
            commands={
              "create new Users values ('Susane', 24, 'Engineer', 'Cuba')\ncreate new Users values ('Anne', 12, ('Doctor', 'Actor'), 'Argentina')\ncreate new Users values ('Anne', 12, null, 'Argentina')\ncreate new Users values ('John', 42, ('Doctor', 'Actor', 'Freelancer'), 'Uruguay')\ncreate new Users values ('Frida', 35, 'Scientist', 'Argentina')\ncreate new Users values ('Albert', 26, null, 'Japan')"
            }
            id="multiline"
          />,
          <p className="wiki-text" key={33}>
            As expected, the error is thrown when we try to create the entry
            Anne, because it is and Invalid case according to the previously
            defined rule.
          </p>,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Defining some rules"
        elems={[
          <p className="wiki-text" key={4}>
            Now, it's time to define some new rules. Check it out:
          </p>,
          <CodeExample
            key={40}
            language="fql (rules)"
            commands={
              "define rule NO_CUBAN_PEOPLE:  \n\t\ton Website when country.name = 'Cuba' is invalid"
            }
            id="rule-1"
          />,
          <CodeExample
            key={41}
            language="fql (rules)"
            commands={
              "define rule NO_TEENS_IN_ADULTS_WEBSITE:  \n\t\ton Website when name equal 'adults'  \n\t\tand age between 1 and 18 is invalid"
            }
            id="rule-1"
          />,
          <CodeExample
            key={42}
            language="fql (rules)"
            commands={
              "define rule NO_DOCTORS_IN_HEAVEN_WEBSITE:  \n\t\ton Website when name equal 'heaven'  \n\t\tand jobs.name equal 'Doctor' is invalid"
            }
            id="rule-1"
          />,
          <p className="wiki-text" key={31}>
            Let's add some entries to the forms and see what happens:
          </p>,
          <CodeExample
            key={43}
            language="fql (create new)"
            commands={
              "create new Website values ('friends', 'friends.com', 'Susane')\ncreate new Website values ('google' , 'google.com' , ('Anne', 'John', 'Frida'))\ncreate new Website values ('adults' , 'adults.com' , ('Anne', 'Albert'))\ncreate new Website values ('heaven' , 'heaven.com' , ('John', 'Frida', 'Albert'))"
            }
            id="multiline"
          />,
          <p className="wiki-text" key={44}>
            As expected, the error is thrown when we try to create the entries
            "friends.com", "adults.com" and "heaven.com", because they aren't
            legal according to the rules defined.
          </p>,
        ]}
      />

      <WikiSection
        id="section-5"
        title="Getting faster and simplier"
        elems={[
          <p className="wiki-text" key={5}>
            If we have some experience using SQL (or similar), we must know that
            retrieving information from a table (or some), using some logical
            expressions and joins might be tricky and non-friendly.
          </p>,
          <CodeExample
            key={50}
            language="fql (get)"
            commands={
              "get Website (jobs.name, country.name) \n\t\twith (age between 5 and 27) \n\t\tand name = 'google'"
            }
            id="get-1"
          />,
          <p className="wiki-text" key={51}>
            Let's check another example:
          </p>,
          <CodeExample
            key={52}
            language="fql (get)"
            commands={
              "get Website (users.username, users.age) \n\t\twith jobs.name <> 'Musician' \n\t\tor country.name = 'Argentina'"
            }
            id="get-2"
          />,
        ]}
      />
    </motion.div>
  );
};

const AmazingBooks = () => {
  const index = [
    {
      id: 1,
      title: "Introducing the store",
    },
    {
      id: 2,
      title: 'Adding "Amazing Books"',
    },
    {
      id: 3,
      title: "Placing an order",
    },
    {
      id: 4,
      title: "Getting faster and simplier",
    },
  ];

  const introduction = [
    <p className="wiki-text" key="introduction-1">
      After learning the basics of FQL you might ask: is that all?
    </p>,
    <p className="wiki-text" key="introduction-2">
      Well, obviously not. <br key={0} />
      This section is dedicated to show more advanced examples that will help
      you realize how powerful FQL can be and still, so simple, friendly, and
      concise. Creating some forms, adding values, setting rules, actions, and
      retrieving info with clear constraints and conditions in a manner that is
      readable for the non-technical user. Yes, it may seem like magic ;)
    </p>,
    <p className="wiki-text" key="introduction-3">
      Let's have a look!
    </p>,
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="wiki-title">Amazing Books Store </h2>
      <br />
      <Index index={index} introduction={introduction} />
      <WikiSection
        id="section-1"
        title="Introducing the store"
        elems={[
          <p className="wiki-text" key={1}>
            Amazing Books sells books from different authors around the world.
          </p>,
          <p className="wiki-text" key={10}>
            We will see how FQL can help in the different searches that users
            can perform, how an order is entered and stored in the system and
            how we can then run some queries also about the orders already
            entered.
          </p>,
          <p className="wiki-text" key={11}>
            Let's now create the main Forms we will need to store information
            about our books store: Countries, Cities, Addresses, Customers,
            Authors.
          </p>,
          <CodeExample
            key={12}
            language="fql (create form)"
            commands={
              "create form Countries(name text not null) \ncreate form Cities(name text not null, country references Countries.name, capital boolean) \ncreate form Addresses(street text, house_number number, city references Cities.(name, country)) \ncreate form Customers(name text not null,last_name text,address references Addresses.(street, house_number, city.name)) \ncreate form Authors(name text not null,last_name text not null,address references Addresses.(street, house_number, city.name))"
            }
            id="multiline"
          />,
          <p className="wiki-text" key={13}>
            For storing the info about books and the orders generated we will
            use the following forms: Books Categories, Keywords, Books and
            Orders.
          </p>,
          <CodeExample
            key={14}
            language="fql (create form)"
            commands={
              "create form BooksCategories (name text not null unique) \ncreate form Keywords(name text not null unique) \ncreate form Books(title text not null unique,isbn text not null unique,author references Authors.(name, last_name, address.city.name, address.city.country.name),category references 1..1 BooksCategories.name, price number, keywords references 1..many Keywords.name unique) \ncreate form Orders(customer references Customers.(name, last_name), books references 1..many Books.(title, author.name), total_amount number)"
            }
            id="multiline"
          />,
        ]}
      />

      <WikiSection
        id="section-2"
        title='Adding "Amazing Books"'
        elems={[
          <p className="wiki-text" key={2}>
            It's time now to add some information to all those forms. Let's
            start with the countries, cities and addresses.
          </p>,
          <CodeExample
            key={20}
            language="fql (create new)"
            commands={
              'create new Countries values ("Cuba")\ncreate new Countries values ("Japan")\ncreate new Countries values ("Swiss")\ncreate new Countries values ("Uruguay")'
            }
            id="multiline"
          />,
          <CodeExample
            key={21}
            language="fql (create new)"
            commands={
              'create new Cities values ("Havana", "Cuba", true)\ncreate new Cities values ("Kyoto", "Japan", false)\ncreate new Cities values ("Montevideo", "Uruguay", true)\ncreate new Cities values ("Bern", "Swiss", true) \ncreate new Cities values ("Zurich", "Swiss", false)'
            }
            id="multiline"
          />,
          <CodeExample
            key={22}
            language="fql (create new)"
            commands={
              'create new Addresses values ("Carlos III", 620, "Havana")\ncreate new Addresses values ("G St.", 255, "Havana")\ncreate new Addresses values ("Paula St.", 28, "Havana")\ncreate new Addresses values ("Bahnhofstrasse", 12, "Zurich")\ncreate new Addresses values ("Teramachi", 30, "Kyoto")'
            }
            id="multiline"
          />,

          <p className="wiki-text" key={23}>
            Now, let's add some authors, customers, keywords tags for the books,
            and a couple of books to our forms. Starting with the authors and
            costumers.
          </p>,
          <CodeExample
            key={24}
            language="fql (create new)"
            commands={
              'create new Authors values ("Cirilo","Villaverde", "Carlos III")\ncreate new Authors values ("Haruki", "Murakami", "Teramachi")\ncreate new Authors values ("Jose", "Marti", ("Paula St.", 28, "Havana"))\n\ncreate new Customers values ("John", "Doe", ("Bahnhofstrasse", 12, "Zurich"))\ncreate new Customers values ("Jane", "Doe", ("G St.", 255, "Havana"))'
            }
            id="multiline"
          />,
          <p className="wiki-text" key={25}>
            The books in the store will have tags (also know as keywords), that
            will help the user to identify its content. For example:
          </p>,
          <CodeExample
            key={26}
            language="fql (create new)"
            commands={
              'create new Keywords values ("interesting")\ncreate new Keywords values ("boring")\ncreate new Keywords values ("excellent")\ncreate new Keywords values ("mystery") \ncreate new Keywords values ("aliens")\ncreate new Keywords values ("fiction")\ncreate new Keywords values ("novel")'
            }
            id="multiline"
          />,
          <p className="wiki-text" key={27}>
            It's time to add some categories for the books and finally add some
            books to our forms.
          </p>,
          <CodeExample
            key={28}
            language="fql (create new)"
            commands={
              'create new BooksCategories values ("fiction")\ncreate new BooksCategories values ("horror")\ncreate new BooksCategories values ("humor")\ncreate new BooksCategories values ("classics")\ncreate new BooksCategories values ("drama")'
            }
            id="multiline"
          />,
          <CodeExample
            key={29}
            language="fql (create new)"
            commands={
              'create new Books values ("La Loma del Angel", "978-987-958-6747", ("Cirilo", "Villaverde"), "drama", 18, ("interesting", "excellent"))\ncreate new Books values ("Tokyo Blues", "978-848-383-5524", ("Haruki", "Murakami"), "drama", 15, ("interesting", "mystery", "novel"))\ncreate new Books values ("La Edad de Oro", "978-959-271-2003", ("Jose", "Marti"), "classics", 10, ("interesting", "fiction", "excellent"))'
            }
            id="multiline"
          />,
        ]}
      />
      <WikiSection
        id="section-3"
        title="Placing an order"
        elems={[
          <p className="wiki-text" key={3}>
            Well, the info is all in. Now let's suppose a customer wants to buy
            a book. It cannot be simplier than placing a new order translating
            it to FQL as the ability to create a new entry to the form Orders.
          </p>,
          <CodeExample
            key={30}
            language="fql (create new)"
            commands={
              'create new Orders values (("John", "Doe"), "La Loma del Angel", 18)\ncreate new Orders values (("Jane", "Doe"), ("Tokyo Blues", "La Edad de Oro"), 16)'
            }
            id="multiline"
          />,
        ]}
      />
      <WikiSection
        id="section-4"
        title="Getting faster and simplier"
        elems={[
          <p className="wiki-text" key={4}>
            If we have some experience using SQL (or similar), we must know that
            retrieving information from a table (or some), using some logical
            expressions and joins might be tricky and non-friendly. Let's check
            some examples.
          </p>,
          <p className="wiki-text" key={40}>
            Here we want to retrieve all the authors that are Cubans.
          </p>,
          <CodeExample
            key={41}
            language="fql (get)"
            commands={
              'get Authors (name, city.name)\n\twith country.name = "Cuba"'
            }
            id="get-1"
          />,
          <p className="wiki-text" key={42}>
            The following represents the SQL code necessary for making the
            previous query.
          </p>,
          <SQLExample
            key={43}
            language={"SQL"}
            commands={
              'SELECT authors.name AS name, authors_address_city.name AS city"."name \n\tFROM authors \n\tLEFT JOIN addresses AS authors_address ON (authors.address = authors_address.fql_id)\n\tLEFT JOIN cities AS authors_address_city ON (authors_address.city = authors_address_city.fql_id) \n\tLEFT JOIN countries AS authors_address_city_country \n\tON (authors_address_city.country = authors_address_city_country.fql_id) \n\tWHERE (authors_address_city_country.name = "Cuba"'
            }
            id={"SQL"}
          />,
          <p className="wiki-text" key={44}>
            If we want to obtain all the orders that contains books with Cuban
            authors it will be so simple as:
          </p>,
          <CodeExample
            key={45}
            language="fql (get)"
            commands={
              'get Orders (customer.name, books.title, books.author.name) \n\twith author.address.city.country.name = "Cuba"'
            }
            id="get-1"
          />,
          <p className="wiki-text" key={46}>
            Not so simple in a SQL query:
          </p>,
          <SQLExample
            key={47}
            language={"SQL"}
            commands={
              'SELECT orders_customer.name AS customer"."name, orders_books.title \n\tAS books"."title, orders_books_author.name \n\tAS books"."author"."name FROM orders \n\tLEFT JOIN customers AS orders_customer ON (orders.customer = orders_customer.fql_id) \n\tLEFT JOIN orders_books AS orders_orders_books ON (orders.fql_id = orders_orders_books.orders) \n\tLEFT JOIN books AS orders_books ON (orders_orders_books.books = orders_books.fql_id) \n\tLEFT JOIN authors AS orders_books_author ON (orders_books.author = orders_books_author.fql_id) \n\tLEFT JOIN addresses AS orders_books_author_address ON (orders_books_author.address = orders_books_author_address.fql_id) \n\tLEFT JOIN cities AS orders_books_author_address_city ON (orders_books_author_address.city = orders_books_author_address_city.fql_id) \n\tLEFT JOIN countries AS orders_books_author_address_city_country ON (orders_books_author_address_city.country = orders_books_author_address_city_country.fql_id) \n\tWHERE (orders_books_author_address_city_country.name = "Cuba"'
            }
            id={"SQL"}
          />,
        ]}
      />
    </motion.div>
  );
};

const wikiComponents = [
  {
    path: "introduction",
    section: "Getting Started",
    component: <Introduction />,
    title: "Introduction",
    url: "/wiki/introduction",
  },
  {
    path: "create_form",
    section: "Commands",
    component: <CreateForm />,
    title: "Create Form",
    url: "/wiki/create_form",
  },
  {
    path: "show_forms",
    section: "Commands",
    component: <ShowForms />,
    title: "Show Forms",
    url: "/wiki/show_forms",
  },
  {
    path: "remove_form",
    section: "Commands",
    component: <RemoveForms />,
    title: "Remove Form",
    url: "/wiki/remove_form",
  },
  {
    path: "create_new",
    section: "Commands",
    component: <CreateNew />,
    title: "Create New",
    url: "/wiki/create_new",
  },
  {
    path: "get_case",
    section: "Commands",
    component: <GetCase />,
    title: "Get Case",
    url: "/wiki/get_case",
  },
  {
    path: "modify_case",
    section: "Commands",
    component: <ModifyCase />,
    title: "Modify Case",
    url: "/wiki/modify_case",
  },
  {
    path: "remove_case",
    section: "Commands",
    component: <RemoveCase />,
    title: "Remove Case",
    url: "/wiki/remove_case",
  },
  {
    path: "define_function",
    section: "Commands",
    component: <DefineFunction />,
    title: "Define Function",
    url: "/wiki/define_function",
  },
  {
    path: "is_used_by",
    section: "Commands",
    component: <IsUsedBy />,
    title: "Is Used By",
    url: "/wiki/is_used_by",
  },
  {
    path: "rules_intro",
    section: "Rules",
    component: <RulesIntro />,
    title: "Rules Intro",
    url: "/wiki/rules_intro",
  },
  {
    path: "validations",
    section: "Rules",
    component: <Validations />,
    title: "Validations",
    url: "/wiki/validations",
  },
  {
    path: "amazing_books",
    section: "Examples",
    component: <AmazingBooks />,
    title: "Amazing Books",
    url: "/wiki/amazing_books",
  },
  {
    path: "advanced_examples",
    section: "Examples",
    component: <AdvancedExamples />,
    title: "Advanced Examples",
    url: "/wiki/advanced_examples",
  },
];

const Wiki = () => {
  const { componentPath } = useParams();
  const component = wikiComponents.find((item) => item.path === componentPath)
    ?.component || <Error />;
  const mainBodyRef = useRef();
  const hash = useLocation().hash;

  const wikiSections = ["Getting Started", "Commands", "Rules", "Examples"];

  setTimeout(() => {
    document.getElementById("wiki-container").addEventListener(
      "load",
      () => {
        if (hash) {
          const section = document.getElementById(hash.substring(1));
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        }
      },
      1000
    );
  });

  return (
    <motion.div
      className="wiki-container"
      id="wiki-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <TopButtons />
      <Sidebar
        componentPath={componentPath}
        wikiSections={wikiSections}
        wikiComponents={wikiComponents}
      />
      <div className="wiki-body" id="wiki-body" ref={mainBodyRef}>
        {component}
        <div className="invisible-bottom" />
      </div>
      <ScrollTop bodyRef={mainBodyRef} />
    </motion.div>
  );
};

export default Wiki;

import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import { GlobalContext } from "../GlobalContext";
import Error from "../../pages/Error";
import {
  TextField,
  NumberField,
  BooleanField,
  DateField,
  TimeField,
  ImageField,
  FileField,
  SingleReferenceField,
  MultipleReferenceField,
} from "./Fields";
import {
  Button,
  HStack,
  VStack,
  Spacer,
  Box,
  Card,
  Heading,
  CardBody,
  Center,
  CardHeader,
} from '@chakra-ui/react';
import theme from '../../theme';
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const EditForm = ({ setLoading }) => {
  const { userInfo, settings, setSettings, runCode, fqlToken } =
    useContext(GlobalContext);
  const { form_name } = useParams();
  const [formData, setFormData] = useState([]);
  const [error, setError] = useState(false);

  const getInfo = async () => {
    await runCode({
      code: `show forms ${form_name}`,
      token: userInfo.token,
    })
      .then((data) => {
        if (data.output && data.output.length !== 0)
          setFormData(data.output[0]["DATA-SPECS"]);
        else {
          setError(true);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userInfo && userInfo.token) {
      getInfo();
    } else {
      setError(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);

  const pushValue = (codeDict, title, value) => {
    if (codeDict[title]) {
      codeDict[title].push(value);
    } else {
      codeDict[title] = [value];
    }
  };

  const handleClearFields = () => {
    const inputs = document.getElementsByClassName("input-ps-cn");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = "";
      if (inputs[i].type === "file") {
        const index = inputs[i].id.split("-")[1];
        const clearElement = document.getElementById(`input-clear-${index}`);
        clearElement.click();
      }
    }
  };

  const handleCreateNew = async () => {
    const inputs = document.getElementsByClassName("input-ps-cn");
    const codeDict = {};

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.required) {
        if (input.value === "" || !input.value || input.value === null) {
          const requiredText = document.getElementById("required-text");
          requiredText.innerHTML = "* This field is required";
          setTimeout(() => {
            requiredText.innerHTML = "";
          }, 3000);
          return;
        }
      }

      if (!(input.value === "" || !input.value || input.value === null)) {
        switch (input.attributes.datatype.value) {
          case "text":
            pushValue(codeDict, input.title, `'${input.value}'`);
            break;
          case "date":
            pushValue(codeDict, input.title, `'${input.value}'`);
            break;
          case "number":
            pushValue(codeDict, input.title, input.value);
            break;
          case "boolean":
            pushValue(codeDict, input.title, input.value);
            break;
          case "file":
            if (input.textvalue === "" || input.textvalue === undefined) {
              pushValue(codeDict, input.title, `'${JSON.stringify({})}'`);
              break;
            }
            pushValue(codeDict, input.title, `'${input.textvalue}'`);
            break;
          case "image":
            if (input.textvalue === "" || input.textvalue === undefined) {
              pushValue(codeDict, input.title, `'${JSON.stringify({})}'`);
              break;
            }
            pushValue(codeDict, input.title, `'${input.textvalue}'`);
            break;
          default:
            break;
        }
      }
    }

    const keys = Object.keys(codeDict);
    const codeArray = [];

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = codeDict[key];

      if (value.length > 1) {
        codeArray.push(`${key} = (${value.join(", ")})`);
      } else {
        codeArray.push(`${key} = ${value[0]}`);
      }
    }

    const code = `create new ${form_name}(${codeArray.join(", ")})`;

    setLoading(true);
    setSettings({
      ...settings,
      message: { ...settings.message, show: false },
    });

    runCode({ code: code, token: userInfo.token })
      .then((data) => {
        setSettings({
          ...settings,
          message: {
            ...settings.message,
            status: data.msg.split(" ")[0].toLowerCase(),
            show: true,
            data: data.msg,
            fn: handleClearFields,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSettings({
          ...settings,
          message: {
            ...settings.message,
            status: "error",
            show: true,
            data: err.message,
            fn: () => {},
          },
        });
      });
  };

  const getField = (data, index) => {
    switch (data.TYPE) {
      case "TEXT":
        return <TextField data={data} index={index} />;
      case "NUMBER":
        return <NumberField data={data} index={index} />;
      case "BOOLEAN":
        return <BooleanField data={data} index={index} />;
      case "DATE":
        return <DateField data={data} index={index} />;
      case "TIME":
        return <TimeField data={data} index={index} />;
      case "IMAGE":
        return <ImageField data={data} index={index} />;
      case "FILE":
        return <FileField data={data} index={index} />;
      case "REFERENCE":
        const minCard = data["REF-CARDINALITY"][0];
        const maxCard = data["REF-CARDINALITY"][1];

        if (maxCard - minCard > 1)
          return (
            <MultipleReferenceField
              data={data}
              index={index}
              minCard={minCard}
              maxCard={maxCard}
            />
          );
        else
          return <SingleReferenceField data={data} index={index} parent="" />;

      default:
        break;
    }
  };

  const handleFillFields = (data) => {
    const inputs = document.getElementsByClassName("input-ps-cn");
    const refDict = {};

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.type === "file") {
        input.textvalue = data[input.title.toLowerCase()];
        const index = input.id.split("-")[1];
        const fillElement = document.getElementById(`input-fill-${index}`);
        fillElement.click();
        continue;
      } else if (
        input.parentElement.attributes.reftype &&
        input.parentElement.attributes.reftype.value === "ref-mult"
      ) {
        if (!refDict[input.title]) {
          refDict[input.title] = "done";
          const index = input.id.split("-")[1];
          const fillElement = document.getElementById(`input-fill-${index}`);
          fillElement.attributes.data.value = JSON.stringify(data);
          fillElement.click();
        }
      } else input.value = data[input.title.toLowerCase()];
    }
  };

  const handleGetEntries = async () => {
    const inputs = document.getElementsByClassName("input-ps-cn");
    const code = [];

    for (let i = 0; i < inputs.length; i++) {
      const item = inputs[i];
      if (item.value && item.value !== "") {
        if (code.length === 0) {
          code.push(`with ${item.title} = '${item.value}' `);
        } else {
          code.push(`and ${item.title} = '${item.value}' `);
        }
      }
    }
    setLoading(true);
    setSettings({
      ...settings,
      message: { ...settings.message, show: false },
    });
    fqlToken.current = undefined;

    await runCode({
      code: `get ${form_name} ${code.join("")}`,
      token: userInfo.token,
    })
      .then((data) => {
        if (data.output && data.output.length > 0) {
          if (data.output.length === 1) {
            handleFillFields(data.output[0]);
            fqlToken.current = {
              "FORM-NAME": data.fql_token["FORM-NAME"],
              TOKEN: [data.fql_token.TOKEN[0]],
            };
          } else {
            setSettings({
              ...settings,
              modals: {
                ...settings.modals,
                title: "SEARCH RESULTS",
                icon: "icon-fields-form",
                id: "modal-get",
                show: true,
                data: data.output,
              },
            });
            fqlToken.current = data.fql_token;
          }
          setLoading(false);
        } else {
          setSettings({
            ...settings,
            message: {
              ...settings.message,
              status: "info",
              show: true,
              data: data.msg,
              fn: () => {},
            },
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSettings({
          ...settings,
          message: {
            ...settings.message,
            status: "error",
            show: true,
            data: err.message.toUpperCase(),
            fn: () => {},
          },
        });
        setLoading(false);
      });
  };

  const handleModifyCase = async () => {
    const inputs = document.getElementsByClassName("input-ps-cn");
    const code = [];

    for (let i = 0; i < inputs.length; i++) {
      const item = inputs[i];
      if (item.value) {
        code.push(`'${item.value}'`);
      }
    }
    setLoading(true);
    setSettings({
      ...settings,
      message: { ...settings.message, show: false },
    });
    const command = `modify ${form_name} (${code.join(", ")})`;

    await runCode({
      code: command,
      token: userInfo.token,
    })
      .then((data) => {
        if (data.msg) {
          const status = data.msg.split(" ")[0].toLowerCase();
          setSettings({
            ...settings,
            message: {
              ...settings.message,
              status: status,
              show: true,
              data: data.msg,
              fn: status !== "error" ? handleClearFields : () => {},
            },
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSettings({
          ...settings,
          message: {
            ...settings.message,
            status: "error",
            show: true,
            data: err.message.toUpperCase(),
            fn: () => {},
          },
        });
        setLoading(false);
      });
  };

  return (
    <Box fontFamily={theme.fonts.body}>
      {!error ? (
        <>
          <div className="container">
            <div className={"items-container row"}>
              <VStack mt={10} mb={5}>
                <Heading align="left" zIndex="999">
                  Create new entry
                </Heading>
                <HStack pb={5}>
                  <Button onClick={handleGetEntries}>
                    <span className="font-cn-search" title="SEARCH" style={{ fontSize: 'small' }} />
                  </Button>
                  <Button onClick={handleModifyCase}>
                    <span className="font-cn-modify" title="MODIFY" style={{ fontSize: 'small' }} />
                  </Button>
                  <Button>
                    <span className="font-cn-delete" title="DELETE" style={{ fontSize: 'small' }} />
                  </Button>
                  <Button>
                    <span className="font-cn-chart" title="SHOW CHART" style={{ fontSize: 'small' }} />
                  </Button>
                  <Button>
                    <span className="font-cn-upload" title="UPLOAD FILE(S)" style={{ fontSize: 'small' }} />
                  </Button>
                </HStack>
              </VStack>
              <Center>
                <VStack minW="650px">
                  <Card w="100%" py={5} px={8} mb={8}>
                    <CardHeader fontSize="2xl">
                      {form_name}
                    </CardHeader>
                    <CardBody>
                      {formData.map((item, index) => (
                        <div
                          key={`div-ps-cn-${index}`}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {getField(item, index)}
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                  <HStack w="100%" px={2} gap={5}>
                    <Button variant="link" as={RouterLink} to="/old/presentation_server/home">
                      <FA icon="arrow-left" />
                      Back
                    </Button>
                    <Spacer />
                    <Button variant="secondary" size="md" onClick={handleClearFields}>
                      Reset
                    </Button>
                    <Button onClick={handleCreateNew} size="md">
                      Create
                    </Button>
                  </HStack>
                </VStack>
              </Center>
            </div>
          </div>
        </>
      ) : (
        <Error />
      )}
    </Box>
  );
};

export default EditForm;

import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const CreateNew = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Create New</Heading>
        <Text>
          The 'create new' command allows the user to add new entries to the
          previously created forms. This new entries have to comply with the
          parameters we have defined for each form. Although we decided to name the
          command as 'create new', we can execute just 'create' and the ID of the
          form we are going to add a new entry to.
        </Text>
        <Text>
          Let's go deeper!
        </Text>
      </section>
      <section>
        <SectionTitle>Create New grammar</SectionTitle>
        <Text>
          Let's check the grammar for the CREATE NEW command:
        </Text>
        <CodeBlock>{`\
create-new ::=
  "create" ["new"] id ["values"] "(" value-list ")";

value-list ::=
  literal {"," value-list } |
  "(" value-list ")" {"," value-list };

literal ::=
  text |
  "null" |
  "true" |
  "false" |
  number |
  int;
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this command that will allow us to demonstrate the use of
          the "create new" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Nationalities (name text)

create form Directors (
  name text,
  nationality references Nationalities.name)

create form Movies (
  name text,
  length number,
  director references 1..3 Directors.name)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Creating new entries</SectionTitle>
        <Text>
          Adding a new entry cannot be simplier. Let's see how to add some
          data to the form Nationalities, previously created.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Nationalities values ("Cuban")
create new Nationalities values ("Japanese")
create new Nationalities values ("Uruguayan")
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>New entries with references</SectionTitle>
        <Text>
          This new entry of the form Directors need a name and a reference to
          a nationality. To fulfill the reference requirement we must insert
          either the first parameter of the referenced form or all the
          parameters. For example:
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create Directors ("Leonardo Padura", "Cuban")
create Directors ("Enrique Colina", "Cuban")
create Directors ("Hayao Miyazaki", "Japanese")
create Directors ("Isao Takahata", "Japanese")
create Directors ("Federico Veiroj", "Uruguayan")
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>New entries with multiple references</SectionTitle>
        <Text>
          If we add some entries to the form Movies we can see how we can set
          a list of directors for one movie as we defined it before. Check it
          out:
        </Text>
        <CodeExample title="fql (create new)">{`\
create Movies values ('Acne', 87, 'Federico Veiroj')

create Movies values (
  'Castle in the Sky',
  124,
  ('Hayao Miyazaki','Isao Takahata'))

create Movies values (
  'Un Rey en La Habana',
  102,
  ('Leonardo Padura','Enrique Colina'))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (create new)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Create New grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Creating new entries</LinkToSection></ListItem>
        <ListItem><LinkToSection>New entries with references</LinkToSection></ListItem>
        <ListItem><LinkToSection>New entries with multiple references</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default CreateNew;

import React, { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const Views = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const changeViewToList = () => {
    setSettings({
      ...settings,
      console: { ...settings.console, show: false, form: undefined },
      theme: { ...settings.theme, view: "list" },
    });
  };
  const changeViewToBox = () => {
    setSettings({
      ...settings,
      console: { ...settings.console, show: false, form: undefined },
      theme: { ...settings.theme, view: "box" },
    });
  };
  const changeViewToConsole = () => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, view: "" },
      console: { ...settings.console, show: true },
    });
  };

  return (
    <>
      <ul className="views">
        <li>
          <div
            className={`icon-list ${
              settings.theme.view === "list" ? "active" : ""
            }`}
            title="LIST VIEW"
            onClick={changeViewToList}
            data-toggle="tooltip"
            data-placement="top"
          ></div>
        </li>
        <li>
          <div
            className={`icon-boxes ${
              settings.theme.view === "box" ? "active" : ""
            }`}
            title="BOX VIEW"
            onClick={changeViewToBox}
            data-toggle="tooltip"
            data-placement="top"
          ></div>
        </li>
        <li>
          <div
            title="CONSOLE VIEW"
            onClick={changeViewToConsole}
            data-toggle="tooltip"
            data-placement="top"
            style={{
              display: "flex",
            }}
          >
            <FA
              icon="laptop"
              color={`${settings.console.show ? "#ff5e54" : "#a8a5a1"}`}
              fontSize={"0.89em"}
            />
          </div>
        </li>
      </ul>
    </>
  );
};

export default Views;

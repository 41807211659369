import React from "react";
import { Link } from "react-router-dom";
import skinClear from "../../../sass/images/skin-clear.jpg";
import skinvintage from "../../../sass/images/skin-vintage.jpg";
import skinFuturist from "../../../sass/images/skin-futurist.jpg";

const SkinBox = ({ active, title, changeSkin, skin }) => {
  const handleMouseEnter = (e) => {
    e.target.parentElement.parentElement.classList.add("config");
  };

  const handleMouseLeave = (e) => {
    e.target.parentElement.parentElement.classList.remove("config");
  };

  return (
    <div className="col-xs-12 col-sm-6 col-lg-4">
      <div className={`skin-box ${active ? "active" : ""}`}>
        <div className="skin-box-header">
          <h4>{title}</h4>
          <button className="btn btn-icon" onClick={changeSkin}>
            <span className="icon-selected"></span>
            {active ? <i>Selected</i> : ""}
          </button>
          {active ? (
            <Link
              to="config"
              id="myid"
              onMouseEnter={handleMouseEnter}
              onMouseOver={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="btn btn-icon edit-configurations"
            >
              <span className="icon-config"></span>
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="skin-box-body">
          <img
            src={
              skin === "vintage"
                ? skinvintage
                : skin === "clear"
                ? skinClear
                : skinFuturist
            }
            alt={title}
          />
          <span className="edit-config-details">
            <i>Edit details of the Skin</i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SkinBox;

import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import theme from '../../theme';

const BorderBox = styled(Box)`
  border: 1px solid;
  border-color: ${theme.colors.gray[100]};
  border-radius: 8px;
  padding: 8px 15px;
`;

export default BorderBox;

import React from "react";
import { Link } from "react-router-dom";

const CreateForm = () => {
  return (
    <div className="create-new-form">
      <span className="text hidden-sm-down">CREATE NEW FORM!</span>
      <span className="icon-arrow hidden-sm-down"></span>
      <Link to="/presentation_server/create_form" id="add-form" title="">
        <span className="icon-plus"></span>
      </Link>
    </div>
  );
};

export default CreateForm;

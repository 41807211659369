import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Heading,
  Box,
  UnorderedList,
} from "@chakra-ui/react";

const toSectionId = (str) => (
  encodeURIComponent((str || '').toLowerCase().replace(/\s/g, '-'))
);

export const SectionTitle = ({ children, ...props }) => (
  <>
    <Link id={toSectionId(children)} pos="relative" top="-100px" display="block"></Link>
    <Heading as="h3" {...props}>{children}</Heading>
  </>
);

export const LinkToSection = ({ children, ...props }) => (
  <Link as={RouterLink} to={`#${toSectionId(children)}`} {...props}>{children}</Link>
);

export const SectionIndex = ({ children, ...props }) => (
  <Box maxW="280px" minW="200px" {...props}>
    <Heading as="h6" variant="page-index">Index</Heading>
    <UnorderedList variant="page-index" mx="0" color="accent.darkgray">
      {children}
    </UnorderedList>
  </Box>
);

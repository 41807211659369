import { useContext, useState } from "react";
import Table from "../component/Table";
import ImageContainer from "../component/ImageContainer";
import { GlobalContext } from "../component/GlobalContext";
import { apiBaseURL } from "../config";
import DefaultLayout from "../layouts/default";
import Console from '../new_component/organisms/Console';
import { Box } from '@chakra-ui/react';

const MyTerminal = () => {
  const [termUserInfo, setTermUserInfo] = useState({});
  const [connection, setConnection] = useState({ connected: false });
  const {
    settings,
    setSettings,
    fqlToken,
    groupName,
    groupOwnerEmail,
    userInfo,
  } = useContext(GlobalContext);
  const scheme = window.location.protocol;

  const getToken = async (host) => {
    await fetch(`${scheme}//${host}/api/new_guest_token`, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setTermUserInfo({ token: data.token });
      })
      .catch((err) => console.log(err));
  };

  const runCode = async (command) => {
    return await fetch(`${scheme}//${connection.host}/api/run_code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code: command,
        token: termUserInfo.token,
        fql_token: fqlToken.current,
        group_name: groupName.current,
        group_owner_email: groupOwnerEmail.current,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const keys = [];
        if (data.output && data.output.length !== 0) {
          for (const k in data.output[0]) {
            keys.push(k);
          }
        }
        if (data.fql_token) {
          setSettings({
            ...settings,
            userData: {
              ...settings.userData,
              fqlToken: {
                "FORM-NAME": data.fql_token["FORM-NAME"],
                TOKEN: data.fql_token.TOKEN,
              },
            },
          });
        }
        if (data.group_name) {
          setSettings({
            ...settings,
            userData: {
              ...settings.userData,
              groupName: data.group_name,
            },
          });
        }
        if (data.group_owner_email) {
          setSettings({
            ...settings,
            userData: {
              ...settings.userData,
              groupOwnerEmail: data.group_owner_email,
            },
          });
        }
        return (
          <>
            {data.msg}
            {data.output ? <Table output={data.output} keys={keys} /> : ""}
          </>
        );
      })
      .catch((err) => `Bad FQL code: ${err}`);
  };

  const commands = {
    connect: {
      description:
        "Connects the terminal emulator to a backend engine on the specified <address>",
      usage: "connect <address>",
      fn: async (...args) => {
        if (args.length === 0 || args.length > 1)
          return "One argument expected";

        if (connection.connected)
          return `Already connected to ${connection.host}`;
        return fetch(`${scheme}//${args[0]}/api/connect`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then(async (data) => {
            if (data.ok === "ok") {
              setConnection({ connected: true, host: args[0] });
              if (!termUserInfo.token) {
                const apiHost = apiBaseURL.split("//")[1].split("/")[0];
                if (args[0] === apiHost) {
                  setTermUserInfo({ token: userInfo.token });
                } else await getToken(args[0]);
              }
              return `Done! Connected to ${args[0]}`;
            }
          })
          .catch((err) => {
            console.error(err);
            return "Connection error. Check the address and try again!";
          });
      },
    },

    connected: {
      description:
        "Returns a boolean value if the terminal is connected to an engine or not.",
      fn: () => {
        return connection.connected
          ? `Connected to host ${connection.host}`
          : "Disconnected!";
      },
    },

    disconnect: {
      description: "Disconnects the backend engine.",
      fn: () => {
        if (connection.connected) {
          setTermUserInfo({});
          setConnection({ connected: false });
          return "Disconnected!";
        } else {
          return "There is no engine connected";
        }
      },
    },

    echo: {
      description: "Echo a passed string.",
      usage: "echo <string>",
      fn: (...args) => {
        return args.join(" ");
      },
    },

    token: {
      description: "Returns the user token identifier",
      fn: () =>
        connection.connected
          ? termUserInfo.token
          : "You need to connect an engine first",
    },

    "?": {
      description: "Shows the help",
      usage: "?",
      fn: () =>
        `
          clear - Clears the terminal window.
          connect - Connects to a backend engine on <address>: connect <address>
          connected - Returns a boolean indicating if the terminal is connected to an engine or not.
          disconnect - Disconnects from the backend engine.
          echo - Echoes a string: echo <string>
          help or ? - Shows this help. See the Wiki for all FQL commands.
          `,
    },

    create: {
      description: "See the Wiki for all FQL commands.",
      usage: "",
      fn: async (...args) => {
        return !connection.connected
          ? "You need to connect an engine first!"
          : await runCode(`create ${args.join(" ")}`);
      },
    },
    get: {
      description: "See the Wiki for all FQL commands.",
      usage: "",
      fn: async (...args) => {
        return !connection.connected
          ? "You need to connect an engine first!"
          : await runCode(`get ${args.join(" ")}`);
      },
    },
    modify: {
      description: "See the Wiki for all FQL commands.",
      usage: "",
      fn: async (...args) => {
        return !connection.connected
          ? "You need to connect an engine first!"
          : await runCode(`modify ${args.join(" ")}`);
      },
    },
    remove: {
      description: "See the Wiki for all FQL commands.",
      usage: "",
      fn: async (...args) => {
        return !connection.connected
          ? "You need to connect an engine first!"
          : await runCode(`remove ${args.join(" ")}`);
      },
    },
    show: {
      description: "See the Wiki for all FQL commands.",
      usage: "",
      fn: async (...args) => {
        return !connection.connected
          ? "You need to connect an engine first!"
          : await runCode(`show ${args.join(" ")}`);
      },
    },
    define: {
      description: "See the Wiki for all FQL commands.",
      usage: "",
      fn: async (...args) => {
        return !connection.connected
          ? "You need to connect an engine first!"
          : await runCode(`define ${args.join(" ")}`);
      },
    },
  };

  return (
    <DefaultLayout>
      <Box mt={-70}>
        <ImageContainer>
          <div className={connection.connected.toString()} id="connected"></div>
          <Console commands={commands} userToken={connection.connected && termUserInfo.token}
            style={{
              backgroundColor: "#333235",
              minHeight: "620px",
              minWidth: "0px",
              paddingBottom: "0px",
            }}
            welcomeMessage={[
              <div style={{ fontSize: 18 }}>
                {" "}
                Welcome to the Application Base Console.
              </div>,
              <>
                To start, connect to an engine, like{" "}
                <b style={{ color: "#ed9c34" }}> engine.applicationbase.org</b>,
                or type <b style={{ color: "#ed9c34" }}>?</b> for help.
              </>,
            ]} />
        </ImageContainer>
      </Box>
    </DefaultLayout>
  );
};

export default MyTerminal;

import { useContext, useRef } from "react";
import { GlobalContext } from "../../GlobalContext";

const SearchField = () => {
  const { forms, setLoading } = useContext(GlobalContext);
  const filteredForms = useRef([]);

  setTimeout(() => {
    if (forms.current)
      forms.current.map((item) => {
        !filteredForms.current.includes(item) &&
          filteredForms.current.push(item);
      });
  }, 500);

  const handleSearch = (e) => {
    setLoading(true);

    try {
      forms.current = filteredForms.current.filter(
        (item) =>
          item["FORMS"].toLowerCase().includes(e.target.value.toLowerCase()) &&
          item
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 10);
  };

  return (
    <div className="input-group search-field">
      <input
        className="form-control"
        type="search"
        placeholder="Search..."
        onChange={handleSearch}
        name="SearchBar"
        disabled={!forms.current}
      />
      <button className="input-group-addon">
        {/* <span className="icon-search"></span> */}
      </button>
    </div>
  );
};

export default SearchField;

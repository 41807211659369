import React from "react";

const ModalResetStyles = () => {
  return (
    <div>
      <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        CANCEL
      </button>
      <button
        type="button"
        className="btn btn-primary-2"
        data-bs-dismiss="modal"
      >
        ACCEPT
      </button>
    </div>
  );
};

export default ModalResetStyles;

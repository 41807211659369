import React, { useContext } from "react";
import Heading from "../component/ps/heading/Heading";
import SkinConfigBackgroundBox from "../component/ps/skins/SkinConfigBackgroundBox";
import SkinConfigColorsBox from "../component/ps/skins/SkinConfigColorsBox";
import SkinConfigHeaderBox from "../component/ps/skins/SkinConfigHeaderBox";
import SkinConfigWorkSpaceBox from "../component/ps/skins/SkinConfigWorkSpaceBox";
import SkinConfigLayoutBox from "../component/ps/skins/SkinConfigLayoutBox";
import { GlobalContext } from "../component/GlobalContext";

const SkinsConfig = () => {
  const { settings } = useContext(GlobalContext);

  return (
    <div className="work-zone">
      <Heading
        title={settings.theme.skin + " skin"}
        subtitle="(EDIT DETAILS)"
      />
      <section className="container">
        <SkinConfigColorsBox />
        {settings.theme.skin === "vintage" && <SkinConfigWorkSpaceBox />}
        {settings.theme.skin !== "vintage" && <SkinConfigHeaderBox />}
        {settings.theme.skin !== "vintage" && <SkinConfigBackgroundBox />}
        <SkinConfigLayoutBox />
      </section>
    </div>
  );
};

export default SkinsConfig;

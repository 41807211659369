import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const ShowForms = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Show Forms</Heading>
        <Text>
          While we are so busy creating forms and adding information to our forms,
          it might be useful to be able to know what forms we have already created
          and its properties. The 'show forms' command allows the user to fetch from
          one to multiple forms from the database. The showed list contains the
          forms requested and their parameters.
        </Text>
        <Text>
          For showing all the forms we have previously added, we just execute "show
          forms". This command will retrieve a list with all the forms we have
          created earlier. Instead if we want to show a specific form, we can
          execute the previous command along with a form name. As a result, we will
          obtain a list with the parameters of that form and their type.
        </Text>
        <Text>
          Let's get into it!
        </Text>
      </section>
      <section>
        <SectionTitle>Show Forms grammar</SectionTitle>
        <Text>
          Let's check the grammar for the SHOW FORMS command:
        </Text>
        <CodeBlock>{`\
show-forms ::=
  "show" "forms" {id}
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "show forms" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Contacts (
  name text,
  mobile_number number,
  email text,
  birthdate date)

create form Emails (
  from references 1 Contacts.email,
  to references 1..many Contacts.email,
  topic text,
  body text)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Showing a Form</SectionTitle>
        <Text>
          When we want to show a form and its structure, we can execute "show forms"
          followed by the name of the form we want to see. It will
          give us the name of the form and its data with the corresponding type.
          Check the following code (click on the message to see the output):
        </Text>
        <CodeExample title="fql (show forms)">{`\
show forms Contacts
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Showing multiple Forms</SectionTitle>
        <Text>
          If you want to show more than one form you can execute the command
          follwed by a list of IDs separated by space. You will retrieve each
          form with all its parameters.
        </Text>
        <CodeExample title="fql (show forms)">{`\
show forms Contacts Emails
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (show forms)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Show Forms grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Showing a Form</LinkToSection></ListItem>
        <ListItem><LinkToSection>Showing multiple Forms</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default ShowForms;

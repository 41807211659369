import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const GetCase = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Get Case</Heading>
        <Text>
          It's time to learn how to retrieve some information from the forms. Now we
          can fetch one, some or all the entries we have previously added to our
          forms.
        </Text>
        <Text>
          The logical expressions will give us the power to filter the information
          we want to obtain from the forms. The can be as simple as you want and
          also the can be as nested as you need them to be.
        </Text>
        <Text>
          Let's get down to business!
        </Text>
      </section>
      <section>
        <SectionTitle>Get Case grammar</SectionTitle>
        <Text>
          Let's check the grammar for the GET command:
        </Text>
        <CodeBlock>{`\
get-case ::=
  "get" id ["(" label-path {"," label-path} ")"] ["with" logical-expression];

logical-expression ::=
  logical-term { "or" logical-term };

logical-term ::=
  logical-factor { "and" logical-factor };

logical-factor ::=
  comparison |
  "(" logical-expression ")";

comparison ::=
  limits-comparison |
  simple-comparison;

limits-comparison ::=
  value ["not"] "between" value "and" value;

simple-comparison ::=
  unary-operator "(" value ")" |
  value relational-operator value;

unary-operator ::=
  ["not"] "null";

value ::=
  literal |
  label-path;

label-path ::=
  id {"." id} ;

relational-operator ::=
  ["not"] "eq" |
  ["not"] "equal" |
  ["not"] "=" |
  "<>" |
  "!=" |
  ["not" | "!"] "<" |
  ["not" | "!"] ">" |
  "<=" |
  ">=";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "get" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Pet_Owners (name text, age number)

create form Pets(
  name text,
  animal text,
  breed text,
  owner references 1 Pet_Owners.(name, age))
`}
        </CodeExample>
        <Text>
          Now, let's add some cases to those forms.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Pet_Owners ('Louis', 15)
create new Pet_Owners ('Andrew', 24)
create new Pet_Owners ('Ana', 63)

create Pets('Loki','dog', 'Pekingese', 'Andrew')
create Pets('Tiger','cat', 'Persian', 'Ana')
create Pets('Nina','dog', 'Labrador', 'Louis')
create Pets('Fluff','cat', 'Siamese', 'Ana')
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Simpliest Get Case</SectionTitle>
        <Text>
          If we wish to retrieve all the entries of an entire form we can
          execute the 'get' command, optionally followed by the ID and the
          parameters we require, of the form we want to get info from. Code
          example below show us how to fetch all the data from the form Pets.
        </Text>
        <CodeExample title="fql (get case)">{`\
get Pets (breed)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Get Case Constraints</SectionTitle>
        <Text>
          Of course we don't want to always show all the entries of a form.
          The 'get... with...' command allows us to retrieve one or some
          entries depending on the conditions we specify. For example we are
          going to fetch all the Pets from Ana:
        </Text>
        <CodeExample title="fql (get case)">{`\
get Pets (name, breed)
  with owner.name = 'Ana'
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Logical expressions</SectionTitle>
        <Text>
          The magic continues when we are able to define logical expressions
          that let us filter the information we want to retrieve from the
          form. The following command will fetch all the dogs who have owners
          olders than 20 years.
        </Text>
        <CodeExample title="fql (get case)">{`\
get Pets (name, breed, owner.name, owner.age)
  with animal = 'dog'
  and owner.age > 20
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (get case)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Get Case grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Simpliest Get Case</LinkToSection></ListItem>
        <ListItem><LinkToSection>Get Case Constraints</LinkToSection></ListItem>
        <ListItem><LinkToSection>Logical expressions</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default GetCase;

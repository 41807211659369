import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Error.css";

const Error = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">Page not found</p>
      </div>
      <div className="gohome-container">
        <Link to="/" className="go-home">
          Go Home
        </Link>
      </div>
    </motion.div>
  );
};

export default Error;

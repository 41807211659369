import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const RemoveCase = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Remove Case</Heading>
        <Text>
          We had learned how to remove an entire form. Let's see now how to delete
          some entries from a specific form. We can go from removing all the entries
          and reseting it to blank to filtering which entries we want to delete
          using logical expressions.
        </Text>
        <Text>
          WARNING!!
          <br /> Removing a form entry will not be reversible and the data will be
          permanently lost.
        </Text>
      </section>
      <section>
        <SectionTitle>Remove Case grammar</SectionTitle>
        <Text>
          Let's check the grammar for the REMOVE command:
        </Text>
        <CodeBlock>{`\
remove-case ::=
  "remove" id ["with" logical-expression];

logical-expression ::=
  logical-term { "or" logical-term };

logical-term ::=
  logical-factor { "and" logical-factor };

logical-factor ::=
  comparison |
  "(" logical-expression ")";

comparison ::=
  limits-comparison |
  simple-comparison;

limits-comparison ::=
  value ["not"] "between" value "and" value;

simple-comparison ::=
  unary-operator "(" value ")" |
  value relational-operator value;

unary-operator ::=
  ["not"] "null";

value ::=
  literal |
  label-path;

label-path ::=
  id {"." id};

relational-operator ::=
  ["not"] "eq" |
  ["not"] "equal" |
  ["not"] "=" |
  "<>" |
  "!=" |
  ["not" | "!"] "<" |
  ["not" | "!"] ">" |
  "<=" |
  ">=";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "remove case" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Genres (name text)

create form Songs (
  name text,
  author text,
  genre text,
  year number)
`}
        </CodeExample>
        <Text>
          Now, let's add some cases to that form.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Genres ('rock')
create new Genres ('pop')
create new Genres ('reggaeton')
create new Genres ('classical')

create new Songs ('Careless Whisper', 'George Michael', 'pop-soul', 1984)
create new Songs ('Imagine', 'John Lenon', 'pop-rock', 1971)
create new Songs ('Gasolina', 'Daddy Yankee', 'reggaeton', 2004)
create new Songs ('Despacito', 'Luis Fonsi', 'pop-latino', 2017)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Removing entries</SectionTitle>
        <Text>
          If we wish to remove all the entries of an entire form we can
          execute the 'remove' command followed by the ID of the form we want
          to empty. Code example below show us how to remove all the data from
          the form Genres.
        </Text>
        <CodeExample title="fql (remove case)">{`\
remove Genres
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>The 'remove ... with ...' command</SectionTitle>
        <Text>
          Of course we don't want to always delete all the entries. The
          'remove... with...' command allows us to remove one or some entries
          depending on the conditions we specify. For example we are going to
          remove all the Songs newer than 2000:
        </Text>
        <CodeExample title="fql (remove case)">{`\
remove Songs with year > 2000
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Logical expressions</SectionTitle>
        <Text>
          The magic continues when we are able to define logical expressions
          that let us filter the information we want to delete from the form.
          The following command will remove all the pop-rock Songs from the
          70s.
        </Text>
        <CodeExample title="fql (remove case)">{`\
remove Songs
  with year > 1970
  and year < 1980
  and genre='pop-rock'
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (remove case)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Remove Case grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Removing entries</LinkToSection></ListItem>
        <ListItem><LinkToSection>The 'remove ... with ...' command</LinkToSection></ListItem>
        <ListItem><LinkToSection>Logical expressions</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default RemoveCase;

import React from "react";

const ModalNotifications = () => {
  return (
    <ul className="notification-list">
      <li>
        <p>Lorem ipsum dolor sit amet consercuter secter</p>
        <button type="button" className="btn btn-primary-2">
          OK
        </button>
      </li>
      <li>
        <p>Dolor sit amet consercuter secter</p>
        <button type="button" className="btn btn-primary-2">
          OK
        </button>
      </li>
      <li>
        <p>Ipsum dolor sit amet consercuter secter loremsy</p>
        <button type="button" className="btn btn-primary-2">
          OK
        </button>
      </li>
    </ul>
  );
};

export default ModalNotifications;

import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const SkinConfigWorkSpaceBox = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const handleChangeWorkspace = (e) => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, workspace: e.target.value },
    });
  };

  return (
    <div className="config-box col-xs-12 col-lg-6">
      <div className="item config-colors">
        <h3>BACKGROUND:</h3>
        <div className="left">
          <label>Workspace Color</label>
          <div
            className={`color workspace-color1 ${
              settings.theme.workspace === "workspace-color1" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="workspace-color1"
              name="workspace-color"
              onChange={handleChangeWorkspace}
            />
            <span></span>
          </div>
          <div
            className={`color workspace-color2 ${
              settings.theme.workspace === "workspace-color2" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="workspace-color2"
              name="workspace-color"
              onChange={handleChangeWorkspace}
            />
            <span></span>
          </div>
          <div
            className={`color workspace-color3 ${
              settings.theme.workspace === "workspace-color3" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="workspace-color3"
              name="workspace-color"
              onChange={handleChangeWorkspace}
            />
            <span></span>
          </div>
          <div
            className={`color workspace-color4 ${
              settings.theme.workspace === "workspace-color4" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="workspace-color4"
              name="workspace-color"
              onChange={handleChangeWorkspace}
            />
            <span></span>
          </div>
          <div
            className={`color workspace-color5 ${
              settings.theme.workspace === "workspace-color5" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="workspace-color5"
              name="workspace-color"
              onChange={handleChangeWorkspace}
            />
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkinConfigWorkSpaceBox;

const getApiBaseURL = () => {
  const hostName = window.location.hostname;
  const scheme = window.location.protocol;
  return hostName.match(/^localhost|127\.0\.0\.1$/)
    ? `${scheme}//${hostName}:7645/api`
    : hostName.match(/^applicationbase\.org$/)
    ? `${scheme}//engine.applicationbase.org/api`
    : `${scheme}//fqlengine.applicationbase.org/api`;
};

const getHostBaseURL = () => {
  const hostName = window.location.hostname;
  const scheme = window.location.protocol;
  return hostName.match(/^localhost|127\.0\.0\.1$/)
    ? `${scheme}//${hostName}:3000`
    : hostName.match(/^applicationbase\.org$/)
    ? `${scheme}//applicationbase.org`
    : `${scheme}//fqlconsole.applicationbase.org`;
};

export const apiBaseURL = getApiBaseURL();
export const hostBaseURL = getHostBaseURL();
console.log(apiBaseURL);
console.log(hostBaseURL);

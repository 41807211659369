import React from "react";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import { Alert, AlertIcon, Heading, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import { LinkToSection, SectionIndex, SectionTitle } from "../../new_component/organisms/SectionIndex";
import CodeExample from "../../new_component/organisms/CodeExample";

const Groups = () => (
  <WikiArticle>
    <WikiArticleMain>
    <section>
        <Heading as="h2">Groups</Heading>
        <Text>
        Groups play a key role in organizing and managing users within an organization. They allow for a structured way of handling tasks, projects, and permissions.
        </Text>
    </section>

    <section>
      <SectionTitle>Group Creation and Membership</SectionTitle>
      <UnorderedList>
        <ListItem><Text as='b'>Creating Groups:</Text></ListItem>
        <Text>
        Organization owners have the ability to create as many groups as they need within their organization. This helps organize team members around specific tasks, projects, or departments.
        </Text>

        <ListItem><Text as='b'>Inviting Members to Groups:</Text></ListItem>
        <Text>
        Owners can invite users to join a group. However, group membership is optional, and users must accept the invitation. When an owner attempts to include a user in a group, an email is sent to the user with the option to accept or reject the invitation.
        </Text>
        <UnorderedList>
          <ListItem>If the user accepts the invitation, they are added to the group and automatically gain the same permissions that apply to all group members.</ListItem>
          <ListItem>If the user rejects the invitation, they will not be added to the group, and the owner may choose to invite them again later.</ListItem>
        </UnorderedList>

        <ListItem><Text as='b'>Excluding Members from Groups:</Text></ListItem>
        <Text>
        Owners also have the authority to remove members from any group. When a member is excluded, they lose all permissions and access granted through that group.
        </Text>

      </UnorderedList>
    </section>

    <section>
      <SectionTitle>Group Commands</SectionTitle>
        <Text>Owners of an organization have the ability to manage groups using the following commands. These commands allow owners to create, remove, and manage the members of groups within the organization.</Text>
    </section>

    <section>
      <SectionTitle as='h4'>Command List</SectionTitle>
      <UnorderedList>
          <ListItem><Text as='b'>Create a New Group:</Text></ListItem>
          <Text>Organization owners can create new groups using the following command:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            CREATE GROUP group_name
          </CodeExample>
          <Text>Example:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            CREATE GROUP Marketing
          </CodeExample>

          <ListItem><Text as='b'>Remove a Group:</Text></ListItem>
          <Text>To delete an existing group, owners can use this command:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            REMOVE GROUP group_name
          </CodeExample>
          <Text>Example:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            REMOVE GROUP Marketing
          </CodeExample>
          <Stack spacing={2} marginBottom={4}>
            <Alert status='success' borderRadius={"md"} >
              <AlertIcon />
              <Text as='b' fontSize={14}>Note: Only organization owners have the authority to create and remove groups.</Text>
            </Alert>
          </Stack>

          <ListItem><Text as='b'>Show All Groups:</Text></ListItem>
          <Text>To display all the groups in the current organization, use:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            SHOW GROUPS
          </CodeExample>

          <ListItem><Text as='b'>Show Specific Group Information:</Text></ListItem>
          <Text>To view detailed information about a specific group, use:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            SHOW GROUPS group_name
          </CodeExample>
          <Text>Example:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            SHOW GROUPS Marketing
          </CodeExample>

          <ListItem><Text as='b'>Include a User in a Group:</Text></ListItem>
          <Text>To invite a user to a group, owners can use this command. The user can be invited by their nickname or email:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            INCLUDE nickname-or-email TO group_name
          </CodeExample>
          <Text>Example:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            INCLUDE john.doe@example.com TO Marketing
          </CodeExample>

          <ListItem><Text as='b'>Exclude a User from a Group:</Text></ListItem>
          <Text>To remove a user from a group, owners can use this command:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            EXCLUDE nickname-or-email FROM group_name
          </CodeExample>
          <Text>Example:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            EXCLUDE john.doe@example.com FROM Marketing
          </CodeExample>
      </UnorderedList>
    </section>

    <section>
      <SectionTitle>Usage Notes</SectionTitle>
      <UnorderedList>
          <ListItem><Text as='b'>Owner-Only Commands:</Text></ListItem>
          <Text>The commands to create and remove groups can only be executed by organization owners, ensuring that group management remains in the hands of those with the highest level of access.</Text>
          <ListItem><Text as='b'>Group Membership Management:</Text></ListItem>
          <Text>Inviting a user to a group via the INCLUDE command will send them an email asking them to accept or reject the invitation. If they accept, they are added to the group and inherit the group's permissions.</Text>
      </UnorderedList>
    </section>

    
    </WikiArticleMain>

    

    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Introduction</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Organization Commands</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Grammar</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
    
  </WikiArticle>
)

export default Groups
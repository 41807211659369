import React, { createRef, useState } from "react";
import TopButtons from "../component/TopButtons";
import TestCase from "../component/TestCase";
import Button from "../component/Button";
import { apiBaseURL } from "../config";
import styled from "styled-components";
import { motion } from "framer-motion";

import consoleLight from "../resources/terminal_dark.png";
import consoleDark from "../resources/terminal_light.png";

const TableHeader = styled.div`
  margin: 5px 0;
`;
const ApiAddress = styled.div`
  display: inline-block;
  margin-left: 18px;
  color: gray;
`;

const ImgContainer = styled.img`
  display: inline-block;
  margin-left: 18px;
  margin-right: 1vh;
  height: 2.5em;
  width: 3em;
  cursor: pointer;
`;

const Test = () => {
  const [visible, setVisible] = useState(false);

  const testCases = [
    {
      subject: "create form",
      code: [
        "create form Countries (\n\tname text not null unique,\n\tcapital text not null,\n\textension number\n)",
        "create form Addresses (\n\tstreet_number number,\n\tzip_code number,\n\tcountry references Countries.name\n)",
        "create form Jobs (name text not null unique)",
        "create form Persons (\n\tname text not null unique,\n\tage number,\n\taddress references Addresses.(street_number, zip_code, country),\n\tjobs references 1..3 Jobs.name unique\n)",
        "create form Companies (\n\tname text not null unique,\n\tmanager references 1 Persons.name totally unique,\n\temployees references 1..many Persons.name unique\n)",
      ],
    },
    {
      subject: "show form(s)",
      initialize: [
        "create form Contacts (\n\tname text,\n\tmobile_number number,\n\temail text,\n\tbirthdate date\n)",
        "create form Emails (\n\tfrom references 1 Contacts.email,\n\tto references 1..many Contacts.email,\n\ttopic text, body text\n)",
      ],
      code: ["show forms Contacts", "show forms Emails Contacts"],
    },
    {
      subject: "remove form(s)",
      initialize: [
        "create form Notes (\n\ttitle text,\n\tbody text,\n\tauthor text,\n\tstarted_date date\n)",
        "create form Voice_Notes (\n\ttitle text,\n\taudio file,\n\tauthor text,\n\tstarted_date date\n)",
        "create form Calendar_Event (\n\tname text not null,\n\tdescription text,\n\tstart_date date,\n\tend_date date\n)",
      ],
      code: ["remove forms Notes", "remove forms Voice_Notes Calendar_Event"],
    },
    {
      subject: "create new entries",
      initialize: [
        "create form Nationalities (name text)",
        "create form Directors (\n\tname text,\n\tnationality references Nationalities.name\n)",
        "create form Movies (\n\tname text,\n\tlength number,\n\tdirector references 1..3 Directors.name\n)",
      ],
      code: [
        'create new Nationalities values ("Cuban")',
        'create new Nationalities values ("Japanese")',
        'create new Nationalities values ("Uruguayan")',
        'create Directors ("Leonardo Padura", "Cuban")',
        'create Directors ("Enrique Colina", "Cuban")',
        'create Directors ("Hayao Miyazaki", "Japanese")',
        'create Directors ("Isao Takahata", "Japanese")',
        'create Directors ("Federico Veiroj", "Uruguayan")',
        "create Movies values ('Acne', 87, 'Federico Veiroj')",
        "create Movies values ('Castle in the Sky', 124, ('Hayao Miyazaki','Isao Takahata'))",
        "create Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Enrique Colina'))",
      ],
    },
    {
      subject: "get case",
      initialize: [
        "create form Pet_Owners (\n\tname text,\n\tage number\n)",
        "create form Pets (\n\tname text,\n\tanimal text,\n\tbreed text,\n\towner references 1 Pet_Owners.(name, age)\n)",
        "create new Pet_Owners ('Louis', 15)",
        "create new Pet_Owners ('Andrew', 24)",
        "create new Pet_Owners ('Ana', 63)",
        "create Pets('Loki','dog', 'Pekingese', 'Andrew')",
        "create Pets('Tiger','cat', 'Persian', 'Ana')",
        "create Pets('Nina','dog', 'Labrador', 'Louis')",
        "create Pets('Fluff','cat', 'Siamese', 'Ana')",
      ],
      code: [
        "get Pets (breed)",
        "get Pets (name, breed)\n\twith owner.name = 'Ana'",
        "get Pets (name, breed, owner.name, owner.age)\n\twith animal = 'dog'\n\tand owner.age > 20",
      ],
    },
    {
      subject: "modify case",
      initialize: [
        "create form Companies (name text)",
        "create form Products (\n\tname text,\n\tdesigner references Companies.name,\n\tprice number\n)",
        "create new Companies ('Apple')",
        "create new Companies ('Google')",

        "create new Products ('MacBook Pro M1', 'Apple', 1299)",
        "create new Products ('iPhone 12 Pro', 'Google', 499)",
        "create new Products ('AirPods Pro 1st Gen', 'Apple', 399)",

        "get Products with name = 'AirPods Pro 1st Gen'",
      ],
      code: [
        "modify Products ('AirPods Pro 1st Gen','Apple', 299)\n\twith name='AirPods Pro 1st Gen'\n\tand price=399\n\tand fql_version=0",
      ],
    },
    {
      subject: "remove case",
      initialize: [
        "create form Genres (name text)",
        "create form Songs (\n\tname text,\n\tauthor text,\n\tgenre text,\n\tyear number\n)",
        "create new Genres('rock')",
        "create new Genres('pop')",
        "create new Genres('reggaeton')",
        "create new Genres('classical')",
        "create new Songs ('Careless Whisper', 'George Michael', 'pop-soul', 1984)",
        "create new Songs ('Imagine', 'John Lenon', 'pop-rock', 1971)",
        "create new Songs ('Gasolina', 'Daddy Yankee', 'reggaeton', 2004)",
        "create new Songs ('Despacito', 'Luis Fonsi', 'pop-latino', 2017)",
      ],
      code: [
        "remove Genres",
        "remove Songs\n\twith year > 2000",
        "remove Songs\n\twith year > 1970\n\tand year < 1980\n\tand genre='pop-rock'",
      ],
    },
    {
      subject: "validations",
      initialize: [
        "create form Items (\n\tname text,\n\tmodel text,\n\tprice number\n)",
        "create form Store (\n\taddress text,\n\titems references 1..many Items.(name, model, price)\n)",
      ],
      code: [
        'define rule NO_LAPTOPS: on Items when\n\tname = "Laptop" is invalid',
        [
          'create new Items values ("Laptop", "M132AXX", 450)',
          (res) =>
            res.error && res.error !== "" && res.error.match(/NO_LAPTOPS/),
        ],
        "define rule NO_EXPENSIVE_ITEMS: on Store when\n\titems.price > 500 is invalid",
        "create new Items ('SmartWatch', 'SM34A', 300)",
        "create new Items ('iPhone 12', 'M3456/A', 650)",
        [
          "create new Store('2701 NW Ave.', ('SmartWatch', 'iPhone 12'))",
          (res) =>
            res.error &&
            res.error !== "" &&
            res.error.match(/NO_EXPENSIVE_ITEMS/),
        ],
      ],
    },
    {
      subject: "define function",
      initialize: ["create form Users (name text unique not null, age number)"],
      code: [
        'define function raise_age_error(age) { (error "Too young to get a job: ~A" age) }',
        "define rule NO_TEENS_WITH_JOBS: on Users when age < 18 then CALL raise_age_error(age)",
        'create new Users ("MEIKO", 19)',
        [
          'create new Users ("Miku Hatsune", 16)',
          (res) =>
            res.error &&
            res.error !== "" &&
            res.error.match(/NO_TEENS_WITH_JOBS/),
        ],
      ],
    },
    {
      subject: "is used by",
      initialize: [
        "create form HOTEL_ROOMS(room_number number not null unique, floor number not null, single_beds number not null, double_beds number not null, capacity number)",
        "create form GUESTS(guest_number number not null unique, name text not null, phone text not null)",
        "create form RESERVATIONS(guest references GUESTS.name, capacity number not null, single_beds number, double_beds number, from_date date, until_date date, room_assigned references HOTEL_ROOMS.room_number)",
        "create HOTEL_ROOMS(1000, 1, 3, 0, 3)",
        "create HOTEL_ROOMS(1001, 1, 1, 1, 2)",
        "create HOTEL_ROOMS(1002, 2, 3, 1, 4)",
        "create HOTEL_ROOMS(1003, 2, 4, 0, 4)",
        "create HOTEL_ROOMS(1004, 3, 1, 5, 6)",

        'create GUESTS(10, "Leandro", "+LLLL126589")',
        'create GUESTS(11, "Felix", "+FFFF346589")',
        'create GUESTS(12, "Eitaro", "+EEEE14354589")',
        'create GUESTS(13, "Fernando", "+YYYY9136546589")',
        'create GUESTS(14, "Alvaro", "+AAAA129430389")',
        'create GUESTS(15, "Carlos", "+CCCC126923289")',
      ],
      code: [
        "HOTEL_ROOMS is used by GUESTS through RESERVATIONS.(room_assigned, from_date, until_date) in groups.(capacity) of 1..HOTEL_ROOMS.capacity",
        'create RESERVATIONS("Felix", 2, 3, 0, "07-10-2024", "07-17-2024", 1000)',

        'create RESERVATIONS("Leandro", 4, 0, 4, "06-08-2024", "06-15-2024", 1003)',
        'create RESERVATIONS("Fernando", 1, 3, 0, "06-28-2024", "07-05-2024", 1000)',

        [
          'create RESERVATIONS("Eitaro", 2, 3, 0, "07-06-2024", "07-13-2024", 1000)',
          (res) => res.error && res.error !== "",
        ],
        [
          'create RESERVATIONS("Alvaro", 2, 3, 0, "07-11-2024", "07-13-2024", 1000)',
          (res) => res.error && res.error !== "",
        ],
        [
          'create RESERVATIONS("Carlos", 11, 3, 0, "07-20-2024", "07-24-2024", 1000)',
          (res) => res.error && res.error !== "",
        ],
      ],
    },
  ];

  for (const testCase of testCases) {
    testCase.ref = createRef();
  }

  const runAllTest = () => {
    testCases.forEach((testCase) => testCase.ref.current.runTest());
  };

  const toggleAllVisibilities = () => {
    testCases.forEach((testCase) =>
      testCase.ref.current.toggleVisibility(!visible)
    );
    setVisible(!visible);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <TopButtons />
      <div style={{ margin: "70px 10px 20px" }}>
        <TableHeader>
          <Button onClick={runAllTest}>Run All</Button>
          <ApiAddress>API: {apiBaseURL}</ApiAddress>
          <ImgContainer
            src={visible ? consoleDark : consoleLight}
            alt="show-command-windows"
            onClick={toggleAllVisibilities}
          />
          {visible ? "Hide All" : "Show All"}
        </TableHeader>
        <div style={{ display: "table" }}>
          {testCases.map((args, i) => (
            <TestCase key={`testcase-${i}`} {...args} />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Test;

import React from "react";
import { Link } from "react-router-dom";
import { Button as ChakraButton } from '@chakra-ui/react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const BaseButton = styled(ChakraButton)`
  font-weight: semibold;
`;

export const Button = ({ icon, href, children, ...props }) => {
  const button = (
    <BaseButton fontFamily="heading" {...props}>{icon && <FA icon={icon} />}
      {children}
    </BaseButton>
  );
  return (
    href ? <Link to={href}>{button}</Link> : button
  );
};

export default Button;

import { useContext, useState, useEffect } from "react";
import {
  Flex,
  VStack,
  HStack,
  Input,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  InputGroup,
  InputRightElement,
  StackDivider,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  faEye,
  faEyeSlash,
  faAt,
  faUser,
  faGear,
  faArrowRightFromBracket,
  faInfo,
  faQuestion,
  faUserAlt,
  faICursor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import Button from "../new_component/atoms/Button";
import styled from "styled-components";
import DefaultLayout from "../layouts/default";
import { GlobalContext } from "../component/GlobalContext";
import { useParams } from "react-router-dom";
import { apiBaseURL, hostBaseURL } from "../config";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import emailjs from "emailjs-com";

const MainContent = ({ children }) => (
  <Flex justify="center" mt={"20%"}>
    {children}
  </Flex>
);

const Header = styled.h3`
  font-size: 28px;
  font-weight: 700;
`;

const UserInfoItem = ({ children, fn }) => {
  return (
    <Flex
      justify={"left"}
      align={"center"}
      gap={4}
      cursor={"pointer"}
      width={"100%"}
      _hover={{
        backgroundColor: "brand.100",
        padding: "2%",
        borderRadius: "10px",
        transition: "all 0.2s ease",
      }}
      onClick={fn}
    >
      {children}
    </Flex>
  );
};

const LoggedIn = ({ userInfo, doLogout }) => {
  return (
    <VStack>
      <Flex justify={"center"} align={"center"} gap={4} direction={"column"}>
        <Flex
          border={"4px solid black"}
          borderRadius={"full"}
          width={"75px"}
          height={"75px"}
          align={"center"}
          justify={"center"}
        >
          <FA icon={faUser} fontSize={"2em"} />
        </Flex>
        Welcome back, {userInfo.email}
      </Flex>
      <VStack
        divider={<StackDivider />}
        align={"flex-start"}
        mt={10}
        width={"100%"}
      >
        <UserInfoItem>
          <FA icon={faUserAlt} />
          Your profile
        </UserInfoItem>
        <UserInfoItem>
          <FA icon={faGear} />
          Settings
        </UserInfoItem>
        <UserInfoItem>
          <FA icon={faQuestion} />
          F.A.Q.
        </UserInfoItem>
        <UserInfoItem>
          <FA icon={faInfo} />
          About us
        </UserInfoItem>
        <UserInfoItem fn={doLogout}>
          <FA icon={faArrowRightFromBracket} />
          Logout
        </UserInfoItem>
      </VStack>
    </VStack>
  );
};

const SignupForm = ({ setLogin }) => {
  const [email, setEmail] = useState("");
  const [signing, setSigning] = useState(false);
  const [signed, setSigned] = useState(false);

  const handleSetLogin = () => {
    setLogin(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (email === "") {
      alert("Fill out the blank spaces");
      return;
    }
    e.target.disabled = true;
    setSigning(true);
    await fetch(`${apiBaseURL}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Signed up sucessfully") {
          const activation_token = data.activation_token;
          emailjs
            .send(
              "default_service",
              "template_r88w93l",
              {
                to_name: "",
                to_email: email,
                token: activation_token,
                host: hostBaseURL,
              },
              "HOOQ1w8YQTFxU9xny"
            )
            .then((response) => {
              setSigning(false);
              setSigned(true);
              console.log(
                "MAILED SUCCESSFULLY!",
                response.status,
                response.text
              );
            })
            .catch((err) => {
              setSigning(false);
              e.target.disabled = false;
              alert(
                "Failed to send email! Check your connection and try again\n",
                err
              );
            });
        } else {
          alert(data.msg);
          setSigning(false);
          e.target.disabled = false;
        }
      })
      .catch((err) => {
        alert("Failed to connect to backend engine\n", err);
        setSigning(false);
        e.target.disabled = false;
      });
  };

  return (
    <VStack
      justify="space-around"
      w="80%"
      minH="350px"
      m="0 auto"
      p="30px 50px"
      bgColor="brand.100"
      borderRadius="24px"
    >
      <Header>{signed ? "You've signed up" : "Sign up!"}</Header>
      {signed ? (
        <>
          <Text as="h1">Thanks for registering!</Text>
          <Text as="h3" align={"center"}>
            Verify your email inbox to activate your account!
          </Text>
          <Button variant="link" onClick={handleSetLogin}>
            Already have an account?
          </Button>
        </>
      ) : (
        <>
          <InputGroup>
            <Input
              placeholder="E-mail address"
              bgColor="bg"
              onChange={handleEmailChange}
            />
            <InputRightElement>
              <FA icon={faAt} />
            </InputRightElement>
          </InputGroup>
          <HStack gap="20px">
            <Button onClick={handleSignUp}>
              {signing ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand.500"
                  size="md"
                />
              ) : (
                "Continue"
              )}
            </Button>
            <Button variant="link" onClick={handleSetLogin}>
              Already have an account?
            </Button>
          </HStack>
        </>
      )}
    </VStack>
  );
};

const LoginForm = ({ setSignUp }) => {
  const { setUserInfo, setLoggedIn } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [logging, setLogging] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSetSignUp = () => {
    setSignUp(false);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSetPassword = (e) => {
    setPasswordLogin(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === "" || passwordLogin === "") {
      alert("Fill out the blank spaces");
      return;
    }
    setLogging(true);
    e.target.disabled = true;
    await fetch(`${apiBaseURL}/get_hash`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Hash successfully obtained") {
          const hash = data.hash;
          bcrypt.compare(passwordLogin, hash, async (err, result) => {
            if (result) {
              await fetch(`${apiBaseURL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  email: email,
                  password: hash,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.msg === "Log in successfully") {
                    setUserInfo({
                      email: email.toString(),
                      token: data.token,
                      loggedIn: true,
                    });
                    setLoggedIn(true);
                    Cookies.set(
                      "userInfo",
                      JSON.stringify({
                        email: email.toString(),
                        token: data.token,
                        loggedIn: true,
                      }),
                      { expires: 1 / 24, secure: true, sameSite: "strict" }
                    );
                  } else {
                    alert(
                      "Logging error.\nVerify your username and password and try again!"
                    );
                    setLogging(false);
                    e.target.disabled = false;
                  }
                })
                .catch((err) => {
                  setLogging(false);
                  e.target.disabled = false;

                  alert(err.message);
                });
            } else {
              setLogging(false);
              e.target.disabled = false;

              alert("Wrong username or password!");
            }
          });
        } else {
          setLogging(false);
          e.target.disabled = false;

          alert("User is not registered or account has not been activated");
        }
      })
      .catch((err) => {
        setLogging(false);
        e.target.disabled = false;

        alert(err);
      });
  };

  return (
    <VStack
      justify="space-around"
      w="80%"
      minH="350px"
      m="0 auto"
      p="30px 50px"
      bgColor="brand.100"
      borderRadius="24px"
    >
      <Header>Login!</Header>
      <VStack w="100%" gap={5}>
        <InputGroup>
          <Input
            placeholder="E-mail address"
            bgColor="bg"
            onChange={handleChangeEmail}
          />
          <InputRightElement>
            <FA icon={faAt} />
          </InputRightElement>
        </InputGroup>
        <InputGroup>
          <Input
            type={`${showPassword ? "text" : "password"}`}
            placeholder="Password"
            bgColor="bg"
            onChange={handleSetPassword}
          />
          <InputRightElement>
            <FA
              icon={showPassword ? faEyeSlash : faEye}
              onClick={toggleShowPassword}
            />
          </InputRightElement>
        </InputGroup>
      </VStack>
      <HStack gap="20px">
        <Button onClick={handleLogin}>
          {logging ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="md"
            />
          ) : (
            "Continue"
          )}
        </Button>
        <Button variant="link" onClick={handleSetSignUp}>
          Don't have an account?
        </Button>
      </HStack>
    </VStack>
  );
};

export const ActivateEmail = () => {
  const { email, activation_token } = useParams();
  const [activating, setActivating] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [activated, setActivated] = useState(false);
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    fetch(`${apiBaseURL}/activated`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email.toString() }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "The account is activated") {
          setActivated(true);
          handleRedirect();
        } else if (data.msg !== "The account isn't activated") {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email]);

  const handleRedirect = () => {
    setTimeout(() => {
      handleGoHome();
    }, 5000);
  };
  const handleGoHome = () => {
    window.location.href = "/";
  };

  const togglePasswordLogin = () => {
    setShowPassword(!showPassword);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const hashPassword = () => {
    return new Promise((resolve, reject) => {
      bcrypt.hash(password.toString(), 12, (err, hash) => {
        if (err) {
          reject(err);
        } else {
          resolve(hash);
        }
      });
    });
  };

  const handleActivateAccount = async (e) => {
    e.preventDefault();
    if (password === "" || confirmPassword === "") {
      alert("Fill out the blank spaces");
      return;
    }

    setActivating(true);
    e.target.disabled = true;
    if (password === confirmPassword) {
      const hashedPassword = await hashPassword();
      fetch(`${apiBaseURL}/activate_account`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email.toString(),
          activation_token: activation_token.toString(),
          nickname: nickname,
          password: hashedPassword.toString(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.msg === "Account activated successfully") {
            setActivating(false);
            setActivated(true);
            handleRedirect();
          } else {
            alert(data.msg);
          }
        })
        .catch((err) => {
          alert("Failed to connect to backend engine\n", err);
        });
      setActivated(true);
    } else {
      alert("The passwords don't match. Try again!");
    }
  };

  const handleSetNickname = (e) => {
    setNickname(e.target.value);
  };

  return (
    <DefaultLayout>
      <Flex justify="center" mt={"5%"}>
        <VStack
          justify="space-around"
          minH="350px"
          m="0 auto"
          p="30px 50px"
          bgColor="brand.100"
          borderRadius="24px"
        >
          <Header>
            {activated ? "Account activated!" : "Activate Account!"}
          </Header>
          {activated ? (
            <>
              <Text as="h1">This account has been activated</Text>
              <Text as="h3" align={"center"}>
                You can now log in to your account
              </Text>
              <Button variant="link" onClick={handleGoHome}>
                Go Home!
              </Button>
              <Text as="h3" align={"center"}>
                You will be redirected to home page in 5 seconds...
              </Text>
            </>
          ) : (
            <>
              <VStack gap={5}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Nickname"
                    bgColor="bg"
                    onChange={handleSetNickname}
                    value={nickname}
                  />
                  <InputRightElement>
                    <FA icon={faICursor} />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                  <Input
                    type={`${showPassword ? "text" : "password"}`}
                    placeholder="Password"
                    bgColor="bg"
                    onChange={handlePassword}
                  />
                  <InputRightElement>
                    <FA
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={togglePasswordLogin}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                  <Input
                    type={`${showPassword ? "text" : "password"}`}
                    placeholder="Confirm password"
                    bgColor="bg"
                    onChange={handleConfirmPassword}
                  />
                  <InputRightElement>
                    <FA
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={togglePasswordLogin}
                    />
                  </InputRightElement>
                </InputGroup>
              </VStack>
              <HStack gap="20px">
                <Button onClick={handleActivateAccount}>
                  {activating ? (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="brand.500"
                      size="md"
                    />
                  ) : (
                    "Continue"
                  )}
                </Button>
                <Button variant="link" href="/">
                  Already activated?
                </Button>
              </HStack>
            </>
          )}
        </VStack>
      </Flex>
    </DefaultLayout>
  );
};

export const Login = ({ showLogin, setShowLogin }) => {
  const { loggedIn, userInfo, doLogout } = useContext(GlobalContext);
  const [login, setLogin] = useState(true);

  return (
    <>
      <Drawer
        isOpen={showLogin}
        onClose={() => {
          setShowLogin(false);
          setLogin(true);
        }}
        placement="right"
        size={loggedIn ? "sm" : "md"}
      >
        <DrawerOverlay />
        <DrawerContent borderRadius={"15px 0px 0px 15px"} bgColor="bg">
          <DrawerHeader>
            <DrawerCloseButton top={5} left={6} />
          </DrawerHeader>
          <DrawerBody>
            <MainContent>
              {loggedIn ? (
                <LoggedIn userInfo={userInfo} doLogout={doLogout} />
              ) : login ? (
                <LoginForm setSignUp={setLogin} />
              ) : (
                <SignupForm setLogin={setLogin} />
              )}
            </MainContent>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

import { useState, useEffect, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { GlobalContext } from "./GlobalContext";
import { apiBaseURL, hostBaseURL } from "../config";
import emailjs from "emailjs-com";
import bcrypt from "bcryptjs";

import showPasswordIcon from "../resources/showPass.png";
import hidePasswordIcon from "../resources/hidePass.png";

import "./LoginSignUp.css";
import Cookies from "js-cookie";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [signing, setSigning] = useState(false);
  const [signed, setSigned] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (signing) {
      const interval = setInterval(() => {
        setCount((count) => (count < 3 ? count + 1 : 0));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [signing]);

  const dots = ".".repeat(count);
  const signingText = signing ? `Signing${dots}` : "Sign up";

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    e.target.disabled = true;
    setSigning(true);

    await fetch(`${apiBaseURL}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Signed up sucessfully") {
          const activation_token = data.activation_token;
          emailjs
            .send(
              "default_service",
              "template_r88w93l",
              {
                to_name: "",
                to_email: email,
                token: activation_token,
                host: hostBaseURL,
              },
              "HOOQ1w8YQTFxU9xny"
            )
            .then((response) => {
              setSigning(false);
              setSigned(true);
              console.log(
                "MAILED SUCCESSFULLY!",
                response.status,
                response.text
              );
            })
            .catch((err) => {
              setSigning(false);
              e.target.disabled = false;
              alert(
                "Failed to send email! Check your connection and try again\n",
                err
              );
            });
        } else {
          alert(data.msg);
          setSigning(false);
          e.target.disabled = false;
        }
      })
      .catch((err) => {
        alert("Failed to connect to backend engine\n", err);
        setSigning(false);
        e.target.disabled = false;
      });
  };

  return (
    <>
      {signed ? (
        <div className="signed">
          <h2 className="signed-title">You've signed up!</h2>
          <h2 className="signed-subt"> Thanks for registering! </h2>
          <h2 className="signed-text">
            Verify your email inbox to activate your account!
          </h2>
        </div>
      ) : (
        <div>
          <h2 className="title">Sign up!</h2>
          <form>
            <label className="auth-label" htmlFor="email">
              Email:
            </label>
            <input
              className="auth-input"
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <button className="tab" onClick={handleRegister}>
              {signingText}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

const Login = () => {
  const { userInfo, setUserInfo, setLoggedIn } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [count, setCount] = useState(0);
  const [logging, setLogging] = useState(false);
  const [passwordLogin, setPasswordLogin] = useState("");
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);

  useEffect(() => {
    if (logging) {
      const interval = setInterval(() => {
        setCount((count) => (count < 3 ? count + 1 : 0));
      }, 500);
      return () => clearInterval(interval);
    }
    if (userInfo && userInfo.email !== undefined) {
      setLoggedIn(true);
    }
  }, [logging]);

  const dots = ".".repeat(count);
  const loggingText = logging ? `Logging in${dots}` : "Log in";

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const togglePasswordLogin = (e) => {
    setShowPasswordLogin(!showPasswordLogin);
  };

  const handlePasswordLogin = (event) => {
    setPasswordLogin(event.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === "" || passwordLogin === "") {
      alert("Fill out the blank spaces");
      return;
    }
    setLogging(true);
    e.target.disabled = true;
    await fetch(`${apiBaseURL}/get_hash`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Hash successfully obtained") {
          const hash = data.hash;
          bcrypt.compare(passwordLogin, hash, async (err, result) => {
            if (result) {
              await fetch(`${apiBaseURL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  email: email,
                  password: hash,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.msg === "Log in successfully") {
                    setUserInfo({
                      email: email.toString(),
                      token: data.token,
                      loggedIn: true,
                    });
                    setLoggedIn(true);
                    Cookies.set(
                      "userInfo",
                      JSON.stringify({
                        email: email.toString(),
                        token: data.token,
                        loggedIn: true,
                      }),
                      { expires: 1 / 24, secure: true, sameSite: "strict" }
                    );
                  } else {
                    alert(
                      "Logging error.\nVerify your username and password and try again!"
                    );
                    setLogging(false);
                    e.target.disabled = false;
                  }
                })
                .catch((err) => {
                  setLogging(false);
                  e.target.disabled = false;

                  alert(err.message);
                });
            } else {
              setLogging(false);
              e.target.disabled = false;

              alert("Wrong username or password!");
            }
          });
        } else {
          setLogging(false);
          e.target.disabled = false;

          alert("User is not registered or account has not been activated");
        }
      })
      .catch((err) => {
        setLogging(false);
        e.target.disabled = false;

        alert(err);
      });
  };

  return (
    <>
      <h2 className="title">Log in!</h2>
      <form>
        <label className="auth-label" htmlFor="email">
          Email:
        </label>
        <input
          className="auth-input"
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
        />
        <label className="auth-label" htmlFor="password">
          Password:
        </label>
        <div className="password-input">
          <input
            className="auth-input"
            type={showPasswordLogin ? "text" : "password"}
            id="password"
            value={passwordLogin}
            onChange={handlePasswordLogin}
          />
          <img
            className={`toggle-password-visibility ${
              showPasswordLogin ? "visible" : ""
            }`}
            src={showPasswordLogin ? hidePasswordIcon : showPasswordIcon}
            alt={showPasswordLogin ? "Hide password" : "Show password"}
            onClick={togglePasswordLogin}
          />
        </div>
        <button className="tab" onClick={handleLogin}>
          {loggingText}
        </button>
      </form>
    </>
  );
};

const LoggedIn = () => {
  const { userInfo, setUserInfo, setLoggedIn } = useContext(GlobalContext);

  const handleEditAccount = (e) => {
    e.preventDefault();
    console.log("Editing profile");
  };

  const handleLogOut = async () => {
    await fetch(`${apiBaseURL}/new_guest_token`, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setUserInfo({ token: data.token });
      })
      .catch((err) => console.log(err));
    setLoggedIn(false);
    Cookies.remove("userInfo");
  };

  return (
    <>
      <div className="logged-form">
        <h2 className="title"> Welcome back!</h2>
        <h2 className="signed-subt"> {userInfo.email} </h2>
        <h2 className="signed-subt"> Have a good time! </h2>
        <h2 className="signed-text">To edit your account go:</h2>
        <button className="tab" onClick={handleEditAccount}>
          Edit account
        </button>
        <h2 className="signed-text">or</h2>
        <button className="tab" onClick={handleLogOut}>
          Log out!
        </button>
      </div>
    </>
  );
};

const SignupLogin = ({ onClose, visible }) => {
  const { loggedIn, setLoggedIn } = useContext(GlobalContext);
  const [login, setLogin] = useState(true);

  const handleShowLogin = () => {
    setLogin(true);
  };

  const handleShowRegister = () => {
    setLogin(false);
  };

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.02 } }}
          exit={{ opacity: 0 }}
        >
          {visible && (
            <>
              <div className="terminal-settings-overlay" onClick={onClose} />
              <div className="auth-container">
                <button className="exit" onClick={onClose}>
                  x
                </button>
                {loggedIn ? (
                  <motion.div
                    initial={{ y: -25 }}
                    animate={{ y: 0 }}
                    exit={{ y: -25 }}
                  >
                    <LoggedIn setLoggedIn={setLoggedIn} />
                  </motion.div>
                ) : (
                  <div className="auth-form">
                    <div className="tabs">
                      <button className="tab top" onClick={handleShowLogin}>
                        Log in
                      </button>
                      <button className="tab top" onClick={handleShowRegister}>
                        Sign up
                      </button>
                    </div>
                    {login && (
                      <motion.div
                        initial={{ y: -50 }}
                        animate={{ y: 0 }}
                        exit={{ y: -50 }}
                      >
                        <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
                      </motion.div>
                    )}
                    {!login && (
                      <motion.div
                        initial={{ y: -50 }}
                        animate={{ y: 0 }}
                        exit={{ y: -50 }}
                      >
                        <SignUp loggedIn={loggedIn} />
                      </motion.div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default SignupLogin;

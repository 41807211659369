import { useContext, useRef, useState } from "react";
import { Resizable } from "re-resizable";
import { GlobalContext } from "../GlobalContext";
import { Link } from "react-router-dom";
import download from "downloadjs";
import {
  Input,
  FormLabel,
  Button,
  HStack,
  Spacer,
} from '@chakra-ui/react';

export const SingleReferenceField = ({ data, index }) => {
  const { runCode, userInfo } = useContext(GlobalContext);
  const refInputs = useRef({ labels: [], types: [] });
  const effectRan = useRef(false);
  const [dataRef, setDataRef] = useState([]);

  const setRefInputs = (elem, parent) => {
    if (elem.TYPE === "REFERENCE") {
      for (let i = 0; i < elem["REF-PATH"].length; i++) {
        const item = elem["REF-PATH"][i];
        setRefInputs(item, `${parent}${elem.LABEL}.`);
      }
    } else {
      refInputs.current.labels.push(`${parent}${elem.LABEL}`);
      refInputs.current.types.push(elem.TYPE);
    }
  };

  if (!effectRan.current) {
    for (let i = 0; i < data["REF-PATH"].length; i++) {
      const elem = data["REF-PATH"][i];
      setRefInputs(elem, "");
    }

    const code = `get ${data["REF-FORM"]} (${refInputs.current.labels.join(
      ", "
    )})`;
    runCode({
      code: code,
      token: userInfo.token,
    })
      .then((dat) => {
        setDataRef(dat.output);
      })
      .catch((err) => console.log(err));

    effectRan.current = true;
  }

  return (
    <div>
      <small className="ref-title">{data["LABEL"]}</small>
      <div className="ref-group">
        {refInputs.current.labels.map((item, i) => {
          return (
            <div
              key={`div-ps-cn-${index}-${i}`}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <small key={`small-ps-cn-${index}-${i}`}>{item}</small>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                reftype={"ref-single"}
              >
                <select
                  title={`${data["LABEL"]}.${item}`}
                  className={`input-ps-cn ${data.LABEL}.${item}`}
                  key={`in-ps-cn-${index}-${i}`}
                  id={`input-${index}`}
                  defaultValue={""}
                  style={{ width: "10vw" }}
                  datatype={refInputs.current.types[i].toLowerCase()}
                  // onChange={handleChangeReference}
                >
                  <option value={""} disabled>
                    {"---"}
                  </option>
                  {dataRef.map((elem, i) => {
                    return (
                      <option
                        key={`op-ps-cn-${index}-${i}`}
                        value={elem[item.toLowerCase()]}
                      >
                        {elem[item.toLowerCase()]}
                      </option>
                    );
                  })}
                </select>
                <Link
                  to={`/presentation_server/create_new/${data[
                    "REF-FORM"
                  ].toLowerCase()}`}
                  className="add-row"
                >
                  <span className="icon-plus"></span>
                </Link>
              </div>
              <p
                key={`p-ps-cn-${index}-${i}`}
                id="required-text"
                style={{ color: "red" }}
              ></p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const MultipleReferenceField = ({ data, index, minCard, maxCard }) => {
  const { runCode, userInfo } = useContext(GlobalContext);
  const effectRan = useRef(false);
  const refInputs = useRef({ labels: [], types: [] });
  const max = !isNaN(maxCard) ? maxCard : Number.MAX_VALUE;
  const [rowsCount, setRowsCount] = useState(max < 3 ? max : 3);
  const [refData, setRefData] = useState([]);

  const setRefInputs = (elem, parent) => {
    if (elem.TYPE === "REFERENCE") {
      for (let i = 0; i < elem["REF-PATH"].length; i++) {
        const item = elem["REF-PATH"][i];
        setRefInputs(item, `${parent}${elem.LABEL}.`);
      }
    } else {
      refInputs.current.labels.push(`${parent}${elem.LABEL}`);
      refInputs.current.types.push(elem.TYPE);
    }
  };

  if (!effectRan.current) {
    for (let i = 0; i < data["REF-PATH"].length; i++) {
      const elem = data["REF-PATH"][i];
      setRefInputs(elem, "");
    }

    runCode({
      code: `get ${data["REF-FORM"]} (${refInputs.current.labels.join(", ")})`,
      token: userInfo.token,
    })
      .then((data) => {
        setRefData(data.output);
      })
      .catch((err) => console.log(err));

    effectRan.current = true;
  }

  const handleChangeReference = (e) => {
    if (e.target.value && e.target.value !== "") {
      let index1 = -1;

      for (let i = 0; i < refData.length; i++) {
        const item = Object.values(refData[i]);

        if (item.includes(e.target.value)) {
          index1 = i;
          break;
        } else if (item.includes(parseInt(e.target.value))) {
          index1 = i;
          break;
        }
      }

      const parent = e.target.parentElement;
      const grandParent = parent.parentElement;
      const children = Array(...grandParent.children);
      const index2 = children.indexOf(parent);

      for (let i = 0; i < children.length; i++) {
        if (i !== index2) {
          const select = children[i].children[1];
          select.value =
            refData[index1][refInputs.current.labels[i].toLowerCase()];
        }
      }
    }
  };

  const handleAddRow = () => {
    if (rowsCount < max) {
      setRowsCount(rowsCount + 1);
    }
  };

  const handleRemoveRow = () => {
    if (rowsCount > Math.max(1, minCard)) {
      setRowsCount(rowsCount - 1);
    }
  };

  const handleSetRows = (e) => {
    const data = JSON.parse(e.target.attributes.data.value);
    const length =
      data[e.target.parentElement.children[1].title.toLowerCase()].length;
    const titles = [];
    Array(...e.target.parentElement.parentElement.children).map((item) => {
      titles.push(item.children[1].title);
    });
    setRowsCount(length);

    setTimeout(() => {
      for (let i = 0; i < titles.length; i++) {
        const title = titles[i];

        const selects = document.getElementsByClassName(title);

        for (let j = 0; j < selects.length; j++) {
          const select = selects[j];

          select.value = data[title.toLowerCase()][j];
        }
      }
    }, 400);
  };

  return (
    <div>
      <small className="ref-title">{data["LABEL"]}</small>
      <div className="ref-group">
        <table id={`input-${index}`}>
          <thead>
            <tr>
              {refInputs.current.labels.map((item, i) => {
                return (
                  <th>
                    <small key={`small-ps-cn-${index}-${i}`}>{item}</small>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Array(rowsCount)
              .fill("")
              .map((_, i) => {
                return (
                  <tr>
                    {refInputs.current.labels.map((item, j) => {
                      return (
                        <td reftype={"ref-mult"}>
                          <div
                            id={`input-fill-${index}`}
                            data={{}}
                            onClick={handleSetRows}
                          />
                          <select
                            title={`${data.LABEL}.${item}`}
                            className={`input-ps-cn ${data.LABEL}.${item}`}
                            key={`in-ps-cn-${index}-${i}`}
                            id={`input-${index}-${i}-${j}`}
                            defaultValue={""}
                            style={{ width: "10vw" }}
                            onChange={handleChangeReference}
                            textvalue={refInputs.current.types[j]}
                            datatype={refInputs.current.types[j].toLowerCase()}
                          >
                            <option value={""} disabled>
                              {"---"}
                            </option>
                            {refData.map((elem, i) => {
                              return (
                                <option
                                  key={`op-ps-cn-${index}-${i}`}
                                  value={elem[item.toLowerCase()]}
                                >
                                  {elem[item.toLowerCase()]}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
        <HStack my={5}>
          <Spacer />
          <Button
            variant="solid"
            size="xs"
            onClick={handleAddRow}
            isDisabled={rowsCount === max}
          >
            <span className="icon-plus"></span>
          </Button>
          <Button
            variant="solid"
            size="xs"
            onClick={handleRemoveRow}
            isDisabled={rowsCount === Math.max(1, minCard)}
          >
            <span className="icon-delete"></span>
          </Button>
        </HStack>
      </div>
    </div>
  );
};

export const NumberField = ({ data, index }) => {
  return (
    <>
      <small key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </small>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn"
        key={`in-ps-cn-${index}`}
        type={`${data["TYPE"]}`}
        datatype={`${data["TYPE"].toLowerCase()}`}
        id={`input-${index}`}
        style={{ maxWidth: "10vw" }}
        required={data["NULLABLE"] === "false"}
      />
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

export const TextField = ({ data, index }) => {
  return (
    <>
      <FormLabel key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </FormLabel>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn"
        key={`in-ps-cn-${index}`}
        type={`${data["TYPE"]}`}
        datatype={`${data["TYPE"].toLowerCase()}`}
        id={`input-${index}`}
        style={{ maxWidth: "10vw" }}
        required={data["NULLABLE"] === "false"}
      />
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

export const BooleanField = ({ data, index }) => {
  return (
    <>
      <small key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </small>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn"
        key={`in-ps-cn-${index}`}
        type={`${data["TYPE"]}`}
        datatype={`${data["TYPE"].toLowerCase()}`}
        id={`input-${index}`}
        style={{ maxWidth: "10vw" }}
        required={data["NULLABLE"] === "false"}
      />
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

export const DateField = ({ data, index }) => {
  return (
    <>
      <small key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </small>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn"
        key={`in-ps-cn-${index}`}
        type={`${data["TYPE"]}`}
        datatype={`${data["TYPE"].toLowerCase()}`}
        id={`input-${index}`}
        style={{ maxWidth: "10vw" }}
        required={data["NULLABLE"] === "false"}
      />
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

export const TimeField = ({ data, index }) => {
  return (
    <>
      <small key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </small>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn"
        key={`in-ps-cn-${index}`}
        datatype={`${data["TYPE"].toLowerCase()}`}
        type={`${data["TYPE"]}`}
        id={`input-${index}`}
        style={{ maxWidth: "10vw" }}
        required={data["NULLABLE"] === "false"}
      />
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

export const FileField = ({ data, index }) => {
  const [selectedFile, setSelectedFile] = useState(undefined);

  const handleFill = () => {
    const input = document.getElementById(`input-${index}`);
    setSelectedFile(JSON.parse(input.textvalue));
  };

  const handleClear = (e) => {
    setSelectedFile(undefined);
    const input = document.getElementById(`input-${index}`);
    input.value = "";
    input.textvalue = "";
  };

  const handleDownloadFile = () => {
    const file = selectedFile.file.split(",");
    download(atob(file[1]), selectedFile.name, { type: file[0].split(";")[0] });
  };

  const handleUploadFile = (e) => {
    if (e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      e.target.textvalue = JSON.stringify({
        name: e.target.files[0].name,
        file: reader.result,
      });
      setSelectedFile({ name: e.target.files[0].name, file: reader.result });
    };
  };

  return (
    <>
      <small key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </small>
      <div id={`input-clear-${index}`} onClick={handleClear}></div>
      <div id={`input-fill-${index}`} onClick={handleFill}></div>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn file"
        type="file"
        datatype={`${data["TYPE"].toLowerCase()}`}
        id={`input-${index}`}
        name="file"
        required={data["NULLABLE"] === "false"}
        onChange={handleUploadFile}
        textvalue={""}
      />
      <label htmlFor={`input-${index}`}>
        <span className="file-input-name">
          <span className="file-input-name-text">{`${
            selectedFile ? selectedFile.name : "No file selected"
          }`}</span>
        </span>
        <span className="file-input-button">
          <span className="font-cn-upload-cloud" />
          Upload
        </span>
      </label>
      <div>
        {selectedFile && (
          <div className="file-input-show">
            <span className="font-cn-file" />
            <span className="file-input-show-name">{selectedFile.name}</span>
            <span className="font-cn-download" onClick={handleDownloadFile} />
            <span className="font-cn-clear-file" onClick={handleClear} />
          </div>
        )}
      </div>
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

export const ImageField = ({ data, index }) => {
  const [selectedImage, setSelectedImage] = useState(undefined);

  const handleFill = (e) => {
    const input = document.getElementById(`input-${index}`);
    setSelectedImage(JSON.parse(input.textvalue));
  };

  const handleClear = (e) => {
    setSelectedImage(undefined);
    const input = document.getElementById(`input-${index}`);
    input.value = "";
    input.textvalue = "";
  };

  const handleUploadImage = (e) => {
    if (e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      e.target.textvalue = JSON.stringify({
        name: e.target.files[0].name,
        file: reader.result,
      });
      setSelectedImage({ name: e.target.files[0].name, file: reader.result });
    };
  };

  return (
    <>
      <small key={`small-ps-cn-${index}`}>
        {`${data["LABEL"]}${data["NULLABLE"] === "false" ? "*" : ""} `}
      </small>
      <div id={`input-clear-${index}`} onClick={handleClear}></div>
      <div id={`input-fill-${index}`} onClick={handleFill}></div>
      <Input
        title={data["LABEL"]}
        className="input-ps-cn file"
        key={`in-ps-cn-${index}`}
        type="file"
        datatype={`${data["TYPE"].toLowerCase()}`}
        alt="select image"
        accept="image/*"
        id={`input-${index}`}
        name="image"
        required={data["NULLABLE"] === "false"}
        onChange={handleUploadImage}
        textvalue={""}
      />
      <label htmlFor={`input-${index}`}>
        <span className="file-input-name">
          <span className="file-input-name-text">{`${
            selectedImage ? selectedImage.name : "No image selected"
          }`}</span>
        </span>
        <span className="file-input-button">
          <span className="font-cn-upload-cloud" />
          Upload
        </span>
      </label>
      {selectedImage && (
        <div className="image-field">
          <Resizable defaultSize={{ height: 200, width: 200 }}>
            <img src={selectedImage.file} alt="Uploaded File" />
          </Resizable>
          <span className="font-cn-clear-file" onClick={handleClear} />
        </div>
      )}
      <p id="required-text" style={{ color: "red" }}></p>
    </>
  );
};

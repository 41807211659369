import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const Validations = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Validations</Heading>
        <Text>
          Rules are a distinctive aspect of FQL, where its full potential can be
          better appreciated. Rules can be subdivided in different groups, like
          Validations, Concepts, Actions, etc.
        </Text>
        <Text>
          Validations are a specific kind of Rule, allowing us to check if a condition
          is fulfilled, and define invalid cases when it is true.
        </Text>
      </section>
      <section>
        <SectionTitle as="h2">Only Invalid Cases</SectionTitle>
        <Text>
          Valid & Invalid cases are mutually exclusive. From a purely logical point of view,
          defining one, also defines the other.
        </Text>
        <Text>
          However, there's an important difference between them. Invalid rules can be accumulative,
          you can define as many as you want, and the only effect is that each one reduces
          a bit more the universe of valid cases.
        </Text>
        <Text>
          On the other side, rules that claim which cases are valid cannot be declared without
          some relationship among them (most likely, "OR"). To simplify, and make FQL adoption
          easier, just use rules to avoid the cases that are invalid. If you can think of a simple rule
          to indicate the only valid cases, then the negation of that rule is all you need to indicate
          all invalid ones.
        </Text>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the validations.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Items (
  name text,
  model text,
  price number)

create form Store (
  address text,
  items references 1..many Items.(name, model, price))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Defining Validations</SectionTitle>
        <Text>
          It cannot be easier to define a rule over a Form than, in a readable
          manner, invalidate cases when they don't accomplish a set of
          restrictions. Let's better see an example.
        </Text>
        <Text>
          We have previously defined the form Items. Check out how to define a
          rule:
        </Text>
        <CodeExample title="fql (validations)">{`\
define rule NO_LAPTOPS: on Items when name = "Laptop" is invalid
`}
        </CodeExample>
        <Text>
          You can show all defined rules with the command show rules
        </Text>
        <CodeExample title="show rules">{`\
show rules // To list all defined rules
show rules Items // To list rules defined to the form Items
`}
        </CodeExample>

        <Text>
          So, if we try now to add a new entry to that form with item name
          "Laptop", an error is thrown.
        </Text>
        <CodeExample title="fql (create new)">{`\
create new Items values ("Laptop", "M132AXX", 450)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Validations grammar</SectionTitle>
        <Text>
          Let's check the grammar for the Validations:
        </Text>
        <CodeBlock>{`\
validation ::=
  validation-header validation-on validation-when "is" "invalid";

validation-header ::=
  "define" "rule" id ":";

validation-on ::=
  "on" id |
  id;

validation-when ::=
  "when" logical-expression;

logical-expression ::=
  logical-term { "or" logical-term };

logical-term ::=
  logical-factor { "and" logical-factor };

logical-factor ::=
  comparison |
  "(" logical-expression ")";

comparison ::=
  limits-comparison |
  simple-comparison;

limits-comparison ::=
  value ["not"] "between" value "and" value;

simple-comparison ::=
  unary-operator "(" value ")" |
  value relational-operator value;

unary-operator ::=
  ["not"] "null";

value ::=
  literal |
  label-path ;

label-path ::=
  id {"." id} ;

relational-operator ::=
  ["not"] "eq" |
  ["not"] "equal" |
  ["not"] "=" |
  "<>" |
  "!=" |
  ["not" | "!"] "<" |
  ["not" | "!"] ">" |
  "<=" |
  ">=";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Validated examples</SectionTitle>
        <Text>
          Let's add now a new rule that doesn't allow expensive items to
          register on the Store form
        </Text>
        <CodeExample title="fql (validations)">{`\
define rule NO_EXPENSIVE_ITEMS: on Store when items.price > 500 is invalid
`}
        </CodeExample>
        <Text>
          Let's see how it works then:
        </Text>
        <CodeExample title="fql (validations)" multiline>{`\
create new Items ('SmartWatch', 'SM34A', 300)
create new Items ('iPhone 12', 'M3456/A', 650)

create new Store ('2701 NW Ave.', ('SmartWatch', 'iPhone 12'))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (validations)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Defining Validations</LinkToSection></ListItem>
        <ListItem><LinkToSection>Validations grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Validated examples</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default Validations;

import React from "react";
import {
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  SectionTitle,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
} from "../../layouts/WikiArticle";

const RulesIntro = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Rules Intro</Heading>
        <Text>
          FQL can be seen as a Domain Specific Language (DSL) whose domain is (no
          surprisingly) Forms. And since a Form can be used to hold any kind of
          data, you end up having a DSL to manage any information system. Just
          store any information of any kind in a Form, and you have the full power
          at your disposal. WebForms are nothing new, and although FQL was
          conceived from the beginning with foreign key constraints and other
          validations, this is, nowadays, something also easy to find in other
          solutions. What makes a real difference is when we start talking about
          Rules that are defined in a language that is readable for any user.
        </Text>
        <Text>
          Maybe not everyone can write FQL, but almost anyone should be able to read it.
        </Text>
      </section>
      <section>
        <SectionTitle>Defining Rules</SectionTitle>
        <Text>
          Rules are a distinctive aspect of FQL, where its full potential can
          be better appreciated.
        </Text>
        <Text>
          Before FQL, end users could only test an IT system like a
          "black-box". Given a context and certain input, if the result was
          the expected one and this happened in a consistent manner, we could
          assume that the system is behaving properly. But as production
          "bugs" show, we cannot be 100% sure.
        </Text>
        <Heading as="h4">More rules or... more about Rules</Heading>
        <Text>
          FQL Rules define the system behavior in a human-readable manner. Not
          everyone might be able to write and express the rules in the best
          possible way, but everyone should be able to read, understand, and
          confirm that this is the expected system behavior.
        </Text>
        <Text>
          Rules can be subdivided in different groups, like Validations,
          Concepts, Actions, etc.
        </Text>
        <Text>
          Please check each specific section for more info.
        </Text>
      </section>
    </WikiArticleMain>
  </WikiArticle>
);

export default RulesIntro;

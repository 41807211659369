import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DocsIndex = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/docs/why_fql", { replace: true });
  }, [navigate]);
};

export default DocsIndex;

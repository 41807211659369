import React from "react";
import { Heading, Text, ListItem } from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const ModifyForm = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Modify Form</Heading>
        <Text>
          The Modify Form command is used to change the structure of the form by
          adding or removing data from its definition.
        </Text>
      </section>
      <section>
        <SectionTitle>Modify Form grammar</SectionTitle>
        <Text>Let's check the grammar for the MODIFY FORM command:</Text>
        <CodeBlock>
          {`\
modify-form ::= 
  "modify" "form" id mf-clause; 

mf-clause ::= 
  mf-add-clause | mf-remove-clause;

mf-add-clause ::= 
  "add" "(" data-spec { "," data-spec } ")"

mf-remove-clause ::= 
  "remove" "(" id { "," id } ")";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "modify form" command.
        </Text>
        <CodeExample title="fql (create form)" multiline>
          {`\
create form Types(name text not null unique)
create form Pokemon(name text not null unique)
`}
        </CodeExample>
        <Text>First, let's to show the definition of the Pokemon Form</Text>
        <CodeExample title="fql (show forms)">
          {`\
show forms Pokemon
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Modifying the Form</SectionTitle>
        <Text>
          Now let's try to modify the Pokemon form by adding the year of
          creation of each Pokemon and a reference to the Pokemon types.
        </Text>
        <CodeExample title="fql (modify form)">
          {`\
modify form Pokemon add (year number, type references 1..2 Types.name)
`}
        </CodeExample>
        <Text>
          Let's inspect the Pokemon form again to make sure the changes were
          made.
        </Text>
        <CodeExample title="fql (show forms)">
          {`\
show forms Pokemon
`}
        </CodeExample>
        <Text>Let's add some data to our base forms</Text>
        <CodeExample title="fql (create new)" multiline>
          {`\
create Types('Fire')
create Types('Water')
create Types('Grass')
create Types('Poison')
create Types('Electric')
create Types('Psychic')

create Pokemon('Charmander', 2010, 'Fire')
create Pokemon('Squirtle', 2020, 'Water')
create Pokemon('Bulbasaur', 2010, ('Grass', 'Poison'))
create Pokemon('Pikachu', 2007, 'Electric')
create Pokemon('Slowpoke', 1995, ('Water', 'Psychic'))
`}
        </CodeExample>
      </section>
      <section>
        <Text>
          Let us consult the information contained in the Pokemon form.
        </Text>
        <CodeExample title="fql (get case)">
          {`\
get Pokemon
`}
        </CodeExample>
      </section>

      <section>
        <SectionTitle>Removing specs from a Form</SectionTitle>
        <Text>
          We can change the structure of a form by deleting data. To do this we
          use the Modify form command as follows.
        </Text>
        <CodeExample title="fql (create form)">
          {`\
modify form Pokemon remove (year, type)
`}
        </CodeExample>
        <Text>
          This way, the Pokemon form will no longer contain either the year or
          the type of pokemon. Let's see the form definition below:
        </Text>
        <CodeExample title="fql (show forms)" multiline>
          {`\
show forms Pokemon
`}
        </CodeExample>

        <Text>
          If we try to get the data from this form we get the following:
        </Text>

        <CodeExample title="fql (get case)">
          {`\
get Pokemon
`}
        </CodeExample>
      </section>

      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>Feel free to test some code!</Text>
        <CodeExample title="fql (modify case)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Modify Case grammar</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Previous commands</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Modifying cases</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Modifying with references</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Test some FQL code!</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default ModifyForm;

import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  HStack,
  Spacer,
  Heading,
  Text,
  Show,
  Image,
  Link,
  useStyleConfig,
} from "@chakra-ui/react";
import styled from "styled-components";

const UnstyledLink = styled(Link)`
  color: #000;
  &:hover {
    color: #000;
  }
`;

const ServiceLogoImage = ({ variant, ...props }) => (
  <Image
    transitionDuration="0.1s"
    fit="cover"
    objectPosition="left"
    src={variant === 'dark' ? "/images/ab_green.png" : "/images/ab_blue.png"}
    {...props}
  />
);

export const Logo = ({ size, variant }) => {
  const imageHeight = size === "sm" ? "40px" : "50px";
  const styles = useStyleConfig('Logo', { variant });
  return (
    <UnstyledLink as={RouterLink} to="/" __css={styles}>
      <Heading as="h1" m={0} color="text">
        <Show above="md">
          <HStack gap={0}>
            <ServiceLogoImage h={imageHeight} variant={variant} />
            <Spacer w={2} />
            <Text m={0} fontSize={size === "sm" ? "md" : "xl"}>Application</Text>
            <Text m={0} fontWeight="400" fontSize={size === "sm" ? "md" : "xl"}>Base</Text>
          </HStack>
        </Show>
        <Show below="md">
          <ServiceLogoImage
            h={imageHeight}
            w={imageHeight}
            variant={variant}
          />
        </Show>
      </Heading>
    </UnstyledLink>
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(["sm", "md"]),
  variant: PropTypes.string,
};

export default Logo;

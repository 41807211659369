import React from "react";

const DeleteForm = () => {
  return (
    <button type="button" className={"mass-form-delete"}>
      <span className="icon-delete"></span>
    </button>
  );
};

export default DeleteForm;

import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const UniqueRule = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Unique Rule</Heading>
        <Text>
          Rules are a distinctive aspect of FQL where its full potential can be better appreciated. 
          Rules can be divided into different groups, such as validations, concepts, actions, etc.
        </Text>
        <Text>
          Uniqueness is a specific type of validation that allows us to check whether the combination 
          of some data specifications of a form is unique. It's an extension of the unique constraint 
          for multiple columns in SQL, but in this case with FQL forms.
        </Text>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's create a simple Form `Person`` to demostrate the use of unique validation.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Person (
	name text,
	age number
)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>How to define a simple unique validation</SectionTitle>
        <Text>
        In this hypothetical case, we will define a unique validation to make the combination 
        of `name` and `age` unique for the Form `Person`
        </Text>
        <Text>
          This is how we define the Validation:
        </Text>
        <CodeExample title="fql (define validation)">{`\
define rule unique_name_and_age: on Person(name, age) is unique
`}
        </CodeExample>
        <Text>
        And now we add some people. As you can see, 
        the combination of the name "Alicia" and the age 12 
        throws an error the second time we add a data.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create Person ("Alicia",  12)
create Person ("Ana", 15)
create Person ("Alicia", 12)
`}
        </CodeExample>
        
      </section>
      <section>
        <SectionTitle>Unique Validation Grammar</SectionTitle>
        <Text>
          Let's check the grammar for Unique Validation:
        </Text>
        <CodeBlock>{`\
rule ::=
  rule-header rule-condition;

rule-header ::=
  "define" "rule" id ":";

rule-condition ::= 
  rule-condition-header "is" "unique";

rule-condition-header ::=
  rule-on rule-during |
  rule-on;

rule-on ::= 
  "on" id "(" path { "," path } ")";

rule-during ::= 
  "during" "(" {during-elem} ")" |
  "during" during-elem;

path ::= 
  id { "." id };

during-elem ::=
  "create" | "remove" | "modify";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Complex Validation examples</SectionTitle>
        <Text>
          Let's add the Forms `Products`, `Components` and `Stores`
        </Text>
        <CodeExample title="fql (validations)" multiline>{`\
create form Stores(name text not null unique)

create form Components(name text not null unique)

create form Products(name text, store references Stores.name, components references 1..many Components.name)
`}
        </CodeExample>
        <Text>
        We want a product to contain only unique components for the same store. 
        So let's define the following rule:
        </Text>
        <CodeExample title="fql (define unique validation)">{`\
define rule one_component_per_store: on Products(store.name, components.name) is unique
`}
        </CodeExample>
        <Text>
        Let's add some new data to the forms.
        </Text>
        <CodeExample title="fql (define unique validation)" multiline>{`\
create Stores('S1')

create Components('Battery')
create Components('Screen')
create Components('RAM')

create Products('Phone', 'S1', ('Battery', 'RAM'))
create Products('Phone', 'S1', ('Screen'))
`}
        </CodeExample>
        <Text>
        Let's get the product information:
        </Text>
        <CodeExample title="fql (define unique validation)">{`\
get Products
`}
        </CodeExample>
        <Text>
        Now, if we want to add a new product with a store name = 'S1' and 
        any previously used component, it throws an error.
        </Text>
        <CodeExample title="fql (define unique validation)">{`\
create Products('Phone', 'S1', ('Battery', 'Screen'))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (validations)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Defining Validations</LinkToSection></ListItem>
        <ListItem><LinkToSection>Validations grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Validated examples</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default UniqueRule;

import React, { useContext } from "react";
import SkinBox from "../component/ps/skins/SkinBox";
import { GlobalContext } from "../component/GlobalContext";
import Heading from "../component/ps/heading/Heading";

const Skins = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const resetStyles = () => {
    setSettings({
      ...settings,
      theme: {
        skin: "clear",
        view: "box",
        topbar: "light",
        layout: "boxed",
        degraded: "degraded",
        workspaceColor: "workspace-color2",
        mainColor: "main-color1",
        secondColor: "secondary-color1",
      },
    });
  };

  const setClear = () => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, skin: "clear", topbar: "light" },
    });
  };
  const setFuturist = () => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, skin: "futurist", topbar: "dark" },
    });
  };
  const setVintage = () => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, skin: "vintage", topbar: "light" },
    });
  };

  return (
    <section className="work-zone">
      <Heading
        title={"CONFIGURATION OF APPEARANCE"}
        children={
          <button
            onClick={resetStyles}
            id="reset-styles"
            className="btn btn-secondary"
          >
            RESET TO DEFAULT STYLES
          </button>
        }
      />
      <section className="container">
        <h3>SELECT THE SKIN OF YOUR PREFERENCE:</h3>
        <div className="row skin-boxes">
          <SkinBox
            title="“Futurist” Skin"
            skin="futurist"
            changeSkin={setFuturist}
            active={settings.theme.skin === "futurist"}
          />
          <SkinBox
            title="“Clear” Skin"
            skin="clear"
            changeSkin={setClear}
            active={settings.theme.skin === "clear"}
          />
          <SkinBox
            title="“Vintage” Skin"
            skin="vintage"
            changeSkin={setVintage}
            active={settings.theme.skin === "vintage"}
          />
        </div>
      </section>
    </section>
  );
};

export default Skins;

import { Children, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import TerminalSettings from "./TerminalSettings";
import SignupLogin from "./LoginSignUp";

import consoleL from "../resources/console.png";
import wikiL from "../resources/wiki.png";
import formsL from "../resources/forms.png";
import authL from "../resources/auth.png";
import settingsButton from "../resources/settings.png";
import homeButton from "../resources/home2.png";
import authButton from "../resources/auth.png";
import tests from "../resources/tests.png";
import "./TopButtons.css";

function SettingsButton() {
  const [showSettings, setShowSettings] = useState(false);

  const toggleShowSettings = () => {
    setShowSettings(!showSettings);
  };

  return (
    <>
      <div className="top-items-container" onClick={toggleShowSettings}>
        <img className="top-items" src={settingsButton} alt="SettingsButton" />
        Settings
      </div>
      {showSettings && (
        <TerminalSettings onClose={toggleShowSettings} visible={showSettings} />
      )}
    </>
  );
}

function HomeButton() {
  return (
    <div>
      <Link to="/">
        <div className="home-item-container">
          <img className="top-items" src={homeButton} alt="homeButton"></img>
        </div>
      </Link>
    </div>
  );
}

function AuthButton() {
  const [showAuth, setShowAuth] = useState(false);

  const toggleShowAuth = () => {
    setShowAuth(!showAuth);
  };
  return (
    <>
      <div className="top-items-container" onClick={toggleShowAuth}>
        <img className="top-items" src={authButton} alt="authButton" />
        Login/Signup
      </div>
      {showAuth && <SignupLogin onClose={toggleShowAuth} visible={showAuth} />}
    </>
  );
}

function TopButtons() {
  const logo = [];
  const label = [];
  const location = useLocation();
  const buttons = [];

  const renderButtons = () => {
    switch (true) {
      case /^\/activate_account\/(.*)$/.test(location.pathname):
        logo.push(authL);
        label.push("Account Settings");
        buttons.push(<HomeButton key={"top-btns-1"} />);
        break;
      case "/fqlconsole" === location.pathname:
        logo.push(consoleL);
        label.push("FQL Console");
        buttons.push(
          <HomeButton key={1} />,
          <SettingsButton key={3} />,
          <AuthButton key={2} />
        );
        break;
      case "/presentation_server" === location.pathname:
        logo.push(formsL);
        label.push("FQL Presentation Server");
        buttons.push(
          <HomeButton key={"top-btns-1"} />,
          <AuthButton key={"top-btns-2"} />
        );
        break;
      case location.pathname.includes("wiki"):
        logo.push(wikiL);
        label.push("FQL Wiki");
        buttons.push(
          <HomeButton key={"top-btns-1"} />,
          <AuthButton key={"top-btns-2"} />
        );
        break;
      case "/" === location.pathname:
        logo.push(formsL);
        label.push("Form Query Language");
        buttons.push(<AuthButton key={"top-btns-1"} />);
        break;
      case "/test" === location.pathname:
        logo.push(tests);
        label.push("FQL Test");
        buttons.push(
          <HomeButton key={"top-btns-1"} />,
          <AuthButton key={"top-btns-2"} />
        );
        break;
      default:
        logo.push(formsL);
        label.push("Form Query Language");
        buttons.push(<HomeButton key={"top-btns-1"} />);
        break;
    }
  };

  return (
    <>
      <AnimatePresence>
        <section id="web-header" className="header">
          <Link to="/" className="title-logo">
            <img className="top-logo" src={logo} alt="logo"></img>
            <label>{label}</label>
          </Link>
          <div className="buttons-container">
            {renderButtons()}
            {buttons.map((item, i) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={i + 5}
              >
                {item}
              </motion.div>
            ))}
          </div>
        </section>
      </AnimatePresence>
    </>
  );
}

export default TopButtons;

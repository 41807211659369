import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import bcrypt from "bcryptjs";
import { apiBaseURL } from "../config";

import showPasswordIcon from "../resources/showPass.png";
import hidePasswordIcon from "../resources/hidePass.png";
import "./ActivateAccount.css";

const ActivateAccount = () => {
  const { email, activation_token } = useParams();
  const [count, setCount] = useState(0);
  const [activating, setActivating] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    if (activating) {
      const interval = setInterval(() => {
        setCount((count) => (count < 3 ? count + 1 : 0));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [activating]);

  useEffect(() => {
    fetch(`${apiBaseURL}/activated`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email.toString() }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "The account is activated") {
          setActivated(true);
          handleRedirect();
        } else if (data.msg !== "The account isn't activated") {
          alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [email]);

  const dots = ".".repeat(count);
  const activatingText = activating ? `Activating${dots}` : "Activate";

  const handleRedirect = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  };
  const handleGoHome = () => {
    window.location.href = "/";
  };

  const togglePasswordLogin = () => {
    setShowPassword(!showPassword);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const hashPassword = () => {
    return new Promise((resolve, reject) => {
      bcrypt.hash(password.toString(), 12, (err, hash) => {
        if (err) {
          reject(err);
        } else {
          resolve(hash);
        }
      });
    });
  };

  const handleActivateAccount = async (e) => {
    e.preventDefault();
    setActivating(true);
    e.target.disabled = true;
    if (password === confirmPassword) {
      const hashedPassword = await hashPassword();
      fetch(`${apiBaseURL}/activate_account`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email.toString(),
          activation_token: activation_token.toString(),
          password: hashedPassword.toString(),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.msg === "Account activated successfully") {
            setActivating(false);
            setActivated(true);
            handleRedirect();
          } else {
            alert(data.msg);
          }
        })
        .catch((err) => {
          alert("Failed to connect to backend engine\n", err);
        });
      setActivated(true);
    } else {
      alert("The passwords don't match. Try again!");
    }
  };

  return (
    <div className="act-container">
      <div className="auth-form">
        {!activated ? (
          <>
            <h2 className="title">Activate your account!</h2>
            <form>
              <label className="auth-label" htmlFor="password">
                Create password:
              </label>
              <div className="password-input">
                <input
                  className="auth-input"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePassword}
                  required
                />
                <img
                  className={`toggle-password-visibility ${
                    showPassword ? "visible" : ""
                  }`}
                  src={showPassword ? hidePasswordIcon : showPasswordIcon}
                  alt={showPassword ? "Hide password" : "Show password"}
                  onClick={togglePasswordLogin}
                />
              </div>
              <label className="auth-label" htmlFor="password">
                Confirm password:
              </label>
              <div className="password-input">
                <input
                  className="auth-input"
                  type={showPassword ? "text" : "password"}
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  required
                />
                <img
                  className={`toggle-password-visibility ${
                    showPassword ? "visible" : ""
                  }`}
                  src={showPassword ? hidePasswordIcon : showPasswordIcon}
                  alt={showPassword ? "Hide password" : "Show password"}
                  onClick={togglePasswordLogin}
                />
              </div>
              <button className="tab" onClick={handleActivateAccount}>
                {activatingText}
              </button>
            </form>
          </>
        ) : (
          <div className="act-container">
            <div className="auth-form">
              <h2 className="title">Account activated!</h2>
              <h2 className="signed-subt"> This account has been activated </h2>
              <h2 className="signed-text">
                You can now log in to your account
              </h2>
              <button className="tab" onClick={handleGoHome}>
                Go Home!
              </button>
              <h2 className="signed-text">
                You will be redirected to home page in 5 seconds...
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivateAccount;

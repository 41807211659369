import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import OrderForms from "./OrderForms";
import SearchField from "./SearchField";
import { GlobalContext } from "../../GlobalContext";

const Heading = ({ title, subtitle, children }) => {
  const { settings } = useContext(GlobalContext);
  const location = useLocation();
  return (
    <header className="container">
      <h1>
        {title} <small>{subtitle}</small>
      </h1>
      {location.pathname.includes("presentation_server/home") &&
        !settings.console.show && (
          <>
            <SearchField />
            <OrderForms />
          </>
        )}
      {children}
    </header>
  );
};

export default Heading;

import React from "react";
import {
  Heading,
  Text,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const DefineFunction = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Define Function</Heading>
        <Text>
        </Text>
      </section>
      <section>
        <SectionTitle>Define Function grammar</SectionTitle>
        <Text>
          Let's check the grammar for the DEFINE FUNCTION command:
        </Text>
        <CodeBlock>{`\
define-function ::=
  "define" "function" id argument-list function-body;

argument-list ::=
  "(" argument {"," argument} ")";

argument ::=
  id | "&optional" | "&rest";

function-body ::=
  "{" lisp-forms "}";

lisp-forms ::=
  { lisp-form };

lisp-form ::=
  "(" { lisp-form } ")" |
  lisp-atom;

lisp-atom ::=
  symbol | number | string;
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "define function" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Members (
  name text unique not null,
  age number)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Define a function for validation</SectionTitle>
        <Text>
          Suppose we want to define a function that raises an error depending
          on the user's age.
        </Text>
        <CodeExample title="fql (define function)">{`\
define function raise_age_error(age) {
  (error "Too young to get a job: ~A" age)
}
`}
        </CodeExample>
        <Text>
          See <Link as={RouterLink} to="/docs/validations">"Validations"</Link> for how to
          define a rule. Here's a rule to refuse users under 18 years old:
        </Text>
        <CodeExample title="fql (define rule)">{`\
define rule NO_TEENS_WITH_JOBS: on Members
  when age < 18 then CALL raise_age_error(age)
`}
        </CodeExample>
        <Text>
          Now, try to add a couple of users and let's see what happens.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Members ("MEIKO", 19)
create new Members ("Miku Hatsune", 16)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (define function)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Define Function grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Define a function for validation</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default DefineFunction;

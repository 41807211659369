import React from "react";
import {
  HStack,
  Flex,
  Hide,
  Box,
} from "@chakra-ui/react";

export const WikiArticleMain = ({ children }) => (
  <Flex className="docs" justify="center" w="100%">
    <Box as="article" w="100%" maxW="700px" px="15px">
      {children}
    </Box>
  </Flex>
);

export const WikiArticleSubPanel = ({ children }) => (
  <Hide below="xl">
    <Flex pos="sticky" top="100px" right="0" h="min-content">
      {children}
    </Flex>
  </Hide>
);

export const WikiArticle = ({ children }) => (
  <HStack gap={10} align="top">{children}</HStack>
);

import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const ModifyCase = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Modify Case</Heading>
        <Text>
        </Text>
      </section>
      <section>
        <SectionTitle>Modify Case grammar</SectionTitle>
        <Text>
          Let's check the grammar for the MODIFY command:
        </Text>
        <CodeBlock>{`\
modify-case ::=
  "modify" id ["values"] "(" value-list ")" ["with" logical-expression];

logical-expression ::=
  logical-term { "or" logical-term };

logical-term ::=
  logical-factor { "and" logical-factor };

logical-factor ::=
  comparison |
  "(" logical-expression ")";

comparison ::=
  limits-comparison |
  simple-comparison;

limits-comparison ::=
  value ["not"] "between" value "and" value;

simple-comparison ::=
  unary-operator "(" value ")" |
  value relational-operator value;

unary-operator ::=
  ["not"] "null";

value ::=
  literal |
  label-path ;

label-path ::=
  id {"." id};

relational-operator ::=
  ["not"] "eq" |
  ["not"] "equal" |
  ["not"] "=" |
  "<>" |
  "!=" |
  ["not" | "!"] "<" |
  ["not" | "!"] ">" |
  "<=" |
  ">=";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "modify" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Companies (name text)

create form Products (
  name text,
  designer references Companies.name,
  price number)
`}
        </CodeExample>
        <Text>
          Now, let's add some cases to that forms.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Companies ('Apple')
create new Companies ('Google')

create new Products ('MacBook Pro M1', 'Apple', 1299)
create new Products ('iPhone 12 Pro', 'Google', 499)
create new Products ('AirPods Pro 1st Gen', 'Apple', 399)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Modifying cases</SectionTitle>
        <Text>
          Well, now that we have some information on the form Products let's
          suppose that the AirPods are in offer and they no longer cost $399;
          instead they will cost $299.
        </Text>
        <Text>
          For that purpose we first run a get command to retrieve the
          "fql_version" attribute. That attribute allows us to know the actual
          version of a case inside a form and avoids the info to be corrupted
          if someone modifies a command while we are trying to do it as well.
        </Text>
        <CodeExample title="fql (get case)">{`\
get Products with name='AirPods Pro 1st Gen'
`}
        </CodeExample>
        <Text>
          Now that we know the fql_version of the "AirPods Pro 1st Gen" we can
          proceed to modify it.
        </Text>
        <CodeExample title="fql (modify case)">{`\
modify Products ('AirPods Pro 1st Gen','Apple', 299) with
  name='AirPods Pro 1st Gen'
  and price=399
  and fql_version=0
`}
        </CodeExample>
        <Text>
          If we run this commands we can check that the case was updated and
          also its "fql_version" attribute
        </Text>
        <CodeExample title="fql (get case)">{`\
get Products with name='AirPods Pro 1st Gen'
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Modifying with references</SectionTitle>
        <Text>
          If the case that we want to modify has a reference to another form
          we must be sure that the fql_version of that entry in the referenced
          form is correct.
        </Text>
        <Text>
          For example, we, on purpose, made a mistake on the designer of the
          "iPhone 12 Pro". The correct way to fix that is the following:
        </Text>
        <CodeExample title="fql (modify case)">{`\
modify Products ('iPhone 12 Pro', 'Apple', 499)
  with name = 'iPhone 12 Pro'
  and fql_version = 0
  and designer.fql_version = 0
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (modify case)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Modify Case grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Modifying cases</LinkToSection></ListItem>
        <ListItem><LinkToSection>Modifying with references</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default ModifyCase;

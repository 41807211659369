import React from 'react';
import {
  Box,
  Heading,
} from '@chakra-ui/react';
import DefaultLayout from "../layouts/default";
import CreateForm from '../new_component/organisms/CreateForm';

const PresentationServer = () => {
  return (
    <DefaultLayout navbarVariant="presentation-server">
      <Box className="new_presentation_server" w={650} mx="auto" pb={40} pt={10}>
        <Heading>Create new form</Heading>
        <CreateForm />
      </Box>
    </DefaultLayout>
  );
};

export default PresentationServer;

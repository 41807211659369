import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const ModalError = () => {
  const { settings } = useContext(GlobalContext);

  return <div>{settings.modals.data}</div>;
};

export default ModalError;

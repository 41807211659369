import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const SkinConfigBackgroundBox = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const handleChangeBackground = (e) => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, degraded: e.target.value },
    });
  };

  return (
    <div className="config-box col-xs-12 col-lg-6">
      <div className="item config-background">
        <h3>BACKGROUND:</h3>
        <div className="left">
          <label>
            <input
              type="radio"
              name="background"
              value="degraded"
              checked={settings.theme.degraded === "degraded"}
              onChange={handleChangeBackground}
            />
            Degraded
          </label>
          <img
            src={require(`../../../sass/images/${settings.theme.skin}/background-degraded.png`)}
            alt="change-background-degraded"
            className={settings.theme.degraded === "degraded" ? "selected" : ""}
          />
        </div>
        <span className="or">OR</span>
        <div className="right">
          <label>
            <input
              type="radio"
              name="background"
              value="plain"
              checked={settings.theme.degraded === "plain"}
              onChange={handleChangeBackground}
            />
            Plain
          </label>
          <img
            src={require(`../../../sass/images/${settings.theme.skin}/background-plain.png`)}
            alt="change-background-plain"
            className={settings.theme.degraded === "plain" ? "selected" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default SkinConfigBackgroundBox;

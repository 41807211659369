import React, { useContext } from "react";
import Views from "./Views";
import DeleteForm from "./DeleteForm";
import CreateFormButton from "./CreateFormButton";
import { Link, useLocation } from "react-router-dom";
import MessageStrip from "./MessageStrip";
import { GlobalContext } from "../../GlobalContext";

const SettingsBottomBar = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <Link onClick={goBack} className="go-back">
        <span className="icon-chevron-left"></span> <span>GO BACK</span>
      </Link>
      <Link to="home" className="go-back">
        <span className="icon-home"></span>
        <span>GO HOME</span>
      </Link>
    </>
  );
};

const BottomBar = () => {
  const { settings } = useContext(GlobalContext);
  const location = useLocation();

  const setComponent = () => {
    switch (true) {
      case location.pathname.includes("presentation_server/home"):
        return (
          <>
            <Views />
            <DeleteForm />
            <CreateFormButton />
          </>
        );
      case location.pathname.includes("skins"):
        return <SettingsBottomBar />;
      case location.pathname.includes("create_new"):
        return <SettingsBottomBar />;
      case location.pathname.includes("create_form"):
        return <SettingsBottomBar />;
      case location.pathname.includes("define_rule"):
        return <SettingsBottomBar />;
      default:
        return <></>;
    }
  };

  return (
    <nav className="bottombar">
      <div className="container">
        {setComponent()}
        {settings.message.show && <MessageStrip />}
      </div>
    </nav>
  );
};

export default BottomBar;

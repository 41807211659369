import React from "react";
import Button from "../atoms/Button";
import {
  ButtonGroup,
  Box,
  Heading,
  Text,
  Spacer,
  Show,
  Hide,
} from "@chakra-ui/react";
import styled from "styled-components";
import theme from "../../theme";

const FixedButtonGroup = styled(ButtonGroup)`
  position: relative;
  top: 0;
  z-index: ${theme.zIndices.top};
  height: 70px;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Tagline = (props) => (
  <Heading
    as="h2"
    display="inline-block"
    fontSize={{ base: "50px", sm: "90px" }}
    lineHeight={{ base: "90px", sm: "135px" }}
    {...props}
  />
);

const BottomMessage = styled(Text)`
  text-align: center;
  margin: 20px 0 30px;
`;

const HeroSection = ({ buttonsRef, fixButtons }) => {
  const mdButtonProps = theme.components.Button.sizes.md;
  const buttonProps = fixButtons ? mdButtonProps : {};
  const buttonGroupStyles = fixButtons
    ? {
        position: "fixed",
        gap: 0,
        transform: "translateX(-50%)",
        left: "50%",
      }
    : {};
  return (
    <Box align="center">
      <Tagline p="10px" align="right">
        THINK IT
        <br />
        BUILD IT
        <br />
        USE IT
      </Tagline>
      <BottomMessage variant="lg">
        Have your apps in minutes without coding
      </BottomMessage>
      <div ref={buttonsRef}></div>
      <Show above="md">
        <FixedButtonGroup style={buttonGroupStyles}>
          <Button icon="fa-arrow-right" size="xl" href="/home" {...buttonProps}>
            Try it for free
          </Button>
          <Button href="/docs" size="xl" variant="outline" {...buttonProps}>
            How it works
          </Button>
        </FixedButtonGroup>
        {/* dummy */}
        <Spacer
          h={70}
          visibility="hidden"
          display={{ lg: fixButtons ? "" : "none" }}
        />
      </Show>
      <Hide above="md">
        <FixedButtonGroup zIndex="base" gap="5px">
          <Button icon="fa-arrow-right" size={["md", "md", "xl"]} href="/home">
            Try it for free
          </Button>
          <Button href="/signup" size={["md", "md", "xl"]} variant="outline">
            Sign Up
          </Button>
        </FixedButtonGroup>
      </Hide>
    </Box>
  );
};

export default HeroSection;

import { useContext, useState } from "react";
import { GlobalContext } from "../GlobalContext";
import Heading from "./heading/Heading";
import { apiBaseURL } from "../../config";
import Table from "../Table";
import Console from '../../new_component/organisms/Console';

const PSConsole = () => {
  const { settings, setSettings, fqlToken, userInfo } =
    useContext(GlobalContext);
  const [connection, setConnection] = useState({ host: apiBaseURL });
  const scheme = window.location.protocol;
  const [termUserInfo, setTermUserInfo] = useState({ token: userInfo.token });

  const getToken = async (host) => {
    await fetch(`${scheme}//${host}/new_guest_token`, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setTermUserInfo({ token: data.token });
      })
      .catch((err) => console.log(err));
  };

  const runCode = async (command) => {
    return await fetch(`${connection.host}/run_code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code: command,
        token: termUserInfo.token,
        fql_token: fqlToken.current,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const keys = [];
        if (data.output && data.output.length !== 0) {
          for (const k in data.output[0]) {
            keys.push(k);
          }
        }
        if (data.fql_token) {
          setSettings({
            ...settings,
            userData: {
              ...settings.userData,
              fqlToken: {
                "FORM-NAME": data.fql_token["FORM-NAME"],
                TOKEN: data.fql_token.TOKEN,
              },
            },
          });
        }
        return (
          <>
            {data.msg}
            {data.output ? <Table output={data.output} keys={keys} /> : ""}
          </>
        );
      })
      .catch((err) => `Bad FQL code: ${err}`);
  };

  const commands = {
    echo: {
      description: "Echo a passed string.",
      usage: "echo <string>",
      fn: (...args) => {
        return args.join(" ");
      },
    },

    token: {
      description: "Returns the user token identifier",
      fn: () => termUserInfo.token,
    },

    connected: {
      description:
        "Returns a boolean value if the terminal is connected to an engine or not.",
      fn: () => {
        return `Connected to host ${connection.host}`;
      },
    },

    disconnect: {
      description: "Disconnects the backend engine.",
      fn: () => {
        if (apiBaseURL !== connection.host) {
          setTermUserInfo({ token: userInfo.token });
          setConnection({ host: apiBaseURL });
          return `Disconected from ${connection.host}! \n Connected to host ${apiBaseURL}`;
        } else {
          return "Not connected to a different host";
        }
      },
    },

    "?": {
      description: "Shows the help",
      usage: "?",
      fn: () =>
        "help - Show a list of available commands.\nclear - Empty the terminal window.\necho - Echo a passed string. - echo <string>\nconnected - Returns a boolean value if the terminal is connected to an engine or not.\ndisconnect - Disconnects the backend engine.\n? - Shows the help - ?\nconnect - Connects the terminal emulator to a backend engine on the specified <address> - connect <address>\n",
    },

    connect: {
      description:
        "Connects the terminal emulator to a backend engine on the specified <address>",
      usage: "connect <address>",
      fn: async (...args) => {
        if (args.length === 0 || args.length > 1)
          return "One argument expected";

        return fetch(`${scheme}//${args[0]}/api/connect`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then(async () => {
            setConnection({ host: `${scheme}//${args[0]}/api` });
            const apiHost = apiBaseURL.split("//")[1].split("/")[0];
            if (args[0] === apiHost) {
              setTermUserInfo({ token: userInfo.token });
            } else await getToken(args[0]);
            return `Done! Connected to ${args[0]}`;
          })
          .catch((err) => {
            console.error(err);
            return "Connection error. Check the address and try again!";
          });
      },
    },

    create: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`create ${args.join(" ")}`);
      },
    },
    get: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`get ${args.join(" ")}`);
      },
    },
    modify: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`modify ${args.join(" ")}`);
      },
    },
    remove: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`remove ${args.join(" ")}`);
      },
    },
    define: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`define ${args.join(" ")}`);
      },
    },
    show: {
      description:
        "Using the previously connected engine, executes the code and returns a message",
      usage: "fql <fql_code>",
      fn: async (...args) => {
        return await runCode(`show ${args.join(" ")}`);
      },
    },
  };

  return (
    <section className="work-zone">
      <Heading title="FQL" subtitle="CONSOLE" />
      <div className="ps-console-container">
        <Console commands={commands} userToken={userInfo.token}
          style={{
            backgroundColor: "#3332359e",
            minHeight: "0px",
            width: "100%",
            height: "65vh",
            maxHeight: "65vh",
            paddingBottom: "0px",
          }}
        />
      </div>
    </section>
  );
};
export default PSConsole;

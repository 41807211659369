import React from "react";
import { Textarea as ChakraTextarea } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";

const Textarea = React.forwardRef(({ minRows = 1, ...props }, ref) => (
  <ChakraTextarea
    minH="unset"
    overflow="hidden"
    w="100%"
    resize="none"
    ref={ref}
    minRows={minRows}
    as={ResizeTextarea}
    {...props}
  />
));

export default Textarea;

import PropTypes from "prop-types";
import Button from "../atoms/Button";
import Logo from "../atoms/Logo";
import SkinSelector from "./SkinSelector";
import { GlobalContext } from "../../component/GlobalContext";
import { useLocation } from "react-router-dom";
import { HStack, Show } from "@chakra-ui/react";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export const MenuButton = ({
  size,
  icon,
  hasIcon = true,
  hasText = true,
  children,
  ...props
}) => {
  const isSmall = size === "sm";
  return (
    <Button
      size={isSmall ? "md" : ["md", "md", "xl"]}
      icon={hasIcon && icon}
      {...props}
    >
      {hasText && children}
    </Button>
  );
};

export const LoginButton = ({ size, setShowLogin, ...props }) => {
  const setVisibleLogin = () => {
    setShowLogin(true);
  };

  return (
    <MenuButton
      onClick={setVisibleLogin}
      variant="secondary"
      size={size}
      {...props}
    >
      Login
    </MenuButton>
  );
};

export const LogoutButton = ({ size, doLogout, setShowLogin, ...props }) => (
  <>
    <MenuButton
      onClick={doLogout}
      icon="arrow-right-from-bracket"
      variant="secondary"
      size={"sm"}
      {...props}
    ></MenuButton>
    <MenuButton
      onClick={() => {
        setShowLogin(true);
      }}
      icon={faUser}
      variant="secondary"
      size={"sm"}
      {...props}
    ></MenuButton>
  </>
);

export const ContactButton = ({ size, props }) => (
  <>
    <Show above="lg">
      <MenuButton href="https://synchronit.com/#contact-form" variant="link" size={size} {...props}>
        Contact
      </MenuButton>
    </Show>
    <Show below="lg">
      <MenuButton href="https://synchronit.com/#contact-form" variant="link" size="md" {...props}>
        Contact
      </MenuButton>
    </Show>
  </>
);

export const AutomatedTestButton = (props) => {
  const path = useLocation();

  return (
    <>
      {path.pathname !== "/automated_tests" ? (
        <MenuButton href="/automated_tests" variant="link" {...props}>
          Automated Test
        </MenuButton>
      ) : (
        <MenuButton href="/docs" variant="link" {...props}>
          Docs
        </MenuButton>
      )}
    </>
  );
};

export const UserLoginButtons = ({
  size,
  isLoggedIn,
  doLogout,
  setShowLogin,
}) =>
  isLoggedIn ? (
    <LogoutButton size={size} setShowLogin={setShowLogin} doLogout={doLogout} />
  ) : (
    <LoginButton size={size} setShowLogin={setShowLogin} />
  );

const MenuButtons = ({ size, variant, isLoggedIn, doLogout, setShowLogin }) => {
  return (
    <HStack gap={{ base: "10px", lg: "20px" }} justify="flex-end">
      {(() => {
        switch (variant) {
          case "landing-page":
            return (
              <>
                <ContactButton size={size} />
                <UserLoginButtons
                  size={size}
                  isLoggedIn={isLoggedIn}
                  setShowLogin={setShowLogin}
                  doLogout={doLogout}
                />
              </>
            );
          case "presentation-server":
            return (
              <>
                <SkinSelector />
                <AutomatedTestButton size={size} />
                <UserLoginButtons
                  size={size}
                  isLoggedIn={isLoggedIn}
                  setShowLogin={setShowLogin}
                  doLogout={doLogout}
                />
              </>
            );
          case "normal":
            return (
              <>
                <AutomatedTestButton size={size} />
                <UserLoginButtons
                  size={size}
                  isLoggedIn={isLoggedIn}
                  setShowLogin={setShowLogin}
                  doLogout={doLogout}
                />
              </>
            );
          default:
        }
      })()}
    </HStack>
  );
};

const NavbarContainer = ({ children, size, ...props }) => (
  <HStack
    justify="space-between"
    w="100%"
    pos="fixed"
    top="0"
    px={["20px", "20px", "30px", "50px"]}
    zIndex="navbar"
    {...props}
  >
    {children}
  </HStack>
);

export const Navbar = ({
  size,
  variant = "normal",
  leftButtons,
  setShowLogin,
}) => {
  const isSmall = size === "sm";
  const containerProps = isSmall
    ? { py: { base: "8px", lg: "10px" } }
    : { py: { base: "15px", lg: "20px" } };
  return (
    <NavbarContainer
      bgColor="layout.bg"
      h={isSmall ? 70 : 100}
      size={size}
      {...containerProps}
    >
      <HStack>
        {leftButtons}
        <Logo size={size} variant="dark" />
      </HStack>
      <GlobalContext.Consumer>
        {({ userInfo, loggedIn, doLogout }) => (
          <MenuButtons
            size={size}
            variant={variant}
            isLoggedIn={loggedIn}
            setShowLogin={setShowLogin}
            doLogout={doLogout}
          />
        )}
      </GlobalContext.Consumer>
    </NavbarContainer>
  );
};

Navbar.propTypes = {
  size: PropTypes.oneOf(["md", "sm"]),
  variant: PropTypes.oneOf(["landing-page", "normal", "presentation-server"]),
};

export default Navbar;

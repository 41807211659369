import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import TopButtons from "../component/TopButtons";

import wiki from "../resources/wiki.png";
import form from "../resources/forms.png";
import console from "../resources/console.png";
import tests from "../resources/tests.png";

import "./Home.css";

const Element = ({ id, img, txt, href }) => {
  return (
    <Link className="elemLink" to={href}>
      <div className="elem" id={id}>
        <img className="elem-img" src={img} alt="card.jpg"></img>
        <h4 className="elem-title">{id}</h4>
        <p className="elem-text">{txt}</p>
      </div>
    </Link>
  );
};

const Home = () => {
  const elems = [
    {
      id: "FQL Console",
      img: console,
      txt: "Type your FQL Commands directly in this Console to interact with the App Base Engine.",
      href: "fqlconsole",
    },
    {
      id: "Presentation\n Server",
      img: form,
      txt: "Web User Interface to create & use forms in a graphical way, without writing any code.",
      href: "presentation_server/home",
    },
    {
      id: 'FQL  "Wiki"',
      img: wiki,
      txt: "Wiki page about Form Query Language (FQL), how to use it, actual status, and other info",
      href: "wiki/introduction",
    },
    {
      id: "Automated Tests",
      img: tests,
      txt: "Testing page with key cases that ensure quality & stability. Useful also as example.",
      href: "test",
    },
  ];

  return (
    <section>
      <TopButtons />
      <div className="elems-container">
        {elems.map(({ id, img, txt, href }, index) => {
          return (
            <motion.div
              key={`home-${index}`}
              initial={{
                opacity: 0,
                y: index < 2 ? -100 : 100,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              transition={{ delay: 0.1 * index + 0.3 }}
            >
              <Element key={index} id={id} img={img} txt={txt} href={href} />
            </motion.div>
          );
        })}
      </div>
    </section>
  );
};

export default Home;

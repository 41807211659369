import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 300;
  transition: opacity 0.3s ease-in-out;
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  text-align: left;
  border-radius: 4px;
  z-index: 301;
`;

const Popup = ({ onClose, visible, children }) => {
  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <Overlay onClick={onClose} />
      <Container>{children}</Container>
    </div>
  );
};

export default Popup;

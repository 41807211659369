import React, {
  useState,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { GlobalContext } from "../../component/GlobalContext";
import {
  Flex,
  Spacer,
  HStack,
  Box,
  Text,
  useToast,
  Toast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
} from "@chakra-ui/react";
import CodeBlock from "../atoms/CodeBlock";
import Button from "../atoms/Button";
import Textarea from "../atoms/Textarea";
import styled from "styled-components";
import theme from "../../theme";
import { resetSchema } from "../../actions";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

export const CodeTitle = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #e0e0e0;
  color: ${theme.colors.text};
`;

const CodeExample = forwardRef(
  ({ run = true, title, children, multiline, ...props }, ref) => {
    const { userInfo, runCode } = useContext(GlobalContext);
    const textRef = useRef(null);
    const toast = useToast();
    const [executionLog, setExecutionLog] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => {
      return {
        focus: () => textRef.current?.focus(),
      };
    });

    const handleCopyCode = () => {
      const code = textRef.current.value;
      navigator.clipboard
        .writeText(code)
        .then(() => {
          toast({
            title: "Success",
            description: "Code copied",
            status: "success",
            duration: 1500,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: "Failed to copy to the clipboard",
            status: "error",
            isClosable: true,
          });
        });
    };

    const handleRunCode = async () => {
      const commands = textRef.current.value
        .split(multiline ? /\n/ : /\n{2,}/)
        .map((command) => command.replace(/\n/g, ""))
        .filter((cmd) => !!cmd);
      const toastId = toast({
        title: "In progress",
        description: "Running the code...",
        status: "loading",
        position: "bottom-right",
      });
      const updateProgress = (doneCount) => {
        toast.update(toastId, {
          description: `Running the code...(${doneCount} / ${commands.length})`,
        });
      };
      let messages = [];
      for (let i = 0; i < commands.length; ++i) {
        const code = commands[i];
        let json = await runCode({ code, token: userInfo.token }).catch(
          (err) => {
            toast.update(toastId, {
              status: "error",
              title: "Error",
              description: err.toString(),
              isClosable: true,
            });
          }
        );
        json.code = code;
        messages.push(json);
        updateProgress(i + 1);
      }
      setExecutionLog(messages);
      if (messages.find((m) => !!m.error)) {
        toast.update(toastId, {
          render: () => (
            <Box onClick={onOpen} style={{ cursor: "pointer" }}>
              <Toast
                title={`Errored ${
                  messages.filter((m) => !!m.error).length
                } in ${messages.length}`}
                description={
                  <Text whiteSpace="pre-wrap" m={0}>
                    {messages.map((m) => m.msg).join("\n")}
                  </Text>
                }
                status="error"
                isClosable={true}
              />
            </Box>
          ),
        });
      } else {
        toast.update(toastId, {
          render: () => (
            <Box onClick={onOpen} style={{ cursor: "pointer" }}>
              <Toast
                title={`Successfully ${commands.length} command(s) executed`}
                description={
                  <Text whiteSpace="pre-wrap" m={0}>
                    {messages.map((m) => m.msg).join("\n")}
                    {'\n'}
                    {'\n'}
                    <Text style={{ fontSize: "0.9em", fontStyle: "italic" }}>(click this box to see the full output)</Text>
                  </Text>
                }
                status="success"
                isClosable={true}
              />
            </Box>
          ),
        });
      }
    };

    const handleReset = async () => {
      const toastId = toast({
        title: "In progress",
        description: "Resetting DB...",
        status: "loading",
      });
      await resetSchema({ token: userInfo.token })
        .then((data) => {
          toast.update(toastId, {
            status: "success",
            title: "Successfully resetted",
            description: data.msg,
            isClosable: true,
          });
        })
        .catch((err) => {
          toast.update(toastId, {
            status: "error",
            title: "Error",
            description: err.toString(),
            isClosable: true,
          });
        });
    };

    return (
      <CodeBlock pos="relative" pt="2.5rem" px="0" pb="0">
        <CodeTitle>
          <Flex>
            <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {title}
            </Box>
            <Spacer />
            <HStack gap="16px">
              <Button
                onClick={handleCopyCode}
                variant="link"
                icon="fa-regular fa-copy"
              >
                Copy
              </Button>
              {run && (
                <>
                  <Button
                    onClick={handleReset}
                    variant="link"
                    icon="rotate-right"
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleRunCode}
                    variant="link"
                    icon="fa-regular fa-circle-play"
                  >
                    Run
                  </Button>
                </>
              )}
            </HStack>
          </Flex>
        </CodeTitle>
        <Textarea
          pt="0.8rem"
          px="24px"
          pb="20px"
          autoCorrect="off"
          spellCheck="false"
          variant="unstyled"
          placeholder="Write some FQL code here!"
          defaultValue={children?.replace(/\n$/, "")}
          ref={textRef}
          {...props}
        />
        {/* Detail dialog */}
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {executionLog.map((m, i) => (
                <Box key={`execution-log-${i}`}>
                  <CodeBlock>{m.code}</CodeBlock>
                  <Text color="accent.darkgray" ml="1rem">
                    <FA icon="fa-arrow-right" /> {m.msg}
                  </Text>
                  {m.output && m.output.length && (
                    <Box maxW="100%" overflowX="auto">
                      <Table variant="striped">
                        <Thead>
                          <Tr>
                            {Object.keys(m.output[0]).map((k, j) => (
                              <Th
                                key={`execution-log-${i}-th-${j}`}
                                textAlign="center"
                              >
                                {k}
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {m.output.map((output, k) => (
                            <Tr key={`execution-log-${i}-td-${k}`}>
                              {Object.keys(output).map((key) => (
                                <Td
                                  key={`execution-log-${i}-td-${k}-${key}`}
                                  textAlign="center"
                                >
                                  {JSON.stringify(output[key])}
                                </Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  )}
                </Box>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </CodeBlock>
    );
  }
);

export default CodeExample;

import React, { useContext } from 'react';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { GlobalContext } from "../../component/GlobalContext";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

const SkinSelector = () => {
  const { settings, setSettings } = useContext(GlobalContext);
  const chooseSkin = (skin) => {
    setSettings({
      ...settings,
      theme: {
        ...settings.theme,
        skin,
      },
    });
  };
  return (
    <Menu>
      <MenuButton as={Button} variant="outline" leftIcon={<FA icon="palette" />}>Skin</MenuButton>
      <MenuList>
        <MenuItem onClick={() => chooseSkin('clear')}>Clear</MenuItem>
        <MenuItem onClick={() => chooseSkin('futurist')}>Futurist</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default SkinSelector;

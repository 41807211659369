import { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { GlobalContext } from "../../GlobalContext";

import ok from "../../../resources/ok.png";
import error from "../../../resources/error.png";
import warning from "../../../resources/warning.png";

const MessageStrip = () => {
  const { settings, setSettings } = useContext(GlobalContext);
  const timeOut = useRef();
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    timeOut.current = setTimeout(() => {
      setShowMessage(false);
      clearTimeout(timeOut.current);
      timeOut.current = setTimeout(() => {
        setSettings({
          ...settings,
          message: { ...settings.message, show: false },
        });
        settings.message.fn();
      }, 1000);
    }, 4000);
    return () => clearTimeout(timeOut.current);
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timeOut.current);
    setSettings({
      ...settings,
      message: { ...settings.message, show: true },
    });
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => {
      setSettings({
        ...settings,
        message: { ...settings.message, show: false },
      });
      settings.message.fn();
    }, 1000);
  };

  return (
    <motion.div
      className={`message-strip ${settings.message.status}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseEnter}
      initial={{ opacity: 0 }}
      animate={{
        opacity: showMessage ? 1 : 0,
      }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <motion.img
        initial={{ opacity: 0, height: "100px", width: "100px" }}
        animate={{
          opacity: 1,
          height: "50px",
          width: "50px",
          transition: { duration: "0.3" },
        }}
        exit={{ opacity: 0 }}
        src={
          settings.message.status === "ok"
            ? ok
            : settings.message.status === "info"
            ? warning
            : error
        }
        alt="result"
      />
      <span> {settings.message.data}</span>
    </motion.div>
  );
};

export default MessageStrip;

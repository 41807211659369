import React from "react";
import { Link } from "react-router-dom";
import { Heading, Text, ListItem, Image } from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import db_ref from "../../resources/db_reference.png";
import CodeExample from "../../new_component/organisms/CodeExample";
import optimistic_locking from "../../resources/optimistic_locking_transparent.png";
import fql_locking from "../../resources/fql_locking_business_transparent.png"

const DataIntegrity = () => {
  return (
    <WikiArticle>
      <WikiArticleMain>
        <section>
          <Heading as="h2">Data Integrity in FQL</Heading>
          <SectionTitle>The Problems with Optimistic Locking</SectionTitle>
          <Text>
            Data Integrity is taken very seriously in Relational Databases.
          </Text>
          <Text>
            However, because the domain for SQL is composed by database tables,
            this is the level at which data integrity is warrantied.
          </Text>
          <Text>
            That means that data integrity is checked at record level.
            But ... what happens if a User is viewing data from multiple records,
            making a business decision based on what is shown,
            and takes an action that updates one of these records?
          </Text>
          <Text>
            In cases like this, the DB Referential Integrity is not enough.
            Let's have a look at how DB and Optimistic Locking manage concurrent access:
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={optimistic_locking}
            alt="Optimistic Locking"
          />
          <Text>
            As we can see in the figure, this is working well, as long as we are
            reading and updating only one single table and record.
            But let's see what happens when we have (as it usually happens) a
            User Interface showing data from multiple tables and records.
          </Text>
        </section>

        <section>
          <SectionTitle>FQL Integrity at Business Level</SectionTitle>
          <Text>
            Unlike a database, an Application Base works on a higher level of abstraction.
            The domain is not composed by tables or files, but by Forms or User Transactions.
            At this level, what the user is viewing on the UI in order to make a decision
            and take an action, is all considered to be part of the same User Transaction.
          </Text>
          <Text>
            This means that instead of checking only for any updates on the record being modified,
            an Application Base checks <span style={{ fontStyle: 'italic' }}>
            all records involved in the transaction</span>, in order to ensure
            <span style={{ fontWeight: 'bold' }}> business consistency</span>.
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={fql_locking}
            alt="FQL Locking"
          />
          <br />
          <Text>
            As you can see in the image, when data from multiple sources (tables, records)
            is being retrieved to be shown on the User Interface, before any of them can be changed,
            the Application Base checks that all those involved in the transaction are still the same.
          </Text>
        </section>

        <section>
          <SectionTitle>Wait ... is my data currupted?</SectionTitle>
          <Text>
            At this point, you might be wondering if your database is consistent.
            The good news is that from a pure database & technical point of view, it is.
            Now, from a business point of view, the reality might be different.
          </Text>
          <Text>
            Imagine a situation where some customers are entitled to receive a given benefit.
            Then, a customer detects that by mistake, a wrong category has been assigned and
            allows him to claim this benefit.
            Fortunately, at the same time, somebody has realized the mistake and is about to
            fix the corresponding customer category.
          </Text>
          <Text>
            The problem is that since the records being updated are different, the usual
            Optimistic Locking implementation allows both transactions to happen.
          </Text>
          <Text>
            And even more concerning is that data inconsistencies, despite their business impact,
            can remain unnoticed. Let's see a Real World Example ...
          </Text>
        </section>

        <section>
          <SectionTitle>A Real World Example</SectionTitle>
          <Text>
            This is a real case of an electricity company.
            After paying several millions for a huge consulting
            project that implied a broad reengineering of the processes and
            information systems, everything was going pretty well.
          </Text>
          <Text>
            Relational databases were already an industry standard, but the implementation
            was based was a non-relational database (Adabas), in order to improve performance.
            One-to-many relationships were implemented as arrays, with no FK constraint.
            The result was a much performant system and everyone was proud of it.
          </Text>
          <Text>
            But after having seen many inconsistencies, I made a small program in Natural.
            The input was the FK constraints and a Cobol program was automatically
            generated, compiled and run, in order to make the checks and produce a report
            of the inconsistencies.
          </Text>
          <Text>
            Among other things, one of the most shocking cases, was the one of meters that had no valid
            office assigned to them. This meant that no office was making the reading of the consumption
            and therefore, no invoice process was ever initiated for these customers.
          </Text>
          <Text>
            There are multiple learnings out of just one real example, but an important one is
            that
            <span style={{ fontWeight: 'bold' }}> data inconsistencies are not a technical, but a
            business problem, that can remain unnoticed.</span>
          </Text>
        </section>

        <section>
          <SectionTitle></SectionTitle>
          <Text></Text>
        </section>
      </WikiArticleMain>

      <WikiArticleSubPanel>
        <SectionIndex>
          <ListItem>
            <LinkToSection>Problems with Optimistic Locking</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>FQL Integrity at Business Level</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>Wait ... is my data currupted?</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>A Real World Example</LinkToSection>
          </ListItem>
        </SectionIndex>
      </WikiArticleSubPanel>
    </WikiArticle>
  );
};

export default DataIntegrity;

import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const SkinConfigHeaderBox = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const handleChangeHeader = (e) => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, topbar: e.target.value },
    });
  };

  return (
    <div className="config-box col-xs-12 col-lg-6">
      <div className="item config-header">
        <h3>HEADER:</h3>
        <div className="left">
          <label>
            <input
              type="radio"
              name="header"
              value="dark"
              checked={settings.theme.topbar === "dark"}
              onChange={handleChangeHeader}
            />
            Dark
          </label>
          <img
            src={require(`../../../sass/images/${settings.theme.skin}/header-dark.png`)}
            className={settings.theme.topbar === "dark" ? "selected" : ""}
            alt="dark-header"
          />
        </div>
        <span className="or">OR</span>
        <div className="right">
          <label>
            <input
              type="radio"
              name="header"
              value="light"
              checked={settings.theme.topbar === "light"}
              onChange={handleChangeHeader}
            />
            Light
          </label>
          <img
            src={require(`../../../sass/images/${settings.theme.skin}/header-light.png`)}
            className={settings.theme.topbar === "light" ? "selected" : ""}
            alt="light-header"
          />
        </div>
      </div>
    </div>
  );
};

export default SkinConfigHeaderBox;

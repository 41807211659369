import { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "./GlobalContext";

const ItemOptions = ({ name }) => {
  const { settings, setSettings } = useContext(GlobalContext);

  const handleDeleteForm = () => {
    setSettings({
      ...settings,
      modals: {
        ...settings.modals,
        title: "DELETE FORM",
        icon: "icon-delete",
        id: "modal-delete",
        show: true,
        data: name,
      },
    });
  };

  const handleShowRules = () => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, view: "" },
      console: { ...settings.console, show: true, form: name, dispatch: 'define-rule' },
    });
  };

  return (
    <ul className="options">
      <li>
        <Link to={`create_new/${name.toLowerCase()}`}>
          <span className="icon-fields-form"></span>
          <i>
            <span>Use</span> Form
          </i>
        </Link>
      </li>

      <li>
        <a>
          <span className="icon-edit"></span>
          <i>
            Edit <span>Form</span>
          </i>
        </a>
      </li>

      <li>
        <a onClick={handleShowRules}>
          <span className="icon-build"></span>
          <i>
            Define <span>Rules</span>
          </i>
        </a>
      </li>

      <li>
        <a onClick={handleDeleteForm}>
          <span className="icon-delete"></span>
          <i>
            Delete <span>Form</span>
          </i>
        </a>
      </li>
    </ul>
  );
};

export default ItemOptions;

import React from "react";
import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";

const CreateForm = ({ children }) => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Create Form</Heading>
        <Text>
          The 'create form' command allows the user to do exactly that: create a new
          form. This new form is as manageable as you can imagine: any name you want
          and any parameters you want to request. The parameters can be of any type:
          text, number, boolean, date, a file, or simply a reference to another
          previously created form.
        </Text>
        <Text>
          Also you can make this parameters unique or totally unique, which
          difference we will explain later. The magic continues when you can set the
          cardinality of the references from 1 to many, many to many or better yet
          from any integer to other as you like, of course being consistent with
          what you want to achieve.
        </Text>
        <Text>
          Let's dive in!
        </Text>
      </section>
      <section>
        <SectionTitle>Defining Forms</SectionTitle>
        <Text>
          If you consider Form Query Language (FQL) as a Domain Specific
          Language (DSL), then the Forms are the Domain on top of which FQL
          applies.
        </Text>
        <Text>
          You can consider a Form also as a Transaction, but it is preferred
          to call it a Form, because it is an easier concept to grasp by the
          end user. Almost every Internet user has sometime filled out a form,
          even on paper or an electronic one (generally, web-based).
          Transactions on the other hand, imply some other concepts that are
          automatically handled by an application base and, even though useful
          to know them, it is not a requirement. Just choose the concept (Form
          or Transaction) that you feel more comfortable with, but please use
          the word "Form" when comunicating with others.
        </Text>
      </section>
      <section>
        <SectionTitle>Create Form grammar</SectionTitle>
        <Text>
          Let's check the grammar for the CREATE FORM command:{"\n"}
        </Text>
        <CodeBlock>{`\
create-form ::=
  "create" "form" id "(" data-spec {"," data-spec } ")";

data-spec ::=
  data-definition |
  data-reference;

data-definition ::=
  id data-type {data-definition-constraint};

data-type ::=
  "number" |
  "boolean" |
  "text" |
  "date" |
  "time" |
  "file" |
  "image";

data-definition-constraint ::=
  "not null" |
  "unique";

data-reference ::=
  id "references" [ref-cardinality] ref-path [ref-constraint] [ref-including];

ref-cardinality ::=
  int |
  int ".." int |
  int ".." "many";

ref-path ::=
  label-path |
  label-path ["." path-last-item];

label-path ::=
  id {"." id};

path-last-item ::=
  "*" |
  "(" id {"," id} ")";

ref-constraint ::=
  "unique" |
  "totally unique";

ref-including ::=
  "including" data-spec |
  "including" "(" data-spec {"," data-spec} ")";
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Simple Form (no References)</SectionTitle>
        <Text>
          Let's begin with some simple FQL code. The create form command
          allows us to create new Forms with its particular properties. For
          example, now we are creating a new Form named Country, which will
          store the country name, its capital and its extension.
        </Text>
        <Text>
          Summarizing:
        </Text>
        <UnorderedList>
          <ListItem>New form named Country will be created</ListItem>
          <ListItem>
            Parameters:
            <UnorderedList styleType="circle">
              <ListItem>Name (unique and not null)</ListItem>
              <ListItem>Capital (not null)</ListItem>
              <ListItem>Extension</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <CodeExample title="fql (create form)">{`\
create form Country(
  name text not null unique,
  capital text not null,
  extension number)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Simple Form (with References)</SectionTitle>
        <Text>
          Now, let's see an example on how to use simple references. Here we
          create a Form named Adresses with a parameter named country which
          references to the previously created form Country.
        </Text>
        <Text>
          Summarizing:
        </Text>
        <UnorderedList>
          <ListItem>New form named Address will be created</ListItem>
          <ListItem>
            Parameters:
            <UnorderedList styleType="circle">
              <ListItem>Street number</ListItem>
              <ListItem>Zip Code</ListItem>
              <ListItem>Country (references a country from the form Country)</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <CodeExample title="fql (create form)">{`\
create form Address (
  street_number number,
  zip_code number,
  country references Country.name)
`}
        </CodeExample>
        <Text>
          The following example will show us the magic of the references,
          first we create a form named Jobs that we will use as a reference
          later.
        </Text>
        <Text>
          Summarizing:
        </Text>
        <UnorderedList>
          <ListItem>New form named Jobs will be created</ListItem>
          <ListItem>
            Parameters:
            <UnorderedList styleType="circle">
              <ListItem>Name (unique and not null)</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <CodeExample title="fql (create form)">{`\
create form Jobs (
  name text not null unique)
`}
        </CodeExample>
        <Text>
          Here,
        </Text>
        <UnorderedList>
          <ListItem>New form named Persons will be created</ListItem>
          <ListItem>
            Parameters:
            <UnorderedList styleType="circle">
              <ListItem>Name (unique and not null)</ListItem>
              <ListItem>Age</ListItem>
              <ListItem>Address (references an address from the form Address)</ListItem>
              <ListItem>
                Jobs (references a job from the form Job with cardinality up
                to 3 jobs for 1 person)
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <CodeExample title="fql (create form)">{`\
create form Persons (
  name text not null unique,
  age number,
  address references Address.(street_number, zip_code, country),
  jobs references 1..3 Jobs.name unique)
`}
        </CodeExample>
        <Text>
          Indeed we saw how a Person can have an address and multiple jobs.
          Let's see one last example, where:
        </Text>
        <UnorderedList>
          <ListItem>New form named Company will be created</ListItem>
          <ListItem>
            Parameters:
            <UnorderedList styleType="circle">
              <ListItem>Name (unique and not null)</ListItem>
              <ListItem>Manager (references a totally unique Person) </ListItem>
              <ListItem>
                Employees (references some persons in one Company)
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <CodeExample title="fql (create form)">{`\
create form Company (
  name text not null unique,
  manager references 1 Persons.name totally unique,
  employees references 1..many Persons.name unique)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (create form)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Defining Forms</LinkToSection></ListItem>
        <ListItem><LinkToSection>Create Form grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Simple Form (no References)</LinkToSection></ListItem>
        <ListItem><LinkToSection>Simple Form (with References)</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default CreateForm;

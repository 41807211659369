import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const SkinConfigLayoutBox = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const handleChangelayout = (e) => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, layout: e.target.value },
    });
  };

  return (
    <div className="config-box col-xs-12 col-lg-6">
      <div className="item config-layout">
        <h3>LAYOUT:</h3>
        <div className="left">
          <label>
            <input
              type="radio"
              name="layout"
              value="boxed"
              checked={settings.theme.layout === "boxed"}
              onChange={handleChangelayout}
            />
            Boxed
          </label>
          <img
            src={require(`../../../sass/images/${settings.theme.skin}/layout-boxed.png`)}
            alt="layout-boxed"
            className={settings.theme.layout === "boxed" ? "selected" : ""}
          />
        </div>
        <span className="or">OR</span>
        <div className="right">
          <label>
            <input
              type="radio"
              name="layout"
              value="fluid"
              checked={settings.theme.layout === "fluid"}
              onChange={handleChangelayout}
            />
            Fluid
          </label>
          <img
            src={require(`../../../sass/images/${settings.theme.skin}/layout-fluid.png`)}
            alt="layout-fluid"
            className={settings.theme.layout === "fluid" ? "selected" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default SkinConfigLayoutBox;

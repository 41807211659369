import { useState, useEffect } from "react";
import "./TerminalSettings.css";

function TerminalSettings({ onClose, visible }) {
  const handleExitClick = () => {
    onClose();
  };
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleFullScreen = (event) => {
    setIsFullScreen(event.target.checked);
  };

  useEffect(() => {
    if (isFullScreen) {
      document.getElementsByClassName("components-container")[
        "react-console-emulator"
      ].className = "components-container-full";
    } else {
      const component = document.getElementsByClassName(
        "components-container-full"
      )["react-console-emulator"];
      if (component !== undefined) {
        component.className = "components-container";
      }
    }
  }, [isFullScreen]);

  return (
    <>
      {visible && (
        <>
          <div
            className="terminal-settings-overlay"
            onClick={handleExitClick}
          />
          <div className="terminal-settings">
            <button className="exit-button" onClick={handleExitClick}>
              X
            </button>
            <h2>Terminal Settings</h2>
            <label>
              <input
                type="checkbox"
                checked={isFullScreen}
                onChange={handleFullScreen}
              />
              Enable fullscreen
            </label>
          </div>
        </>
      )}
    </>
  );
}

export default TerminalSettings;

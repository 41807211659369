import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { GlobalContext } from "../../component/GlobalContext";
import {
  Card,
  CardHeader,
  CardBody,
  Stack,
  StackDivider,
  Editable,
  EditablePreview,
  EditableInput,
  Tooltip,
  UnorderedList,
  ListItem,
  Button,
  FormControl,
  FormErrorMessage,
  Box,
  Flex,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import DataItem from './CreateForm/DataItem';
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";

class DataRow {
  constructor({ name = '', type = 'text', id }) {
    this.id = id || Math.floor(Math.random() * 100);
    this.name = name;
    this.type = type;
    this.isError = false;
  }

  check() {
    this.isError = !!this.name;
    return this.isError;
  }
}

class SimpleDataRow extends DataRow {
  constructor(props) {
    super(props);
    const { isRequired = true, isUnique = false } = props;
    this.isRequired = isRequired;
    this.isUnique = isUnique;
  }

  toFql() {
    const { name, type, isRequired, isUnique } = this;
    return `${name} ${type}${isRequired ? ' not null' : ''}${isUnique ? ' unique' : ''}`;
  }
}

class ReferenceDataRow extends DataRow {
  constructor(props) {
    super(props);
    this.type = 'reference';
    const { referenceForm = '', referenceData = [], min = 0, max = 1, uniqueness = null } = props;
    this.referenceForm = referenceForm;
    this.referenceData = referenceData;
    this.min = min;
    this.max = max;
    this.uniqueness = uniqueness;
  }
  toFql() {
    const { name, referenceForm, referenceData, min, max, uniqueness } = this;
    const cardinality = min === null && max === null ? null
      : min === 0 && max === 1 ? null
      : max === null ? `${min}..many`
      : min === max ? `${min}`
      : `${min}..${max}`;
    const referenceTo = referenceData.length <= 1
      ? `${referenceForm}.${referenceData[0]}`
      : `${referenceForm}.(${referenceData.join(', ')})`;
    const uniquenessLabel = uniqueness === null ? null
      : (uniqueness === 'totally-unique' && Number.isInteger(min) && (max === null || (Number.isInteger(max) && 1 < max))) ? 'totally unique'
      : 'unique';
    return [name, 'references', cardinality, referenceTo, uniquenessLabel].filter(x => x !== null).join(' ');
  }
}

const makeDataRow = (props = {}) => {
  return props.type === 'reference'
    ? new ReferenceDataRow(props)
    : new SimpleDataRow(props);
};

const CreateForm = () => {
  const toast = useToast();
  const { userInfo, runCode } = useContext(GlobalContext);
  const [formName, setFormName] = useState('');
  const [dataRows, setDataRows] = useState([makeDataRow()]);
  const [isValidationError, setIsValidationError] = useState(false);
  const toFql = () => {
    const isDataError = dataRows.map(row => row.check()).findIndex(x => !!x);
    if (!formName || isDataError) {
      throw new Error('validation failed');
    }
    return `create form ${formName} (${dataRows.map(d => d.toFql()).join(', ')})`;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let code;
    try {
      code = toFql();
    } catch (e) {
      setIsValidationError(true);
      return;
    }
    setIsValidationError(false);
    console.log(code);
    if (!userInfo.token) {
      toast({
        title: 'Error',
        description: "Can't get a request token. Maybe server is down?",
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
      return;
    }
    const toastId = toast({
      title: 'In progress',
      description: 'Creating the form...',
      status: 'loading',
      position: 'bottom-right',
    });
    return runCode({ code, token: userInfo.token })
      .then((data) => {
        console.log(data);
        if (data.msg.includes('ERROR')) {
          toast.update(toastId, {
            status: 'error',
            title: 'Error',
            description: data.msg,
            isClosable: true,
          });
        }
        else {
          toast.update(toastId, {
            status: 'success',
            title: 'Successfully created',
            description: data.msg,
            isClosable: true,
          });
          setFormName('');
          setDataRows([makeDataRow()]);
        }
      })
      .catch((err) => {
        toast.update(toastId, {
          status: 'error',
          title: 'Error',
          description: err.toString(),
          isClosable: true,
        });
      });
  };
  return (
    <form onSubmit={onSubmit}>
      <Box>
        <Card my={8} bg="secondaryBg" color="text">
          <CardHeader px={8} pt={6} pb={4}>
            <FormControl isInvalid={isValidationError && !formName}>
              <Editable placeholder="Form Name" value={formName} startWithEditView={true} onChange={(value) => setFormName(() => value)} fontSize='xl' fontWeight='semibold'>
                <Tooltip label="Click to edit" shouldWrapChildren={true}>
                  <EditablePreview
                    _hover={{ background: 'component.background.200' }}
                    {...(formName
                      ? null
                      : { color: 'placeholder', opacity: 0.6, fontStyle: 'italic' })} />
                </Tooltip>
                <EditableInput color="text" />
              </Editable>
              <FormErrorMessage>Form name is required.</FormErrorMessage>
            </FormControl>
          </CardHeader>
          <CardBody pt={2}>
            <Stack
              as={UnorderedList} divider={<StackDivider />} spacing={4}>
              {dataRows.map((row, i) => (
                <ListItem mx={3} pl={1} key={`data-row-${row.id}`} color="gray.500">
                  <DataItem name={row.name} type={row.type || 'text'}
                    onChange={(params) => { setDataRows(dataRows.toSpliced(i, 1, makeDataRow(Object.assign({}, params, { id: row.id })))) }}
                    onRemove={() => setDataRows(dataRows.toSpliced(i, 1))}
                    enableValidationError={isValidationError}
                  />
                </ListItem>
              ))}
              <Button variant="link" w="auto" onClick={() => setDataRows(dataRows.concat(makeDataRow()))} leftIcon={<FA icon="plus" />}>Add a new data</Button>
            </Stack>
          </CardBody>
        </Card>
        <Flex>
          <Button variant="link" as={RouterLink} to="/old/presentation_server/home">
            <FA icon="arrow-left" />
            Back
          </Button>
          <Spacer />
          <Button type="submit" size="md">Create</Button>
        </Flex>
      </Box>
    </form>
  );
};

export default CreateForm;

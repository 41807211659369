import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { HStack, Box, Link, Image } from '@chakra-ui/react';
import styled from 'styled-components';

const FooterContainer = ({ children, ...props }) => (
  <HStack justify="center" p="20px" mt="60px" {...props}>
    {children}
  </HStack>
);

const Copyright = styled.span`
  font-size: small;
`;

const CompanyLogoImage = (props) => (
  <Image
    fit="contain"
    src="/images/company-logo-white.png"
    alt="Synchronit"
    h="30px"
    {...props}
  />
);

export const Footer = (props) => (
  <Box w="100%" pos="absolute" bottom="0" {...props}>
    <FooterContainer bgColor="layout.footerBg" color="layout.footerText">
      <Copyright>&copy; 2024 Synchronit</Copyright>
      <Link as={RouterLink} to="https://synchronit.com" variant="footer" pos="absolute" right={[5, 10, 30, 30]}>
        <CompanyLogoImage />
      </Link>
    </FooterContainer>
  </Box>
);

export default Footer;

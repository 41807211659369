import React from "react";
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import CodeBlock from "../atoms/CodeBlock";

const ExecutionLog = ({ code, msg, output, isSuccess, ...props }) => {
  const uuid = crypto.randomUUID();
  return (
    <Box key={`execution-log-${uuid}`} {...props}>
      <CodeBlock>
        {code}
      </CodeBlock>
      <Box ml="1rem" display={msg ? "" : "none"}>
        <HStack align="top">
          <Text as={Box} color="brand.500" display={isSuccess ? "" : "none"}>
            <FA icon={isSuccess ? "fa-check" : "fa-xmark"} />
          </Text>
          <Text as={Box} color="accent.red" display={isSuccess ? "none" : ""}>
            <FA icon="fa-xmark" />
          </Text>
          <Text as={Box} color="accent.darkgray">
            {msg}
          </Text>
        </HStack>
      </Box>
      {output && output.length && (
        <Box maxW="100%" overflowX="auto">
          <Table variant="striped">
            <Thead>
              <Tr>
                {Object.keys(output[0]).map((k, i) => (
                  <Th key={`execution-log-${uuid}-th-${i}`} textAlign="center">{k}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {output.map((output, j) => (
                <Tr key={`execution-log-${uuid}-td-${j}`}>
                  {Object.keys(output).map(key => (
                    <Td key={`execution-log-${uuid}-td-${j}-${key}`} textAlign="center">{JSON.stringify(output[key])}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default ExecutionLog;

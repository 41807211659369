import { Box } from "@chakra-ui/react";
import styled from "styled-components";
import theme from "../../theme";

const CodeBlock = styled(Box)`
  background-color: ${theme.colors.accent.darkgray};
  color: ${theme.colors.accent.white};
  white-space: pre-wrap;
  padding: 20px 24px;
  margin: 20px auto;
  border-radius: 6px;
  font-family: monospace;
  font-size: 0.9rem;
`;

export default CodeBlock;

import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const RemoveForm = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Remove Forms</Heading>
        <Text>
          After creating a lot of forms or just a few ones we might realize we don't
          need some of them, or maybe we just made a mistake creating one of them.
          In that case we can delete those forms by executing the command "remove
          forms". Removing a form will not be allowed if any other form references
          any parameter of the one we are about to delete.
        </Text>
        <Text>
          WARNING!!
          <br /> Removing a form will not be reversible and the data will be
          permanently lost.
        </Text>
      </section>
      <section>
        <SectionTitle>Remove Forms grammar</SectionTitle>
        <Text>
          Let's check the grammar for the REMOVE FORM command:
        </Text>
        <CodeBlock>{`\
remove-forms ::=
  "remove" "forms" id
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this command that will allow us to demonstrate the use of
          the "remove forms" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Notes (
  title text,
  body text,
  author text,
  started_date date)

create form Voice_Notes (
  title text,
  audio file,
  author text,
  started_date date)

create form Calendar_Events (
  name text not null,
  description text,
  start_date date,
  end_date date)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Removing a Form</SectionTitle>
        <Text>
          Deleting a form is as simple as executing the example code, but it
          is very dangerous and irreversible. Be sure that what you want to
          delete is not longer required. Example code below:
        </Text>
        <CodeExample title="fql (remove forms)">{`\
remove forms Notes
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Removing some forms</SectionTitle>
        <Text>
          Deleting some forms at once is also possible. Be sure that what you
          want to delete is not longer required. Example code below:
        </Text>
        <CodeExample title="fql (remove forms)">{`\
remove forms Voice_Notes Calendar_Events
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (remove forms)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Remove Forms grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Removing a Form</LinkToSection></ListItem>
        <ListItem><LinkToSection>Removing some forms</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default RemoveForm;

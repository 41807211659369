import { useState } from "react";
import { Box } from "@chakra-ui/react";
import Navbar from "../new_component/molecules/Navbar";
import Footer from "../new_component/molecules/Footer";
import styled from "styled-components";
import { Login } from "../pages/Login";

/* XXX: To overwrite the base style of FQLConsole
 *   It should be done in Chakra UI theme. */
const Page = styled(Box)`
  font-family: "Mulish", sans-serif;
  color: #2d2d2d;
  position: relative;
  min-height: 100vh;
`;

export const DefaultLayout = ({
  navbarSize = "sm",
  navbarVariant = "normal",
  navbarLeftButtons,
  withFooter = true,
  baseStyleWorkaround = false,
  children,
}) => {
  const [showLogin, setShowLogin] = useState(false);

  return (
    <>
    {/* XXX: Base style uses !important to add horizontal paddings */}
    <Page bg="bg" mx={baseStyleWorkaround ? -35 : 0}>
      <Navbar
        size={navbarSize}
        variant={navbarVariant}
        leftButtons={navbarLeftButtons}
        setShowLogin={setShowLogin}
      />
      <Box pt={navbarSize === "sm" ? 70 : 100}>{children}</Box>
      <Footer display={withFooter ? "" : "none"} />
      <Login showLogin={showLogin} setShowLogin={setShowLogin} />
    </Page>
    </>
  );
};

export default DefaultLayout;

import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const AmazingBooks = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Amazing Books Store</Heading>
        <Text>
          After learning the basics of FQL you might ask: is that all?
        </Text>
        <Text>
          Well, obviously not.
        </Text>
        <Text>
          This section is dedicated to show more advanced examples that will help
          you realize how powerful FQL can be and still, so simple, friendly, and
          concise. Creating some forms, adding values, setting rules, actions, and
          retrieving info with clear constraints and conditions in a manner that is
          readable for the non-technical user. Yes, it may seem like magic ;)
        </Text>
        <Text>
          Let's have a look!
        </Text>
      </section>
      <section>
        <SectionTitle>Introducing the store</SectionTitle>
        <Text>
          Amazing Books sells books from different authors around the world.
        </Text>
        <Text>
          We will see how FQL can help in the different searches that users
          can perform, how an order is entered and stored in the system and
          how we can then run some queries also about the orders already
          entered.
        </Text>
        <Text>
          Let's now create the main Forms we will need to store information
          about our books store: Countries, Cities, Addresses, Customers,
          Authors.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form Countries (
  name text not null)

create form Cities (
  name text not null,
  country references Countries.name,
  capital boolean)

create form Addresses (
  street text,
  house_number number,
  city references Cities.(name, country))

create form Customers (
  name text not null,
  last_name text,
  address references Addresses.(street, house_number, city.name))

create form Authors (
  name text not null,
  last_name text not null,
  address references Addresses.(street, house_number, city.name))
`}
        </CodeExample>
        <Text>
          For storing the info about books and the orders generated we will
          use the following forms: Books Categories, Keywords, Books and
          Orders.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form BooksCategories (
  name text not null unique)

create form Keywords (
  name text not null unique)

create form Books (
  title text not null unique,
  isbn text not null unique,
  author references Authors.(
    name,
    last_name,
    address.city.name,
    address.city.country.name
  ),
  category references 1..1 BooksCategories.name,
  price number,
  keywords references 1..many Keywords.name unique)

create form Orders (
  customer references Customers.(name, last_name),
  books references 1..many Books.(title, author.name),
  total_amount number)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Adding "Amazing Books"</SectionTitle>
        <Text>
          It's time now to add some information to all those forms. Let's
          start with the countries, cities and addresses.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Countries values ("Cuba")
create new Countries values ("Japan")
create new Countries values ("Swiss")
create new Countries values ("Uruguay")
`}
        </CodeExample>
        <CodeExample title="fql (create new)" multiline>{`\
create new Cities values ("Havana", "Cuba", true)
create new Cities values ("Kyoto", "Japan", false)
create new Cities values ("Montevideo", "Uruguay", true)
create new Cities values ("Bern", "Swiss", true)
create new Cities values ("Zurich", "Swiss", false)
`}
        </CodeExample>
        <CodeExample title="fql (create new)" multiline>{`\
create new Addresses values ("Carlos III", 620, "Havana")
create new Addresses values ("G St.", 255, "Havana")
create new Addresses values ("Paula St.", 28, "Havana")
create new Addresses values ("Bahnhofstrasse", 12, "Zurich")
create new Addresses values ("Teramachi", 30, "Kyoto")
`}
        </CodeExample>
        <Text>
          Now, let's add some authors, customers, keywords tags for the books,
          and a couple of books to our forms. Starting with the authors and
          costumers.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Authors values ("Cirilo","Villaverde", "Carlos III")
create new Authors values ("Haruki", "Murakami", "Teramachi")
create new Authors values ("Jose", "Marti", ("Paula St.", 28, "Havana"))

create new Customers values ("John", "Doe", ("Bahnhofstrasse", 12, "Zurich"))
create new Customers values ("Jane", "Doe", ("G St.", 255, "Havana"))
`}
        </CodeExample>
        <Text>
          The books in the store will have tags (also know as keywords), that
          will help the user to identify its content. For example:
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Keywords values ("interesting")
create new Keywords values ("boring")
create new Keywords values ("excellent")
create new Keywords values ("mystery")
create new Keywords values ("aliens")
create new Keywords values ("fiction")
create new Keywords values ("novel")
`}
        </CodeExample>
        <Text>
          It's time to add some categories for the books and finally add some
          books to our forms.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new BooksCategories values ("fiction")
create new BooksCategories values ("horror")
create new BooksCategories values ("humor")
create new BooksCategories values ("classics")
create new BooksCategories values ("drama")
`}
        </CodeExample>
        <CodeExample title="fql (create new)">{`\
create new Books values (
  "La Loma del Angel",
  "978-987-958-6747",
  ("Cirilo", "Villaverde"),
  "drama",
  18,
  ("interesting", "excellent"))

create new Books values (
  "Tokyo Blues",
  "978-848-383-5524",
  ("Haruki", "Murakami"),
  "drama",
  15,
  ("interesting", "mystery", "novel"))

create new Books values (
  "La Edad de Oro",
  "978-959-271-2003",
  ("Jose", "Marti"),
  "classics",
  10,
  ("interesting", "fiction", "excellent"))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Placing an order</SectionTitle>
        <Text>
          Well, the info is all in. Now let's suppose a customer wants to buy
          a book. It cannot be simplier than placing a new order translating
          it to FQL as the ability to create a new entry to the form Orders.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create new Orders values (("John", "Doe"), "La Loma del Angel", 18)
create new Orders values (("Jane", "Doe"), ("Tokyo Blues", "La Edad de Oro"), 16)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Getting faster and simplier</SectionTitle>
        <Text>
          If we have some experience using SQL (or similar), we must know that
          retrieving information from a table (or some), using some logical
          expressions and joins might be tricky and non-friendly. Let's check
          some examples.
        </Text>
        <Text>
          Here we want to retrieve all the authors that are Cubans.
        </Text>
        <CodeExample title="fql (get)">{`\
get Authors (name, city.name)
  with country.name = "Cuba"
`}
        </CodeExample>
        <Text>
          The following represents the SQL code necessary for making the
          previous query.
        </Text>
        <CodeBlock>{`\
SELECT
  authors.name AS name,
  authors_address_city.name AS city"."name
FROM authors
LEFT JOIN addresses AS authors_address
  ON (authors.address = authors_address.fql_id)
LEFT JOIN cities AS authors_address_city
  ON (authors_address.city = authors_address_city.fql_id)
LEFT JOIN countries AS authors_address_city_country
  ON (authors_address_city.country = authors_address_city_country.fql_id)
WHERE (authors_address_city_country.name = "Cuba"
`}
        </CodeBlock>
        <Text>
          If we want to obtain all the orders that contains books with Cuban
          authors it will be so simple as:
        </Text>
        <CodeExample title="fql (get)">{`\
get Orders (customer.name, books.title, books.author.name)
  with author.address.city.country.name = "Cuba"
`}
        </CodeExample>
        <Text>
          Not so simple in a SQL query:
        </Text>
        <CodeBlock>{`\
SELECT
  orders_customer.name AS customer"."name,
  orders_books.title AS books"."title,
  orders_books_author.name AS books"."author"."name
FROM orders
LEFT JOIN customers AS orders_customer
  ON (orders.customer = orders_customer.fql_id)
LEFT JOIN orders_books AS orders_orders_books
  ON (orders.fql_id = orders_orders_books.orders)
LEFT JOIN books AS orders_books
  ON (orders_orders_books.books = orders_books.fql_id)
LEFT JOIN authors AS orders_books_author
  ON (orders_books.author = orders_books_author.fql_id)
LEFT JOIN addresses AS orders_books_author_address
  ON (orders_books_author.address = orders_books_author_address.fql_id)
LEFT JOIN cities AS orders_books_author_address_city
  ON (orders_books_author_address.city = orders_books_author_address_city.fql_id)
LEFT JOIN countries AS orders_books_author_address_city_country
  ON (orders_books_author_address_city.country = orders_books_author_address_city_country.fql_id)
WHERE (orders_books_author_address_city_country.name = "Cuba"
`}
        </CodeBlock>
        <Text>
        </Text>
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Introducing the store</LinkToSection></ListItem>
        <ListItem><LinkToSection>Adding "Amazing Books"</LinkToSection></ListItem>
        <ListItem><LinkToSection>Placing an order</LinkToSection></ListItem>
        <ListItem><LinkToSection>Getting faster and simplier</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default AmazingBooks;

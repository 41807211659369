import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const IsUsedBy = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Is Used By</Heading>
        <Text>
        </Text>
      </section>
      <section>
        <SectionTitle>Is Used By Grammar</SectionTitle>
        <Text>
          Let's check the grammar for the IS USED BY command:
        </Text>
        <CodeBlock>{`\
is-used-by ::=
  is-used-by-header is-used-by-in-groups-of;

is-used-by-header ::=
  id "is" "used" "by" id is-used-by-through;

is-used-by-through ::=
  "through" id "." "(" id "," id "," "id" ")";

is-used-by-in-groups-of ::=
  "in" "groups" "." "(" id ")" "of" group-value ".." group-value;

group-value ::=
  number | id "." id;
`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's run this commands that will allow us to demonstrate the use of
          the "is used by" command.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form HOTEL_ROOMS (
  room_number number not null unique,
  floor number not null,
  single_beds number not null,
  double_beds number not null,
  capacity number)

create form GUESTS (
  guest_number number not null unique,
  name text not null,
  phone text not null)

create form RESERVATIONS (
  guest references GUESTS.name,
  capacity number not null,
  single_beds number,
  double_beds number,
  from_date date,
  until_date date,
  room_assigned references HOTEL_ROOMS.room_number)
`}
        </CodeExample>
        <Text>
          Now, let's add some cases.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create HOTEL_ROOMS (1000, 1, 3, 0, 3)
create HOTEL_ROOMS (1001, 1, 1, 1, 2)
create HOTEL_ROOMS (1002, 2, 3, 1, 4)
create HOTEL_ROOMS (1003, 2, 4, 0, 4)
create HOTEL_ROOMS (1004, 3, 1, 5, 6)

create GUESTS (10, "Leandro", "+LLLL126589")
create GUESTS (11, "Felix", "+FFFF346589")
create GUESTS (12, "Eitaro", "+EEEE14354589")
create GUESTS (13, "Fernando", "+YYYY9136546589")
create GUESTS (14, "Alvaro", "+AAAA129430389")
create GUESTS (15, "Carlos", "+CCCC126923289")
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Use the Is Used By command</SectionTitle>
        <Text>
          Suppose we want to define a function that raises an error depending
          on the user's age.
        </Text>
        <CodeExample title="fql (is used by)">{`\
HOTEL_ROOMS is used by GUESTS
  through RESERVATIONS.(room_assigned, from_date, until_date)
  in groups.(capacity) of 1..HOTEL_ROOMS.capacity
`}
        </CodeExample>
        <Text>
          Now, try to add a couple of RESERVATIONS and let's see what happens.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create RESERVATIONS ("Felix", 2, 3, 0, "07-10-2024", "07-17-2024", 1000)
create RESERVATIONS ("Leandro", 4, 0, 4, "06-08-2024", "06-15-2024", 1003)
create RESERVATIONS ("Fernando", 1, 3, 0, "06-28-2024", "07-05-2024", 1000)
create RESERVATIONS ("Eitaro", 2, 3, 0, "07-06-2024", "07-13-2024", 1000)
create RESERVATIONS ("Alvaro", 2, 3, 0, "07-11-2024", "07-13-2024", 1000)
create RESERVATIONS ("Carlos", 11, 3, 0, "07-20-2024", "07-24-2024", 1000)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (is used by)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Is Used By grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Use the Is Used By command</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default IsUsedBy;

import React from "react";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import { LinkToSection, SectionIndex, SectionTitle } from "../../new_component/organisms/SectionIndex";
import { Heading, ListItem, Text, UnorderedList} from "@chakra-ui/react";
import CodeExample from "../../new_component/organisms/CodeExample";

const Organizations = () => (
  <WikiArticle>
    <WikiArticleMain>
    <section>
        <Heading as="h2">Organizations</Heading>
        <Text>
        In our system, organizations are designed to allow users to collaborate by forming groups and hierarchies within each organization. This structure helps manage tasks and responsibilities more effectively.
        </Text>
    </section>

    <section>
      <SectionTitle>Groups and Roles</SectionTitle>
      <UnorderedList>
        <ListItem><Text as='b'>Groups: </Text>Users within an organization can be organized into groups, allowing for efficient collaboration on specific projects or tasks.</ListItem>
        <ListItem><Text as='b'>Roles and Permissions: </Text>Each user can have specific roles that determine their level of access and control within the organization. These roles help define what actions a user can perform, such as managing teams, editing content, or accessing restricted data.</ListItem>
      </UnorderedList>
    </section>

    <section>
      <SectionTitle>Customizable Permissions</SectionTitle>
        <Text>The system provides customizable permissions that enable organizations to define what each user or group can do:</Text>
        <UnorderedList>
          <ListItem><Text as='b'>Form Permissions: </Text>Control who can create, edit, and manage forms within the organization.</ListItem>
          <ListItem><Text as='b'>Group Permissions: </Text>Manage which groups users can join, what data they can access, and what actions they can perform.</ListItem>
          <ListItem><Text as='b'>Organization Permissions: </Text>These govern high-level actions like adding or removing members, managing settings, and overseeing the entire organization.</ListItem>
        </UnorderedList>
    </section>

    <section>
      <SectionTitle>Personal Workspaces and Default Organization</SectionTitle>
      <Text>Every user can create as many organizations as they like, each serving as a separate space for managing teams and projects. By default, users are always part of an organization called "Main," which represents their personal workspace. This is their default organization unless they choose to switch to another.</Text>
    </section>

    <section>
      <SectionTitle>User Registration Requirement</SectionTitle>
      <Text>To access the commands and fully participate in creating organizations, managing groups, or adjusting permissions, users must be registered in the system. Only registered users can leverage the full functionality of the platform.</Text>
    </section>

    <section>
      <SectionTitle>Commands</SectionTitle>
      <Text>Users can interact with the system by using specific commands to manage their organizations, groups, and permissions. Below are the key commands available for managing organizations:</Text>

      <SectionTitle as='h4'>Command List</SectionTitle>
      <UnorderedList>
          <ListItem><Text as='b'>Create a New Organization: </Text></ListItem>
          <Text>To create a new organization, use the following command:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            CREATE ORGANIZATION organization_name
          </CodeExample>
          <Text>Example:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            CREATE ORGANIZATION OpenIA
          </CodeExample>

          <ListItem><Text as='b'>View Your Organizations:</Text></ListItem>
          <Text>To view the organizations you are part of, either as the owner or a member, use this command:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            SHOW ORGANIZATIONS
          </CodeExample>

          <ListItem><Text as='b'>Set Default Organization:</Text></ListItem>
          <Text>To change your default organization (the one you are currently working in), use this command:</Text>
          <CodeExample margin={0} title="FQL" run={false}>
            SET ORGANIZATION organization_name
          </CodeExample>
        </UnorderedList>
    </section>

    <section>
      <SectionTitle>Example: Creating and Switching Between Organizations</SectionTitle>
      <Text>Let’s walk through a scenario where a user creates an organization, works within it, and switches between organizations:</Text>

      <UnorderedList>
          <ListItem>The user creates a new organization called "OpenIA":</ListItem>
          <CodeExample margin={0} title="FQL" run={false}>
            CREATE ORGANIZATION OpenIA
          </CodeExample>

          <ListItem>After creating the organization, the user creates a form for inventory:</ListItem>
          <CodeExample margin={0} title="FQL" run={false} multiline>
            {`CREATE FORM INVENTORY(
  NAME TEXT NOT NULL UNIQUE,
  QUANTITY NUMBER
)`}
          </CodeExample>

          <ListItem>The user decides to switch to the new organization:</ListItem>
          <CodeExample margin={0} title="FQL" run={false}>
            SET ORGANIZATION OpenIA
          </CodeExample>

          <ListItem>Now, the user tries to view the forms within this new organization:</ListItem>
          <CodeExample margin={0} title="FQL" run={false}>
            SHOW FORMS
          </CodeExample>

          <ListItem>The user notices that the forms created earlier are not available in the new organization. This is because forms are stored within the organization where they were created. To access those forms, the user needs to switch back</ListItem>
          <CodeExample margin={0} title="FQL" run={false}>
            SET ORGANIZATION Main
          </CodeExample>
        </UnorderedList>
    </section>
    </WikiArticleMain>

    

    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Introduction</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Organization Commands</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Grammar</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
    
  </WikiArticle>
)

export default Organizations
import { useContext, useState } from "react";
import { GlobalContext } from "../../GlobalContext";

const OrderForms = () => {
  const { forms, setLoading } = useContext(GlobalContext);
  const [openOrder, setOpenOrder] = useState(false);
  const [actualOrder, setActualOrder] = useState("A-Z");

  const toggleOpenCloseOrder = () => {
    setOpenOrder(!openOrder);
  };

  const handleOrderForms = (e) => {
    setLoading(true);
    setActualOrder(e.target.textContent);

    switch (e.target.textContent) {
      case "A-Z":
        forms.current.sort((a, b) => a["FORMS"].localeCompare(b["FORMS"]));
        break;
      case "Z-A":
        forms.current.sort((a, b) => -a["FORMS"].localeCompare(b["FORMS"]));
        break;
      default:
        break;
    }
    setOpenOrder(false);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  return (
    <div className={`order-forms dropdown ${openOrder ? "open" : ""}`}>
      <span className="">ORDER: </span>
      <a
        className="dropdown-toggle"
        title=""
        data-toggle="dropdown"
        style={{ cursor: "pointer" }}
        onClick={toggleOpenCloseOrder}
      >
        {actualOrder}
      </a>
      <ul className="dropdown-menu">
        <li onClick={handleOrderForms}>A-Z</li>
        <li onClick={handleOrderForms}>Z-A</li>
        {/* <li onClick={handleOrderForms}>Recent</li> */}
        {/* <li onClick={handleOrderForms}>Older</li> */}
      </ul>
    </div>
  );
};

export default OrderForms;

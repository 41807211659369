import { useContext, useEffect, useState } from "react";
import ModalNotifications from "./ModalNotifications";
import ModalDelete from "./ModalDelete";
import ModalMassDelete from "./ModalMassDelete";
import ModalResetStyles from "./ModalResetStyles";
import { GlobalContext } from "../../GlobalContext";
import ModalGet from "./ModalGet";
import ModalError from "./ModalError";
import { Resizable } from "re-resizable";
import ModalSignUp from "./ModalSignUp";

const MyModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { settings, setSettings } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      settings.modals.show && setShowModal(true);
    }, 100);
  }, [settings.modals.show, settings]);

  const handleCloseModal = (e) => {
    if (e.target.id === "icon-close" || e.target.id === settings.modals.id) {
      setShowModal(false);
      setTimeout(() => {
        setSettings({
          ...settings,
          modals: { ...settings.modals, show: false },
        });
      }, 100);
    }
  };

  const getModal = () => {
    switch (settings.modals.id) {
      case "modal-notifications":
        return <ModalNotifications />;
      case "modal-delete":
        return <ModalDelete onClose={handleCloseModal} />;
      case "modal-mass-delete":
        return <ModalMassDelete />;
      case "modal-reset-styles":
        return <ModalResetStyles />;
      case "modal-get":
        return <ModalGet setShowModal={setShowModal} />;
      case "modal-error":
        return <ModalError />;
      case "modal-login":
        return <ModalSignUp />;
      default:
        break;
    }
  };

  return (
    <div
      className={`modal fade${showModal ? " in" : ""}${
        settings.modals.show ? " show" : ""
      }`}
      id={settings.modals.id}
      onMouseDown={handleCloseModal}
    >
      <button
        type="button"
        className="icon-close"
        id="icon-close"
        onClick={handleCloseModal}
      ></button>
      <Resizable
        className="modal-dialog"
        minHeight={"fit-content"}
        maxWidth={"100vw"}
        maxHeight={"85vh"}
        defaultSize={{ height: "fit-content", width: "45vw" }}
      >
        <div className="modal-content">
          <span className={settings.modals.icon}></span>
          <div className="modal-header">
            <h4 className="modal-title">{settings.modals.title}</h4>
          </div>
          <div className="modal-body">{getModal()}</div>
        </div>
      </Resizable>
    </div>
  );
};

export default MyModal;

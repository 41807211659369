import { useContext } from "react";
import {
  useLocation,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
  ChakraProvider,
  ColorModeScript,
} from "@chakra-ui/react";

import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import UserHome from "./pages/UserHome";
import Docs from "./pages/Docs";
import DocsIndex from "./pages/DocsIndex";
import Wiki from "./pages/Wiki";
import Test from "./pages/Test";
import MyTerminal from "./pages/Terminal";
import Error from "./pages/Error";
import ActivateAccount from "./pages/ActivateAccount";
import { ActivateEmail } from "./pages/Login";
import { GlobalContext } from "./component/GlobalContext";
import PresentationServer from "./pages/PresentationServer";
import NewPresentationServer from "./pages/NewPresentationServer";
import DefaultLayout from "./layouts/default";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faArrowLeft,
  faEnvelope,
  faRocket,
  faBook,
  faLaptop,
  faDiagramProject,
  faRotateRight,
  faBars,
  faCheck,
  faXmark,
  faAsterisk,
  faU,
  faPlus,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy, faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import { defaultTheme, futuristTheme } from "./theme";

import "./App.css";
import AutomatedTests from "./pages/AutomatedTests";

library.add(
  faArrowRight,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faArrowLeft,
  faEnvelope,
  faRocket,
  faBook,
  faLaptop,
  faDiagramProject,
  faRotateRight,
  faCopy,
  faCirclePlay,
  faBars,
  faCheck,
  faXmark,
  faAsterisk,
  faU,
  faPlus,
  faPalette,
);

const themes = {
  clear: defaultTheme,
  futurist: futuristTheme,
};

// NOTE: Wrap only specific page elements with ChakraProvider
//   to avoid unintended effects to existing pages.
const Chakra = ({ theme, props }) => {
  const { settings } = useContext(GlobalContext);
  const activeTheme = theme || themes[settings.theme.skin];
  return (
    <>
      <ChakraProvider
        theme={activeTheme}
        toastOptions={{ defaultOptions: { position: "bottom-right" } }}
        {...props}
      >
        <ColorModeScript initialColorMode={activeTheme.config.initialColorMode} />
        <Outlet />
      </ChakraProvider>
      <ScrollRestoration />
    </>
  );
};

const Original = () => {
  const { settings } = useContext(GlobalContext);
  const location = useLocation();

  return (
    <div
      id="base"
      className={`${
        location.pathname !== "presentation_server"
          ? `base
          ${settings.theme.skin}
          ${settings.theme.view}
          ${settings.theme.topbar}
          ${settings.theme.layout}
          ${settings.theme.degraded}
          ${settings.theme.workspace}
          ${settings.theme.mainColor}
          ${settings.theme.secondColor}`
          : location.pathname === "/test"
          ? "test-page"
          : "super"
      }`}
    >
      <AnimatePresence>
        <Outlet />
      </AnimatePresence>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/old",
    element: <Original />,
    children: [
      {
        path: "/old",
        element: <Home />,
      },
      {
        path: "presentation_server",
        element: <PresentationServer />,
        children: [
          "home",
          "create_new/:form_name",
          "skins/config",
          "skins",
          "create_form",
        ].map((path) => ({ path })),
      },
      {
        path: "wiki/:componentPath",
        element: <Wiki />,
      },
      {
        path: "test",
        element: <Test />,
      },
      {
        path: "activate_account/:email/:activation_token",
        element: <ActivateAccount />,
      },
      {
        path: "*",
        element: <Error />,
      },
    ],
  },
  {
    path: "/",
    element: <Chakra theme={defaultTheme} />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/home",
        element: <UserHome />,
      },
      {
        path: "/docs",
        element: <DocsIndex />,
      },
      {
        path: "/fqlconsole",
        element: <MyTerminal />,
      },
      {
        path: "/docs/:section",
        element: <Docs />,
      },
      {
        path: "/automated_tests",
        element: <AutomatedTests />,
      },
      {
        path: "activate_account/:email/:activation_token",
        element: <ActivateEmail />,
      },
    ],
  },
  {
    path: "/presentation_server",
    element: <Chakra />,
    children: [
      {
        path: "create_form",
        element: <NewPresentationServer />,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

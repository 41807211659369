import { apiBaseURL } from "./config";

export const getToken = async () =>
  await fetch(`${apiBaseURL}/new_guest_token`, {
    method: "POST",
  })
    .then(async (res) => {
      const data = await res.json();
      return data.token;
    })
    .catch((err) => console.error(err));

export const runCommands = async ({ commands, token }) =>
  await fetch(`${apiBaseURL}/run_commands`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code: commands, token: token }),
  })
    .then(async (res) => res.json())
    .catch((err) => {
      console.log(err);
    });

export const resetSchema = async ({ token }) =>
  await fetch(`${apiBaseURL}/reset_schema`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));

import React from "react";
import { Heading, Text, ListItem } from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeExample from "../../new_component/organisms/CodeExample";

const AdvancedExamples = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Advanced examples</Heading>
        <Text>
          After learning the basics of FQL you might ask: is that all?
        </Text>
        <Text>Well, obviously not.</Text>
        <Text>
          This section is dedicated to show more advanced examples that will
          help you realize how powerful FQL can be and still, so simple,
          friendly, and concise. Creating some forms, adding values, setting
          rules, actions, and retrieving info with clear constraints and
          conditions in a manner that is readable for the non-technical user.
          Yes, it may seem like magic ;)
        </Text>
        <Text>Let's have a look!</Text>
      </section>
      <section>
        <SectionTitle>Setting the basis</SectionTitle>
        <Text>
          Let's create some Forms that will help us out showing the strength of
          FQL:
        </Text>
        <Text></Text>
        <CodeExample title="fql (create form)">
          {`\
create form Nations (
  name text not null unique)

create form Employments (
  name text,
  medium_salary number)

create form Users (
  username text not null unique,
  age number,
  jobs references 0..3 Employments.name,
  country references Nations.name)

create form Website (
  name text not null,
  url text not null,
  users references 1..many Users.(username, age, jobs.name, country.name))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>More basis...</SectionTitle>
        <Text>
          Let's add some info to the Form Nations. It will be useful later.
        </Text>
        <CodeExample title="fql (create new)" multiline>
          {`\
create new Nations values ('Cuba')
create new Nations values ('Japan')
create new Nations values ('Uruguay')
create new Nations values ('Argentina')
`}
        </CodeExample>
        <Text>Let's add some cases to the Form Jobs.</Text>
        <CodeExample title="fql (create new)" multiline>
          {`\
create new Employments values ('Doctor', 4000)
create new Employments values ('Scientist', 3500)
create new Employments values ('Musician', 3000)
create new Employments values ('Actor', 2800)
create new Employments values ('Engineer', 2100)
create new Employments values ('Freelancer', 900)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>The magic begins</SectionTitle>
        <Text>
          As we saw before, rules are a distinctive and a powerful aspect of
          FQL, where its full potential can be better appreciated. Let's define
          one validation.
        </Text>
        <CodeExample title="fql (rules)">
          {`\
define rule NO_TEENS_WITH_JOBS:
  on Users when not null (jobs.name)
  and age < 18 is invalid
`}
        </CodeExample>
        <Text>
          Let's see what happens now when we start inserting new cases on the
          "ruled" Form:
        </Text>
        <CodeExample title="fql (create new)" multiline>
          {`\
create new Users values ('Susane', 24, 'Engineer', 'Cuba')
create new Users values ('Anne', 12, ('Doctor', 'Actor'), 'Argentina')
create new Users values ('Anne', 12, null, 'Argentina')
create new Users values ('John', 42, ('Doctor', 'Actor', 'Freelancer'), 'Uruguay')
create new Users values ('Frida', 35, 'Scientist', 'Argentina')
create new Users values ('Albert', 26, null, 'Japan')
`}
        </CodeExample>
        <Text>
          As expected, the error is thrown when we try to create the entry Anne,
          because it is and Invalid case according to the previously defined
          rule.
        </Text>
      </section>
      <section>
        <SectionTitle>Defining some rules</SectionTitle>
        <Text>Now, it's time to define some new rules. Check it out:</Text>
        <CodeExample title="fql (rules)">
          {`\
define rule NO_CUBAN_PEOPLE:
  on Website when country.name = 'Cuba' is invalid

define rule NO_TEENS_IN_ADULTS_WEBSITE:
  on Website when name equal 'adults'
  and age between 1 and 18 is invalid

define rule NO_DOCTORS_IN_HEAVEN_WEBSITE:
  on Website when name equal 'heaven'
  and jobs.name equal 'Doctor' is invalid
`}
        </CodeExample>
        <Text>Let's add some entries to the forms and see what happens:</Text>
        <CodeExample title="fql (create new)" multiline>
          {`\
create new Website values ('friends', 'friends.com', 'Susane')
create new Website values ('google' , 'google.com' , ('Anne', 'John', 'Frida'))
create new Website values ('adults' , 'adults.com' , ('Anne', 'Albert'))
create new Website values ('heaven' , 'heaven.com' , ('John', 'Frida', 'Albert'))
`}
        </CodeExample>
        <Text>
          As expected, the error is thrown when we try to create the entries
          "friends.com", "adults.com" and "heaven.com", because they aren't
          legal according to the rules defined.
        </Text>
      </section>
      <section>
        <SectionTitle>Getting faster and simplier</SectionTitle>
        <Text>
          If we have some experience using SQL (or similar), we must know that
          retrieving information from a table (or some), using some logical
          expressions and joins might be tricky and non-friendly.
        </Text>
        <CodeExample title="fql (get)">
          {`\
get Website (jobs.name, country.name)
  with (age between 5 and 27)
  and name = 'google'
`}
        </CodeExample>
        <Text>Let's check another example:</Text>
        <CodeExample title="fql (get)">
          {`\
get Website (users.username, users.age)
  with jobs.name <> 'Musician'
  or country.name = 'Argentina'
`}
        </CodeExample>
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Setting the basis</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>More basis...</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>The magic begins</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Defining some rules</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Getting faster and simplier</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default AdvancedExamples;

import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../GlobalContext";
import emailjs from "emailjs-com";
import bcrypt from "bcryptjs/dist/bcrypt";
import Cookies from "js-cookie";
import { apiBaseURL, hostBaseURL } from "../../../config";
import { AnimatePresence, motion } from "framer-motion";

import hidePasswordIcon from "../../../resources/hidePass.png";
import showPasswordIcon from "../../../resources/showPass.png";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [signing, setSigning] = useState(false);
  const [signed, setSigned] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (signing) {
      const interval = setInterval(() => {
        setCount((count) => (count < 3 ? count + 1 : 0));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [signing]);

  const dots = ".".repeat(count);
  const signingText = signing ? `Signing${dots}` : "Sign up";

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    e.target.disabled = true;
    setSigning(true);

    await fetch(`${apiBaseURL}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Signed up sucessfully") {
          const activation_token = data.activation_token;
          emailjs
            .send(
              "default_service",
              "template_r88w93l",
              {
                to_name: "",
                to_email: email,
                token: activation_token,
                host: hostBaseURL,
              },
              "HOOQ1w8YQTFxU9xny"
            )
            .then((response) => {
              setSigning(false);
              setSigned(true);
              console.log(
                "MAILED SUCCESSFULLY!",
                response.status,
                response.text
              );
            })
            .catch((err) => {
              setSigning(false);
              e.target.disabled = false;
              alert(
                "Failed to send email! Check your connection and try again\n",
                err
              );
            });
        } else {
          alert(data.msg);
          setSigning(false);
          e.target.disabled = false;
        }
      })
      .catch((err) => {
        alert("Failed to connect to backend engine\n", err);
        setSigning(false);
        e.target.disabled = false;
      });
  };

  return (
    <>
      {signed ? (
        <div>
          <h4 className="title-login">You've signed up!</h4>
          <h4 className="title-login"> Thanks for registering! </h4>
          <h4 className="subtitle-login">
            Verify your email inbox to activate your account!
          </h4>
        </div>
      ) : (
        <div>
          <h4 className="title-login">Sign up!</h4>
          <form>
            <div>
              <label className="label-login" htmlFor="email">
                Email:
              </label>
              <br />
              <input
                className="input-login"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <button className="btn btn-primary" onClick={handleRegister}>
              {signingText}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

const Login = () => {
  const { userInfo, setUserInfo, setLoggedIn, setSettings, settings } =
    useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [count, setCount] = useState(0);
  const [logging, setLogging] = useState(false);
  const [passwordLogin, setPasswordLogin] = useState("");
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);

  useEffect(() => {
    if (logging) {
      const interval = setInterval(() => {
        setCount((count) => (count < 3 ? count + 1 : 0));
      }, 500);
      return () => clearInterval(interval);
    }
    if (userInfo && userInfo.email !== undefined) {
      setLoggedIn(true);
    }
  }, [logging]);

  const dots = ".".repeat(count);
  const loggingText = logging ? `Logging in${dots}` : "Log in";

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const togglePasswordLogin = (e) => {
    setShowPasswordLogin(!showPasswordLogin);
  };

  const handlePasswordLogin = (event) => {
    setPasswordLogin(event.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === "" || passwordLogin === "") {
      alert("Fill out the blank spaces");
      return;
    }
    setLogging(true);
    e.target.disabled = true;
    await fetch(`${apiBaseURL}/get_hash`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.msg === "Hash successfully obtained") {
          const hash = data.hash;
          bcrypt.compare(passwordLogin, hash, async (err, result) => {
            if (result) {
              await fetch(`${apiBaseURL}/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  email: email,
                  password: hash,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data.msg === "Log in successfully") {
                    setUserInfo({
                      email: email.toString(),
                      token: data.token,
                      loggedIn: true,
                    });
                    setLoggedIn(true);
                    setSettings({
                      ...settings,
                      modals: { ...settings.modals, show: false },
                    });
                    Cookies.set(
                      "userInfo",
                      JSON.stringify({
                        email: email.toString(),
                        token: data.token,
                        loggedIn: true,
                      }),
                      { expires: 1 / 24, secure: true, sameSite: "strict" }
                    );
                    window.document.location = window.document.location;
                  } else {
                    alert(
                      "Logging error.\nVerify your username and password and try again!"
                    );
                    setLogging(false);
                    e.target.disabled = false;
                  }
                })
                .catch((err) => {
                  setLogging(false);
                  e.target.disabled = false;

                  alert(err.message);
                });
            } else {
              setLogging(false);
              e.target.disabled = false;

              alert("Wrong username or password!");
            }
          });
        } else {
          setLogging(false);
          e.target.disabled = false;

          alert("User is not registered or account has not been activated");
        }
      })
      .catch((err) => {
        setLogging(false);
        e.target.disabled = false;

        alert(err);
      });
  };

  return (
    <>
      <h4 className="title-login">Log in!</h4>
      <form>
        <label className="label-login" htmlFor="email">
          Email:
        </label>
        <br />
        <input
          className="input-login"
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
        />

        <div>
          <label className="label-login" htmlFor="password">
            Password:
          </label>
          <br />
          <input
            className="input-login"
            type={showPasswordLogin ? "text" : "password"}
            id="password"
            value={passwordLogin}
            onChange={handlePasswordLogin}
          />
          {/* <img
            src={showPasswordLogin ? hidePasswordIcon : showPasswordIcon}
            alt={showPasswordLogin ? "Hide password" : "Show password"}
            onClick={togglePasswordLogin}
          /> */}
        </div>
        <button className="btn btn-primary" onClick={handleLogin}>
          {loggingText}
        </button>
      </form>
    </>
  );
};

const ModalSignUp = () => {
  const [login, setLogin] = useState(true);

  const toggleLoginSignup = () => {
    setLogin(!login);
  };

  return (
    <div className="login-menu">
      {login && (
        <motion.div initial={{ y: -50 }} animate={{ y: 0 }} exit={{ y: -50 }}>
          <Login />
        </motion.div>
      )}
      {!login && (
        <motion.div initial={{ y: -50 }} animate={{ y: 0 }} exit={{ y: -50 }}>
          <SignUp />
        </motion.div>
      )}

      <div>
        {login ? "Don't have an account? " : "Already have an account? "}
        <a onClick={toggleLoginSignup}>
          {login ? "Register here." : "Login here!"}
        </a>
      </div>
    </div>
  );
};

export default ModalSignUp;

import { createContext, useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { apiBaseURL } from "../config";

export const GlobalContext = createContext();

const tokenPromise = fetch(`${apiBaseURL}/new_guest_token`, {
  method: "POST",
})
  .then((resp) => resp.json())
  .catch((err) => console.log(err));

export const GlobalProvider = ({ children }) => {
  const userData = Cookies.get("userInfo");
  const [userInfo, setUserInfo] = useState(
    userData ? JSON.parse(userData) : userData
  );
  const [loggedIn, setLoggedIn] = useState(
    userInfo ? userInfo.email !== undefined : false
  );
  const [settings, setSettings] = useState({
    theme: {
      skin: "clear",
      view: "box",
      topbar: "light",
      layout: "boxed",
      degraded: "degraded",
      workspace: "workspace-color2",
      mainColor: "main-color1",
      secondColor: "secondary-color1",
    },
    modals: {
      show: false,
      title: "NOTIFICATIONS",
      icon: "icon-notification",
      id: "modal-notifications",
    },
    message: {
      show: false,
      status: "ok",
      data: "OK",
      fn: () => {},
    },
    console: {
      show: false,
    },
  });

  const [loading, setLoading] = useState(false);
  const fqlToken = useRef(undefined);
  const groupName = useRef(undefined);
  const groupOwnerEmail = useRef(undefined);
  const forms = useRef();

  const runCode = async ({ code, token }) => {
    return fetch(`${apiBaseURL}/run_code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        code,
        token,
        fql_token: fqlToken.current,
        group_name: groupName.current,
        group_owner_email: groupOwnerEmail.current,
      }),
    })
      .then(async (res) => {
        // if (!res.ok) {
        //   const status = res.status;
        //   const statusText = res.statusText;
        //   const body = await res.json();
        //   throw new APIError({ body, status, statusText });
        // }
        return res.json();
      })
      .then((data) => {
        if (data.fql_token) fqlToken.current = data.fql_token;
        if (data.group_name) groupName.current = data.group_name;
        if (data.group_owner_email) groupOwnerEmail.current = data.group_owner_email;
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const getToken = async () => {
    await tokenPromise
      .then((data) => {
        setUserInfo(userData ? JSON.parse(userData) : { token: data.token });
      })
      .catch((err) => console.log(err));
  };

  const doLogout = async () => {
    await tokenPromise
      .then((data) => {
        setUserInfo({ token: data.token });
        setLoggedIn(false);
        Cookies.remove("userInfo");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        userInfo,
        setUserInfo,
        settings,
        setSettings,
        loggedIn,
        setLoggedIn,
        runCode,
        fqlToken,
        groupName,
        groupOwnerEmail,
        forms,
        loading,
        setLoading,
        doLogout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import { useContext, useState, useEffect } from "react";
import ItemOptions from "./ItemOptions";
import { GlobalContext } from "./GlobalContext";

const ItemList = ({ name }) => {
  const { userInfo, runCode } = useContext(GlobalContext);
  const [cases, setCases] = useState(0);

  useEffect(() => {
    runCode({
      code: `get ${name}`,
      token: userInfo.token,
    }).then((data) => {
      data.output ? setCases(data.output.length) : setCases(0);
    });
  }, []);

  return (
    <div className={"item-line"}>
      <div className="left">
        <span className="name">{name}</span>
      </div>
      <div className="right hidden-sm-down">
        <span>
          <i className="icon-date"></i> 13/9/2023
        </span>
        <span>
          <i className="icon-user"></i> {cases} CASES
        </span>
      </div>
      <ItemOptions name={name} />
      <div className="selection-mask"></div>
    </div>
  );
};

export default ItemList;

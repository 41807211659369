import React from "react";
import { Link } from "react-router-dom";
import { Heading, Text, ListItem, Image } from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import db_ref from "../../resources/db_reference.png";
import CodeExample from "../../new_component/organisms/CodeExample";

const WhyFQL = () => {
  return (
    <WikiArticle>
      <WikiArticleMain>
        <section>
          <Heading as="h2">Why FQL?</Heading>
          <SectionTitle>It's Human</SectionTitle>
          <Text>
            When I first told my daughter that I was working on a new
            programming language, her reaction was <span style={{ fontStyle: 'italic' }}>"Whaaaaaaat!!!!??? Dad, I was
            learning Python in high school until I heard that there are hundreds
            of different programming languages. That was discouraging. Why do we
            need another one?"</span> -she responded.
          </Text>
          <Text>
            <span style={{ fontStyle: 'italic' }}>"You're right"</span> -I said.
            <span style={{ fontStyle: 'italic' }}>"But all these languages are computer
            languages that humans need to learn. This one instead, is a human
            language that computers can run. Our starting point is not '1s and
            0s', Assembler, or anything like that. We start from human language,
            and then make it unambiguous. Maybe not everyone can write it, but
            everyone can read it."</span>
          </Text>
          <Text>
            Then, I showed her some examples and got her approval. This is how
            we got here :-)
          </Text>
        </section>

        <section>
          <SectionTitle>It's Simple</SectionTitle>
          <Text>
            Imagine you have some data about Countries, Cities (and the Country
            they belong to), Companies (and the City where the HQ is), Products
            (and the Company that produces them), and finally some invoices that
            contain multiple Products. In SQL, each of these entities will be
            represented by a Table. In an Application Base, each of them will be
            a Form.
          </Text>
          <Text>
            But then, if you want to see something like "Invoices containing
            Products from Uruguay", here is how you would write it:
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={db_ref}
            alt="DB References"
          />
          <br />
          <CodeExample margin={0} title="SQL" run={false}>
            {`\
SELECT *                                                      
FROM invoices i 
INNER JOIN invoice_lines il
ON i.nr = il.invoice_nr
INNER JOIN products p
ON il.product_id = p.id
INNER JOIN companies co
ON p.company_id = co.id
INNER JOIN cities ci
ON co.city_id = ci.id
INNER JOIN countries c
ON ci.country_id = c.id
WHERE c.name = 'URUGUAY'
              `}
          </CodeExample>

          <CodeExample margin={0} title="FQL" run={false}>
            {`\
GET invoices 
  WITH country.name = 'Uruguay'
              `}
          </CodeExample>
          <Text>
            This is achieved through an internal Data Dictionary, and a graph
            search that (if non-ambiguous) makes the statement valid.
          </Text>
          <Text>
            Ok, maybe instead of just "simple" we should say that FQL is
            "intelligently simple" :)
          </Text>
        </section>

        <section>
          <SectionTitle>It's Powerful</SectionTitle>
          <Text>
            At this point, you might be wondering{" "}
            <Text fontStyle={"italic"}>
              "Yeah ... all nice and good when writing simpler SQLs, but what
              about the business logic? It seems that we will always have to
              write a lot of FOR loops and intricate logic to get even simple
              things done."
            </Text>
          </Text>
          <Text>Well... not really. </Text>
          <Text>
            Imagine you want to check if there are some rooms available in a
            hotel application. Well ... you simply write:
          </Text>
          <CodeExample title="FQL" run={false}>
            {`\
HOTEL_ROOMS is used by GUESTS
  through RESERVATIONS.(room_assigned, from_date, until_date)
  by groups.(capacity) in 1..HOTEL_ROOMS.capacity
          `}
          </CodeExample>
          <Text>
            You can see (and run) this statement from the{" "}
            <Link to="/docs/is_used_by" style={{ color: "blue" }}>
              Wiki
            </Link>
            .
          </Text>
        </section>

        <section>
          <SectionTitle>It's Growing</SectionTitle>
          <Text>
            And we are so passionate about it, that we continue developing it,
            while building amazing solutions. Join us! Tell us about your
            experience! We are happy to help and keep on improving!
          </Text>
        </section>

        <section>
          <SectionTitle></SectionTitle>
          <Text></Text>
        </section>
      </WikiArticleMain>

      <WikiArticleSubPanel>
        <SectionIndex>
          <ListItem>
            <LinkToSection>It's Human</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>It's Simple</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>It's Powerful</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>It's Growing</LinkToSection>
          </ListItem>
        </SectionIndex>
      </WikiArticleSubPanel>
    </WikiArticle>
  );
};

export default WhyFQL;

import { forwardRef, useState, useImperativeHandle, useContext } from "react";
import { getToken } from "../actions";
import Popup from "../component/Popup";
import Button from "./Button";
import RunImage from "../resources/run.png";
import styled, { css } from "styled-components";

import consoleLight from "../resources/terminal_dark.png";
import consoleDark from "../resources/terminal_light.png";
import { GlobalContext } from "./GlobalContext";

const RunButton = ({ onClick, children }) => (
  <Button onClick={onClick}>
    <img style={{ width: "1em" }} src={RunImage} alt="Run" />
    {children && ` ${children}`}
  </Button>
);

const Badge = styled.span`
  display: inline-block;
  padding: 5px 12px;
  border-radius: 4px;
  color: #fff;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const RunningBadge = (props) => (
  <Badge style={{ backgroundColor: "#777" }} {...props}>
    RUNNING
  </Badge>
);
const SuccessBadge = (props) => (
  <Badge style={{ backgroundColor: "#5cb85c" }} {...props}>
    SUCCESS
  </Badge>
);
const FailureBadge = (props) => (
  <Badge style={{ backgroundColor: "#d9534f" }} {...props}>
    FAILURE
  </Badge>
);
const ErrorBadge = (props) => (
  <Badge style={{ backgroundColor: "#d9534f" }} {...props}>
    ERROR
  </Badge>
);

const TableRow = styled.div`
  display: table-row;
  height: 50px;
  > * {
    display: table-cell;
    vertical-align: middle;
    padding: 5px 10px;
  }
`;

const ResultDetailContainer = styled.div`
  min-width: 650px;
  max-width: 80vw;
  max-height: 95vh;
  border-radius: 4px;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  th {
    padding: 4px 14px;
    background: lightgray;
    font-size: 90%;
  }
  td {
    padding: 0;
  }
`;

const ImgContainer = styled.img`
  display: inline-block;
  margin-left: 18px;
  margin-right: 1vh;
  height: 3em;
  width: 4em;
  cursor: pointer;
`;
const ExpressionContainer = styled.div`
  white-space: pre-wrap;
  overflow: hidden;
  padding: 4px 18px 12px;
  font-family: monospace;
  tab-size: 2;
  background-color: #333;
  color: #fff;
`;
const Expression = styled.div`
  margin-top: 9px;
`;
const MessageLog = styled.span`
  color: green;
`;
const OutputLog = styled.span`
  color: yellowgreen;
`;
const ErrorLog = styled.span`
  color: red;
`;

const ResultDetail = ({
  badge,
  subject,
  initialize,
  code,
  initResponses = [],
  responses = [],
  runTest,
}) => {
  const RowsForLogs = ({ headline, code, responses }) => (
    <>
      <tr>
        <th>{headline}</th>
      </tr>
      <tr>
        <td>
          <ExpressionContainer>
            {code.map((expression, i) => (
              <Expression key={`result-detail-${subject}-${i}`}>
                {Array.isArray(expression) ? expression[0] : expression}
                {"\n"}
                {responses[i]?.msg && (
                  <MessageLog>{`;-> ${responses[i].msg}\n`}</MessageLog>
                )}
                {responses[i]?.output && (
                  <OutputLog>{`;=> ${JSON.stringify(
                    responses[i].output,
                    undefined,
                    2
                  ).replace(/\n/g, "\n;   ")}\n`}</OutputLog>
                )}
                {responses[i]?.error && (
                  <ErrorLog>{`;-> ${responses[i].error}\n`}</ErrorLog>
                )}
              </Expression>
            ))}
          </ExpressionContainer>
        </td>
      </tr>
    </>
  );
  return (
    <ResultDetailContainer>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td style={{ padding: "4px 12px 12px" }}>
              {badge}
              <div style={{ display: "inline-block", marginLeft: "8px" }}>
                {subject}
              </div>
              <div style={{ float: "right" }}>
                {runTest && <RunButton onClick={runTest}>Re-Run</RunButton>}
              </div>
            </td>
          </tr>
          {initialize && initialize.length !== 0 && (
            <RowsForLogs
              headline="Initialization"
              code={initialize}
              responses={initResponses}
            />
          )}
          <RowsForLogs
            headline={`Logs of ${code.length} expressions`}
            code={code}
            responses={responses}
          />
        </tbody>
      </table>
    </ResultDetailContainer>
  );
};

const TestCase = forwardRef(function TestCase(
  { subject, test, initialize, code },
  ref
) {
  const [result, setResult] = useState(null);
  const [initResponses, setInitResponses] = useState([]);
  const [responses, setResponses] = useState([]);
  const [isDetailVisible, setDetailVisibility] = useState(false);
  const [commandsVisible, setCommandsVisible] = useState(false);

  const { runCode, fqlToken } = useContext(GlobalContext);

  const defaultTestFn = test || ((res) => res.error === "");

  const runTest = async () => {
    setResult("running");
    setResponses([]);
    setInitResponses([]);
    getToken().then(async (token) => {
      if (initialize) {
        for (const expression of initialize) {
          const isSuccess = await runCode({
            code: expression.replace(/[\n\t]/g, " "),
            token,
          })
            .then((res) => {
              if (res.fql_token) {
                fqlToken.current = res.fql_token;
              }
              setInitResponses((responses) => responses.concat([res]));
              if (res.error !== "") {
                return false;
              }
              return true;
            })
            .catch((error) => {
              setInitResponses((responses) => responses.concat([{ error }]));
              return false;
            });
          if (!isSuccess) {
            setResult("error");
            return;
          }
        }
      }
      for (const c of code) {
        const [expression, testFn] = Array.isArray(c) ? c : [c];
        const res = await runCode({
          code: expression.replace(/[\n\t]/g, " "),
          token,
        })
          .then((res) => {
            setResponses((responses) => responses.concat([res]));
            return (testFn || defaultTestFn)(res) ? "success" : "failure";
          })
          .catch((error) => {
            setResponses((responses) => responses.concat([{ error }]));
            return "error";
          });
        if (res !== "success") {
          setResult(res);
          return;
        }
      }
      setResult("success");
    });
  };

  const toggleVisibility = (visible) => {
    if (visible === true || visible === false) setCommandsVisible(visible);
    else {
      setCommandsVisible(!commandsVisible);
    }
  };

  useImperativeHandle(ref, () => {
    return { runTest, toggleVisibility };
  });

  const showDetail = () => setDetailVisibility(true);

  const showResult = (onClick) => {
    switch (result) {
      case "running":
        return <RunningBadge onClick={onClick} />;
      case "success":
        return <SuccessBadge onClick={onClick} />;
      case "failure":
        return <FailureBadge onClick={onClick} />;
      case "error":
        return <ErrorBadge onClick={onClick} />;
      default:
        return null;
    }
  };

  return (
    <>
      <TableRow>
        <div
          style={{
            display: "table-cell",
            textAlign: "center",
            verticalAlign: "middle",
            padding: "none",
            width: "65px",
          }}
        >
          {result === "running" ? (
            <div>...</div>
          ) : (
            <RunButton onClick={runTest} />
          )}
        </div>
        <div>{subject}</div>
        <ImgContainer
          src={commandsVisible ? consoleDark : consoleLight}
          alt="show-command-windows"
          onClick={toggleVisibility}
        />
        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          {showResult(showDetail)}
        </div>
        <Popup
          onClose={() => setDetailVisibility(false)}
          visible={isDetailVisible}
        >
          <ResultDetail
            initResponses={initResponses}
            responses={responses}
            subject={subject}
            initialize={initialize}
            code={code}
            badge={showResult()}
            runTest={result !== "running" && runTest}
          />
        </Popup>
      </TableRow>

      <div
        style={{
          display: "flex",
          textAlign: "left",
          verticalAlign: "middle",
          padding: "none",
          width: "65px",
          height: "fit-content",
        }}
      >
        <div className={`elems-section ${commandsVisible ? "show" : "hide"}`}>
          <ResultDetail
            initResponses={initResponses}
            responses={responses}
            initialize={initialize}
            code={code}
          />
        </div>
      </div>
    </>
  );
});

export default TestCase;

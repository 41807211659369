import {
  useState,
  createRef,
  forwardRef,
  useImperativeHandle,
  useContext,
  useEffect,
  useRef,
} from "react";
import DefaultLayout from "../layouts/default";
import { GlobalContext } from "../component/GlobalContext";
import { getToken } from "../actions";
import { apiBaseURL } from "../config";
import {
  VStack,
  HStack,
  Flex,
  StackDivider,
  SimpleGrid,
  Spacer,
  Card,
  CardBody,
  Heading,
  Box,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  Tag,
} from "@chakra-ui/react";
import { Button } from "../new_component/atoms/Button";
import ExecutionLog from "../new_component/organisms/ExecutionLog";
import { faPlay, faTerminal } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import background from "../resources/db_reference.png";
import { useSearchParams } from "react-router-dom";
import emailjs from "emailjs-com";

const BaseBadge = styled(Tag)`
  text-transform: uppercase;
  font-weight: 700;
`;

const ResultBadge = ({ result }) => (
  <BaseBadge
    size="lg"
    color="white"
    fontFamily="heading"
    bgColor={
      result === "success"
        ? "brand.500"
        : result === "running"
        ? "darkgray"
        : "red"
    }
  >
    {result}
  </BaseBadge>
);

const TestCase = forwardRef(function TestCase(
  { subject, test, initialize = [], code = [] },
  ref
) {
  const [result, setResult] = useState(null);
  const [initResponses, setInitResponses] = useState([]);
  const [responses, setResponses] = useState([]);
  const [commandsVisible, setCommandsVisible] = useState(false);
  const fqlToken = useRef({});

  const [query, setQuery] = useSearchParams();

  const { runCode } = useContext(GlobalContext);

  const defaultTestFn = test || ((res) => !res.error);

  const sendMail = (code, error) => {
    console.log(code, error);
    if (query.get("runTests") === "true") {
      emailjs
        .send(
          "default_service",
          "template_go5e81t",
          {
            code: code,
            error: error,
            to_email: "fixel1999@gmail.com",
            cc: "fguigou@gmail.com, leandro.nunez1457@gmail.com, e.arrows@gmail.com, frankbg.000914@gmail.com",
          },
          "JtJkEdwYagbvyGlcX"
        )
        .then((response) => {
          console.log("MAILED SUCCESSFULLY!", response.status, response.text);
        })
        .catch((err) => {
          alert(
            "Failed to send email! Check your connection and try again\n",
            err
          );
        });
    }
  };

  const runTest = async () => {
    setResult("running");
    setResponses([]);
    setInitResponses([]);
    getToken().then(async (token) => {
      if (initialize) {
        for (const expression of initialize) {
          const code = expression.replace(/[\n\t]/g, " ");
          const isSuccess = await runCode({ code, token })
            .then((res) => {
              if (res.fql_token) {
                fqlToken.current = res.fql_token;
              }
              const result = !res.error;
              setInitResponses((responses) =>
                responses.concat(Object.assign({}, res, { isSuccess: result }))
              );
              !result && sendMail(expression, res.msg);
              return result;
            })
            .catch((error) => {
              setInitResponses((responses) => responses.concat({ error }));
              sendMail(expression, error.toString());
              return false;
            });
          if (!isSuccess) {
            setResult("error");
            return;
          }
        }
      }

      for (const c of code) {
        const [expression, testFn] = Array.isArray(c) ? c : [c];
        const testCode = expression.replace(/[\n\t]/g, " ");
        const res = await runCode({ code: testCode, token })
          .then((res) => {
            const result = (testFn || defaultTestFn)(res);
            setResponses((responses) =>
              responses.concat(Object.assign({}, res, { isSuccess: result }))
            );
            !result && sendMail(c, res.msg);
            return result ? "success" : "failure";
          })
          .catch((error) => {
            setResponses((responses) => responses.concat([{ error }]));
            sendMail(c, error.toString());
            return "error";
          });
        if (res !== "success") {
          setResult(res);
          return;
        }
      }
      setResult("success");
    });
  };

  const toggleVisibility = (visible) => {
    if (visible === true || visible === false) setCommandsVisible(visible);
    else {
      setCommandsVisible(!commandsVisible);
    }
  };

  useImperativeHandle(ref, () => {
    return { runTest, toggleVisibility };
  });

  return (
    <AccordionItem border="none">
      <AccordionButton
        as={Card}
        direction={{ base: "column", sm: "row" }}
        variant="outline"
        size="sm"
        py={0}
        pos="sticky"
        top="70px"
      >
        <HStack w="100%">
          <AccordionIcon />
          <CardBody w="max-content">
            <Flex gap={3} align="center">
              <Button
                icon={faPlay}
                onClick={(e) => {
                  runTest();
                  e.stopPropagation();
                }}
                size="sm"
                zIndex="float"
              />
              <Heading as="h3" fontSize="md" fontWeight="500" m={0}>
                {subject}
              </Heading>
              <Spacer />
              <HStack visibility={result ? "visible" : "hidden"} gap={2}>
                <Spinner
                  size="sm"
                  visibility={result === "running" ? "visible" : "hidden"}
                />
                <Box>
                  <ResultBadge result={result} />
                </Box>
              </HStack>
            </Flex>
          </CardBody>
        </HStack>
      </AccordionButton>
      <AccordionPanel>
        {initialize.map((init, i) => {
          const uuid = crypto.randomUUID();
          return (
            <ExecutionLog
              key={`execution-code-${uuid}`}
              {...Object.assign(initResponses.at(i) || {}, { code: init })}
            />
          );
        })}
        {code.map((c, i) => {
          const uuid = crypto.randomUUID();
          return (
            <ExecutionLog
              key={`execution-code-${uuid}`}
              code={Array.isArray(c) ? c[0] : c}
              {...Object.assign(responses.at(i) || {}, {
                code: Array.isArray(c) ? c[0] : c,
              })}
            />
          );
        })}
      </AccordionPanel>
    </AccordionItem>
  );
});

const AutomatedTests = () => {
  const [visible, setVisible] = useState(false);
  const [visibleIndices, setVisibleIndices] = useState([]);
  const [query, setQuery] = useSearchParams();

  const testCases = [
    {
      subject: "create form",
      code: [
        "create form Countries (\n\tname text not null unique,\n\tcapital text not null,\n\textension number\n)",
        "create form Addresses (\n\tstreet_number number,\n\tzip_code number,\n\tcountry references Countries.name\n)",
        "create form JobCategories (name text not null unique, parent references JobCategories.name)",
        "create form Jobs (name text not null unique, categories references JobCategories.name)",
        "create form Persons (\n\tname text not null unique,\n\tage number,\n\taddress references Addresses.(street_number, zip_code, country),\n\tjobs references 1..3 Jobs.name unique\n)",
        "create form Companies (\n\tname text not null unique,\n\tmanager references 1 Persons.name totally unique,\n\temployees references 1..many Persons.name unique\n)",
      ],
    },
    {
      subject: "show form(s)",
      initialize: [
        "create form Contacts (\n\tname text,\n\tmobile_number number,\n\temail text,\n\tbirthdate date\n)",
        "create form Emails (\n\tfrom_contact references 1 Contacts.email,\n\tto_contact references 1..many Contacts.email,\n\ttopic text, body text\n)",
      ],
      code: ["show forms Contacts", "show forms Emails Contacts"],
    },
    {
      subject: "remove form(s)",
      initialize: [
        "create form Notes (\n\ttitle text,\n\tbody text,\n\tauthor text,\n\tstarted_date date\n)",
        "create form Voice_Notes (\n\ttitle text,\n\taudio file,\n\tauthor text,\n\tstarted_date date\n)",
        "create form Calendar_Event (\n\tname text not null,\n\tdescription text,\n\tstart_date date,\n\tend_date date\n)",
      ],
      code: ["remove forms Notes", "remove forms Voice_Notes Calendar_Event"],
    },
    {
      subject: "create new entries",
      initialize: [
        "create form Nationalities (name text)",
        "create form Directors (\n\tname text,\n\tnationality references Nationalities.name\n)",
        "create form Movies (\n\tname text,\n\tlength number,\n\tdirector references 1..3 Directors.name\n)",
      ],
      code: [
        'create new Nationalities values ("Cuban")',
        'create new Nationalities values ("Japanese")',
        'create new Nationalities values ("Uruguayan")',
        'create Directors ("Leonardo Padura", "Cuban")',
        'create Directors ("Enrique Colina", "Cuban")',
        'create Directors ("Hayao Miyazaki", "Japanese")',
        'create Directors ("Isao Takahata", "Japanese")',
        'create Directors ("Federico Veiroj", "Uruguayan")',
        "create Movies values ('Acne', 87, 'Federico Veiroj')",
        "create Movies values ('Castle in the Sky', 124, ('Hayao Miyazaki','Isao Takahata'))",
        "create Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Enrique Colina'))",
      ],
    },
    {
      subject: "get case",
      initialize: [
        "create form Pet_Owners (\n\tname text,\n\tage number\n)",
        "create form Pets (\n\tname text,\n\tanimal text,\n\tbreed text,\n\towner references 1 Pet_Owners.(name, age)\n)",
        "create new Pet_Owners ('Louis', 15)",
        "create new Pet_Owners ('Andrew', 24)",
        "create new Pet_Owners ('Ana', 63)",
        "create Pets('Loki','dog', 'Pekingese', 'Andrew')",
        "create Pets('Tiger','cat', 'Persian', 'Ana')",
        "create Pets('Nina','dog', 'Labrador', 'Louis')",
        "create Pets('Fluff','cat', 'Siamese', 'Ana')",
        "create form EmptyForm (void text)",
      ],
      code: [
        "get EmptyForm",
        "get Pets (breed)",
        "get Pets (name, breed)\n\twith owner.name = 'Ana'",
        "get Pets (name, breed, owner.name, owner.age)\n\twith animal = 'dog'\n\tand owner.age > 20",
      ],
    },
    {
      subject: "modify case",
      initialize: [
        "create form Companies (name text)",
        "create form Products (\n\tname text,\n\tdesigner references Companies.name,\n\tprice number\n)",
        "create new Companies ('Apple')",
        "create new Companies ('Google')",

        "create new Products ('MacBook Pro M1', 'Apple', 1299)",
        "create new Products ('iPhone 12 Pro', 'Google', 499)",
        "create new Products ('AirPods Pro 1st Gen', 'Apple', 399)",

        "get Products with name = 'AirPods Pro 1st Gen'",
      ],
      code: [
        "modify Products ('AirPods Pro 1st Gen','Apple', 299)\n\twith name='AirPods Pro 1st Gen'\n\tand price=399\n\tand fql_version=0",
      ],
    },
    {
      subject: "remove case",
      initialize: [
        "create form Genres (name text)",
        "create form Songs (\n\tname text,\n\tauthor text,\n\tgenre text,\n\tyear number\n)",
        "create new Genres('rock')",
        "create new Genres('pop')",
        "create new Genres('reggaeton')",
        "create new Genres('classical')",
        "create new Songs ('Careless Whisper', 'George Michael', 'pop-soul', 1984)",
        "create new Songs ('Imagine', 'John Lenon', 'pop-rock', 1971)",
        "create new Songs ('Gasolina', 'Daddy Yankee', 'reggaeton', 2004)",
        "create new Songs ('Despacito', 'Luis Fonsi', 'pop-latino', 2017)",
      ],
      code: [
        "remove Genres",
        "remove Songs\n\twith year > 2000",
        "remove Songs\n\twith year > 1970\n\tand year < 1980\n\tand genre='pop-rock'",
      ],
    },
    {
      subject: "validations",
      initialize: [
        "create form Items (\n\tname text,\n\tmodel text,\n\tprice number\n)",
        "create form Store (\n\taddress text,\n\titems references 1..many Items.(name, model, price)\n)",
      ],
      code: [
        'define rule NO_LAPTOPS: on Items when\n\tname = "Laptop" is invalid',
        [
          'create new Items values ("Laptop", "M132AXX", 450)',
          (res) => res.error && res.msg.match(/NO_LAPTOPS/i),
        ],
        "define rule NO_EXPENSIVE_ITEMS: on Store when\n\titems.price > 500 is invalid",
        "create new Items ('SmartWatch', 'SM34A', 300)",
        "create new Items ('iPhone 12', 'M3456/A', 650)",
        [
          "create new Store('2701 NW Ave.', ('SmartWatch', 'iPhone 12'))",
          (res) => res.error && res.msg.match(/NO_EXPENSIVE_ITEMS/i),
        ],
      ],
    },
    {
      subject: "define function",
      initialize: ["create form Users (name text unique not null, age number)"],
      code: [
        'define function raise_age_error(age) { (error "Too young to get a job: ~A" age) }',
        "define rule NO_TEENS_WITH_JOBS: on Users when age < 18 then CALL raise_age_error(age)",
        'create new Users ("MEIKO", 19)',
        [
          'create new Users ("Miku Hatsune", 16)',
          (res) => res.error && res.msg.match(/NO_TEENS_WITH_JOBS/i),
        ],
      ],
    },
    {
      subject: "is used by",
      initialize: [
        "create form HOTEL_ROOMS(room_number number not null unique, floor number not null, single_beds number not null, double_beds number not null, capacity number)",
        "create form GUESTS(guest_number number not null unique, name text not null, phone text not null)",
        "create form RESERVATIONS(guest references GUESTS.name, capacity number not null, single_beds number, double_beds number, from_date date, until_date date, room_assigned references HOTEL_ROOMS.room_number)",
        "create HOTEL_ROOMS(1000, 1, 3, 0, 3)",
        "create HOTEL_ROOMS(1001, 1, 1, 1, 2)",
        "create HOTEL_ROOMS(1002, 2, 3, 1, 4)",
        "create HOTEL_ROOMS(1003, 2, 4, 0, 4)",
        "create HOTEL_ROOMS(1004, 3, 1, 5, 6)",

        'create GUESTS(10, "Leandro", "+LLLL126589")',
        'create GUESTS(11, "Felix", "+FFFF346589")',
        'create GUESTS(12, "Eitaro", "+EEEE14354589")',
        'create GUESTS(13, "Fernando", "+YYYY9136546589")',
        'create GUESTS(14, "Alvaro", "+AAAA129430389")',
        'create GUESTS(15, "Carlos", "+CCCC126923289")',
      ],
      code: [
        "HOTEL_ROOMS is used by GUESTS through RESERVATIONS.(room_assigned, from_date, until_date) in groups.(capacity) of 1..HOTEL_ROOMS.capacity",
        'create RESERVATIONS("Felix", 2, 3, 0, "07-10-2024", "07-17-2024", 1000)',

        'create RESERVATIONS("Leandro", 4, 0, 4, "06-08-2024", "06-15-2024", 1003)',
        'create RESERVATIONS("Fernando", 1, 3, 0, "06-28-2024", "07-05-2024", 1000)',

        [
          'create RESERVATIONS("Eitaro", 2, 3, 0, "07-06-2024", "07-13-2024", 1000)',
          (res) => res.error,
        ],
        [
          'create RESERVATIONS("Alvaro", 2, 3, 0, "07-11-2024", "07-13-2024", 1000)',
          (res) => res.error,
        ],
        [
          'create RESERVATIONS("Carlos", 11, 3, 0, "07-20-2024", "07-24-2024", 1000)',
          (res) => res.error,
        ],
      ],
    },
    {
      subject: "any / all",
      initialize: [
        "create form Nationalities (name text)",
        "create form Directors (\n\tname text,\n\tnationality references Nationalities.name\n)",
        "create form Movies (\n\tname text,\n\tlength number,\n\tdirector references 1..3 Directors.name\n)",
        'create new Nationalities values ("Cuban")',
        'create new Nationalities values ("Japanese")',
        'create new Nationalities values ("Uruguayan")',
        'create Directors ("Leonardo Padura", "Cuban")',
        'create Directors ("Enrique Colina", "Cuban")',
        'create Directors ("Hayao Miyazaki", "Japanese")',
        'create Directors ("Isao Takahata", "Japanese")',
        'create Directors ("Federico Veiroj", "Uruguayan")',
        "define rule NO_ENRIQUE: on Movies when ANY director.name = 'Enrique Colina' is invalid",
        "define rule NO_ALL_LEONARDO: on Movies when ALL director.name = 'Leonardo Padura' is invalid",
      ],
      code: [
        "create Movies values ('Acne', 87, 'Federico Veiroj')",
        [
          "create Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Enrique Colina'))",
          (res) => res.error && res.msg.match(/NO_ENRIQUE/i),
        ],
        "create Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Hayao Miyazaki'))",
        [
          "create Movies values ('Acne', 87, ('Leonardo Padura', 'Leonardo Padura'))",
          (res) => res.error && res.msg.match(/NO_ALL_LEONARDO/i),
        ],
        [
          "create Movies values ('Acne', 87, 'Leonardo Padura')",
          (res) => res.error && res.msg.match(/NO_ALL_LEONARDO/i),
        ],
      ],
    },
    {
      subject: "sum / min / max",
      initialize: [
        "create form Nationalities (name text)",
        "create form Directors (\n\tname text,\n\tnationality references Nationalities.name\n)",
        "create form Movies (\n\tname text,\n\tlength number,\n\tdirector references 1..3 Directors.name\n)",
        "create form Albums (\n\tname text, length references 1..3 Movies.length)",
        'create new Nationalities values ("Cuban")',
        'create new Nationalities values ("Japanese")',
        'create new Nationalities values ("Uruguayan")',
        'create Directors ("Leonardo Padura", "Cuban")',
        'create Directors ("Enrique Colina", "Cuban")',
        'create Directors ("Hayao Miyazaki", "Japanese")',
        'create Directors ("Isao Takahata", "Japanese")',
        'create Directors ("Federico Veiroj", "Uruguayan")',
        "create Movies values ('Acne', 87, 'Federico Veiroj')",
        "create Movies values ('Castle in the Sky', 124, ('Hayao Miyazaki','Isao Takahata'))",
        "create Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Enrique Colina'))",
        "define rule NO_BIG_ALBUMS: on Albums when SUM length.length > 300 is invalid",
        "define rule NO_LONG_MOVIE: on Albums when MAX length.length > 120 is invalid",
        "define rule NO_SHORT_MOVIE: on Albums when MIN length.length < 90 is invalid",
      ],
      code: [
        "create Albums values ('Un Rey en La Habana', 102)",
        [
          "create Albums values('Acne', 87)",
          (res) => res.error && res.msg.match(/NO_SHORT_MOVIE/i),
        ],
        [
          "create Albums values ('Castle in the Sky', 124)",
          (res) => res.error && res.msg.match(/NO_LONG_MOVIE/i),
        ],
        [
          "create new Albums (name = 'All', length.length = (87,124,102))",
          (res) => res.error && res.msg.match(/NO_BIG_ALBUMS/i),
        ],
      ],
    },

    {
      subject: "path inference",
      initialize: [
        "create form Countries(name text)",
        "create form Cities(name text, country references Countries.name)",
        "create form Persons(name text, city references Cities.(name, country.name))",
        "create form Store(name text, location references Cities.name, employees references 1..many Persons.name)",

        'create new Countries values ("Cuba")',
        'create new Countries values ("Japan")',
        'create new Countries values ("Uruguay")',

        'create new Cities ("Habana", "Cuba")',
        'create new Cities ("Tokyo", "Japan")',
        'create new Cities ("Montevideo", "Uruguay")',

        'create new Persons ("Leandro", "Habana")',
        'create new Persons ("Felix", "Habana")',
        'create new Persons ("Eitaro", "Tokyo")',
        'create new Persons ("Fernando", "Montevideo")',

        "define rule NO_EMPLOYEES_FROM_HABANA: on Store when city.name = 'Habana' is invalid",
      ],
      code: [
        "create Store values ('Amazing Store', 'Montevideo', ('Fernando'))",
        [
          "create Store values ('Carlos III', 'Habana', ('Leandro', 'Fernando'))",
          (res) => res.error,
          // && res.msg.match(/NO_SHORT_MOVIE/),
        ],
        [
          "define rule CONFLICT_WITH_PATHS: on Store when country.name = 'Cuba' is invalid",
          (res) => res.error,
          // && res.msg.match(/NO_LONG_MOVIE/),
        ],
      ],
    },
    {
      subject: "actions",
      initialize: [
        "create form Persons (\n\tname text not null unique,\n\tage number,\n\tmembership text\n)",
      ],
      code: [
        'define action convert_to_vip: on Persons when age > 50 set membership = "vip"',
        'create Persons ("Goku", 12, "")',
        'create Persons ("Roshi", 319, "")',
        [
          "get Persons (name, membership)",
          (res) => {
            const goku = res.output.find((x) => x.name === "Goku");
            const roshi = res.output.find((x) => x.name === "Roshi");
            return (
              !res.error &&
              goku &&
              roshi &&
              goku.membership === "" &&
              roshi.membership === "vip"
            );
          },
        ],
      ],
    },
    {
      subject: "including",
      initialize: [
        "create form Nationalities (name text)",
        "create form Directors (name text, nationality references Nationalities.name)",
        "create form Movies (name text, length number, director references 1..3 Directors.name including sub_role text)",
        'create new Nationalities values ("Cuban")',
        'create new Nationalities values ("Japanese")',
        'create new Nationalities values ("Uruguayan")',
        'create Directors ("Leonardo Padura", "Cuban")',
        'create Directors ("Enrique Colina", "Cuban")',
        'create Directors ("Hayao Miyazaki", "Japanese")',
        'create Directors ("Isao Takahata", "Japanese")',
        'create Directors ("Federico Veiroj", "Uruguayan")',
      ],
      code: [
        "create Movies values ('Acne', 87, 'Federico Veiroj')",
        "create Movies values ('Castle in the Sky', 124, (('Hayao Miyazaki', 'Principal'),('Isao Takahata', 'Assistant')))",
        "create Movies values('Un Rey en La Habana', 102, ('Leonardo Padura','Enrique Colina'))",
        "get Movies (name, director.name, director.sub_role)",

        "create form Cities (name text not null)",
        "create form Articles (name text not null)",
        "create form Warehouse (name text not null, location references Cities.name, articles references 1..many Articles.name including (quantity number, current_price number, best_buying_cities references 1..3 Cities.name including (sell_priority number)))",
        'create new Cities ("Habana")',
        'create new Cities ("Tokyo")',
        'create new Articles ("Article A")',
        'create new Articles ("Article B")',
        'create new Warehouse ("Warehouse A", "Habana", (("Article A", 10, 320, (("Habana", 10), ("Tokyo", 20))), ("Article B", 3, 200, (("Tokyo", 10)))))',
        "get Warehouse (name, location.name, articles.fql_id, articles.best_buying_cities.name, articles.best_buying_cities.sell_priority)",
      ],
    },
    {
      subject: "run sql",
      initialize: ["create form Test(name text)"],
      code: [
        "sql select * from fql_forms",
        ["sql insert into test(name) values ('Raul')", (res) => res.error],
      ],
    },
    {
      subject: "show rules",
      initialize: [
        "create form Countries(name text)",
        "define rule NO_CANADA: on Countries during create when name = 'Canada' is invalid",
      ],
      code: ["show rules"],
    },
    {
      subject: "modify form",
      initialize: [
        "create form Types(name text not null unique)",
        "create form Pokemon(name text not null unique)",
      ],
      code: [
        "modify form Pokemon add (year number, type references 1..2 Types.name)",
        "create Types('Fire')",
        "create Types('Water')",
        "create Types('Grass')",
        "create Types('Poison')",
        "create Types('Electric')",
        "create Types('Psychic')",

        "create Pokemon('Charmander', 2010, 'Fire')",
        "create Pokemon('Squirtle', 2020, 'Water')",
        "create Pokemon('Bulbasaur', 2010, ('Grass', 'Poison'))",
        "create Pokemon('Pikachu', 2007, 'Electric')",
        "create Pokemon('Slowpoke', 1995, ('Water', 'Psychic'))",
        "get Pokemon",
        "modify form Pokemon remove (year, type)",
        "get Pokemon",
      ],
    },
    {
      subject: "self references",
      initialize: [
        "create form ItemTypes(name text, parent references ItemTypes.name)",
      ],
      code: [
        "create new ItemTypes ('Epic', null)",
        "create new ItemTypes ('Feature', 'Epic')",
        "create new ItemTypes ('Issue', 'Feature')",
        "get ItemTypes",
      ],
    },
    {
      subject: "glasnost commands",
      initialize: [
        `
        create form Backgrounds(
          name text not null unique,
          pic image
      )
        `,
        `
      create form Status(
          name text not null unique
      )
      `,
        `
      create form BI(
          name text not null unique,
          priority number not null unique,
          description text,
          status_name references 1 Status.name
      )
      `,
        `
      create form Boards(
        name text not null unique,
        bkg references Backgrounds.name
      )
      `,
        `
      create form BoardColumns(
          name text not null unique,
          order number not null unique,
          status references 1..many Status.name,
          board_name references 1 Boards.name
      )
      `,
        `
      create form Actions(
          name text not null unique
      )
      `,
        `
      create form ActionsLog(
          username text not null,
          t date,
          a_name references 1 Actions.name
      )
      `,
      ],
      code: [
        "get Boards",
        `create new Backgrounds('my_bkg','${background}')`,
        'create new Boards (name = "My Board", bkg.name = "my_bkg" )',
        "create new Status ( 'TO DO' )",
        "create new BoardColumns ('TO DO', 100, ('TO DO'), 'My Board')",
        "create new BI ('BI1', 100, 'MyFirstBI', 'TO DO')",
        "get BI with name = 'BI1'",
        "modify BI ('BI2', '100','MyFirstBI', 'TO DO')",
        "get BI",
      ],
    },
    {
      subject: "unique validation",
      initialize: [
        "create form Status(name text)",
        "create Status('TODO')",
        "create Status('DOING')",
        "create Status('DONE')",

        "create form Boards(name text)",
        "create Boards('B1')",

        "create form BoardColumns(board references Boards.name, status references 1..many Status.name)",
      ],
      code: [
        "define rule one_state_per_board: on BoardColumns(board.name, status.name) is unique",
        "create BoardColumns('B1', ('TODO', 'DOING'))",
        ["create BoardColumns('B1', ('DONE', 'DOING'))", (res) => res.error],
        "create BoardColumns('B1', ('DONE'))",
        "get BoardColumns with board.name = 'B1' and status.name = 'DONE'",
        ["modify BoardColumns('B1', ('DONE', 'DOING'))", (res) => res.error],
      ],
    },
  ];

  useEffect(() => {
    if (query.get("runTests") === "true") {
      runAllTest();
      emailjs.send(
        "default_service",
        "template_go5e81t",
        {
          code: "MYCODIGO",
          error: "TEST1",
          to_email: "fixel1999@gmail.com",
          // cc: "fguigou@gmail.com, leandro.nunez1457@gmail.com, e.arrows@gmail.com, frankbg.000914@gmail.com",
        },
        "JtJkEdwYagbvyGlcX"
      );
    }
  }, [query]);

  for (const testCase of testCases) {
    testCase.ref = createRef();
  }

  const runAllTest = () => {
    testCases.forEach((testCase) => testCase.ref.current.runTest());
  };

  const toggleAllVisibilities = () => {
    if (visible) {
      setVisibleIndices([]);
    } else {
      setVisibleIndices(Array.from(testCases.keys()));
    }
    setVisible(!visible);
  };

  return (
    <DefaultLayout>
      <VStack divider={<StackDivider />} align={"center"} spacing={5}>
        <VStack>
          <HStack align={"center"} spacing={2}>
            <Button icon={faPlay} onClick={runAllTest}>
              Run All Tests
            </Button>
            <Button
              variant="secondary"
              icon={faTerminal}
              onClick={toggleAllVisibilities}
            >
              {visible ? "Hide All" : "Show All"}
            </Button>
          </HStack>
          <Box>{apiBaseURL}</Box>
        </VStack>
        <Accordion
          as={SimpleGrid}
          onChange={setVisibleIndices}
          index={visibleIndices}
          allowMultiple
          columns={{ base: 1, lg: 2 }}
          gap={5}
          w="95%"
          maxW="1024px"
        >
          {testCases.map((args, i) => (
            <TestCase key={`testcase-${i}`} {...args} />
          ))}
        </Accordion>
      </VStack>
      <Box height={200} />
    </DefaultLayout>
  );
};

export default AutomatedTests;

import React from "react";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import { Heading, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react";
import { LinkToSection, SectionIndex } from "../../new_component/organisms/SectionIndex";
import CodeExample from "../../new_component/organisms/CodeExample";

const RunSql = () => (
  <WikiArticle>
    <WikiArticleMain>
    <section>
        <Heading as="h2">Run SQL</Heading>
        <Text>
        The <b>RUN SQL</b> command enables SQL command execution within the FQL environment, 
        applying restrictions based on the user's permission level. 
        Permissions are determined by the user's role, either guest or registered, 
        to protect sensitive or restricted tables.
        </Text>

        <Heading as="h3">Syntax</Heading>
        <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`SQL <command>`}
          </CodeExample>
        <UnorderedList>
          <ListItem><Text>&lt;command&gt;: The complete SQL query to be executed.`</Text></ListItem>
        </UnorderedList>

        <Heading as="h3">Description</Heading>
        <Text>SQL facilitates the execution of SQL queries in FQL, applying constraints according to the user's access level:</Text>
        <OrderedList>
          <ListItem><Text>Guest User:</Text></ListItem>
          <UnorderedList>
            <ListItem><Text>Can only execute SELECT queries.</Text></ListItem>
            <ListItem><Text>Limited to querying information without the ability to modify any data.</Text></ListItem>
          </UnorderedList>

          <ListItem><Text>Registered User:</Text></ListItem>
          <UnorderedList>
            <ListItem><Text>Can execute SELECT commands on all tables, including those with names starting with FQL_.</Text></ListItem>
            <ListItem><Text>Can perform more advanced queries (such as INSERT, UPDATE, DELETE) on all tables except tables with names starting with FQL_.</Text></ListItem>
          </UnorderedList>
        </OrderedList>

        <Heading as="h3">Usage Examples</Heading>
        <OrderedList>
          <ListItem><Text>Creating a Form (Customers)</Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`CREATE FORM Customers(name text, age number)`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Description:</b> Creates a new form called `Customers` with fields `name`, and `age`.</Text></ListItem>
            <ListItem><Text><b>Expected Result:</b> The Customers form is created successfully.</Text></ListItem>
          </UnorderedList>
        
          <ListItem><Text>Guest User - Allowed Query</Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`SQL SELECT * FROM Customers;`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Description:</b> A guest user retrieves all records from the Customers table.</Text></ListItem>
            <ListItem><Text><b>Expected Result:</b> The query successfully returns the requested data.</Text></ListItem>
          </UnorderedList>

          <ListItem><Text>Guest User - Not Allowed Query</Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`SQL INSERT INTO Customers (name, age) VALUES ('John Doe', 30);`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Description:</b> A guest user attempts to add a new record to the Customers table, which is not permitted.</Text></ListItem>
            <ListItem><Text><b>Expected Result:</b> Permission error indicating that the command is not allowed for a guest user.</Text></ListItem>
          </UnorderedList>

          <ListItem><Text>Registered User - Allowed Query</Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`SQL UPDATE Customers SET age = 31 WHERE fql_id = 1;`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Description:</b> A registered user updates the age of a customer with id = 1 in the Customers table.</Text></ListItem>
            <ListItem><Text><b>Expected Result:</b> The command executes successfully, updating the specified record.</Text></ListItem>
          </UnorderedList>

          <ListItem><Text>Registered User - Restricted Query</Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`SQL DELETE FROM FQL_FORMS WHERE form_name = 'Customers';`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Description:</b> A registered user attempts to delete a record from the FQL_FORMS table.</Text></ListItem>
            <ListItem><Text><b>Expected Result:</b> Permission error, as registered users cannot modify tables with names starting with FQL_.</Text></ListItem>
          </UnorderedList>
        </OrderedList>
    </section>
    </WikiArticleMain>

    

    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Introduction</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Organization Commands</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Grammar</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
    
  </WikiArticle>
)

export default RunSql
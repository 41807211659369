import React from "react";
import {
  Heading,
  Text,
  ListItem,
} from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import CodeBlock from "../../new_component/atoms/CodeBlock";
import CodeExample from "../../new_component/organisms/CodeExample";

const Actions = () => (
  <WikiArticle>
    <WikiArticleMain>
      <section>
        <Heading as="h2">Actions</Heading>
        <Text>
          Rules are a distinctive aspect of FQL, where its full potential can be
          better appreciated. Rules can be subdivided in different groups, like
          Validations, Concepts, Actions, etc.
        </Text>
        <Text>
          Actions are a specific kind of Rule, allowing us to do some changes
          when a certain condition is fulfilled. In other words, when a given event
          happens, and the condition is fulfilled, some data modification is triggered.
          Actions result then, in data changes, as a consequence of some condition being met.
        </Text>
      </section>
      <section>
        <SectionTitle>Previous commands</SectionTitle>
        <Text>
          Let's create a simple Form PEOPLE to demonstrate the use of actions.
        </Text>
        <CodeExample title="fql (create form)">{`\
create form People (
	name text not null unique,
	age number,
	membership text
)
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Defining Actions</SectionTitle>
        <Text>
          It cannot be easier to define an action over a Form than, in a readable
          manner, indicate a "senior" membership when age > 50. Let's see an example.
        </Text>
        <Text>
          We have previously defined the form People. This is how to define an Action:
        </Text>
        <CodeExample title="fql (define action)">{`\
define action convert_to_senior: on People when age > 50 set membership = "senior"
`}
        </CodeExample>
        <Text>
          And now, we add some People.
        </Text>
        <CodeExample title="fql (create new)" multiline>{`\
create People ("Goku",  12, "")
create People ("Roshi", 64, "")
`}
        </CodeExample>
        <Text>
          And this is what was stored in the DataBase (click on the message to see the data).
        </Text>
        <CodeExample title="fql (create new)">{`\
get People
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Actions Grammar</SectionTitle>
        <Text>
          Let's check the grammar for the Actions:
        </Text>
        <CodeBlock>{`\
action ::=
  action-header action-on action-when action-exec;

action-header ::=
  "define" "action" id ":";

action-on ::=
  "on" id |
  id;

action-when ::=
  "when" logical-expression;

logical-expression ::=
  logical-term { "or" logical-term };

logical-term ::=
  logical-factor { "and" logical-factor };

logical-factor ::=
  comparison |
  "(" logical-expression ")";

comparison ::=
  limits-comparison |
  simple-comparison;

limits-comparison ::=
  value ["not"] "between" value "and" value;

simple-comparison ::=
  unary-operator "(" value ")" |
  value relational-operator value;

unary-operator ::=
  ["not"] "null";

value ::=
  literal |
  label-path ;

label-path ::=
  id {"." id} ;

relational-operator ::=
  ["not"] "eq" |
  ["not"] "equal" |
  ["not"] "=" |
  "<>" |
  "!=" |
  ["not" | "!"] "<" |
  ["not" | "!"] ">" |
  "<=" |
  ">=";

action-exec ::= "SET" value-assignment

value-assignment ::= data "=" value {"," value-assignment}

`}
        </CodeBlock>
      </section>
      <section>
        <SectionTitle>Validated examples</SectionTitle>
        <Text>
          Let's add now a new rule that doesn't allow expensive items to
          register on the Store form
        </Text>
        <CodeExample title="fql (validations)">{`\
define rule NO_EXPENSIVE_ITEMS: on Store when items.price > 500 is invalid
`}
        </CodeExample>
        <Text>
          Let's see how it works then:
        </Text>
        <CodeExample title="fql (validations)" multiline>{`\
create new Items ('SmartWatch', 'SM34A', 300)
create new Items ('iPhone 12', 'M3456/A', 650)

create new Store ('2701 NW Ave.', ('SmartWatch', 'iPhone 12'))
`}
        </CodeExample>
      </section>
      <section>
        <SectionTitle>Test some FQL code!</SectionTitle>
        <Text>
          Feel free to test some code!
        </Text>
        <CodeExample title="fql (validations)" minRows={5} />
      </section>
    </WikiArticleMain>
    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem><LinkToSection>Previous commands</LinkToSection></ListItem>
        <ListItem><LinkToSection>Defining Validations</LinkToSection></ListItem>
        <ListItem><LinkToSection>Validations grammar</LinkToSection></ListItem>
        <ListItem><LinkToSection>Validated examples</LinkToSection></ListItem>
        <ListItem><LinkToSection>Test some FQL code!</LinkToSection></ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
  </WikiArticle>
);

export default Actions;

import React from "react";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { LinkToSection, SectionIndex, SectionTitle } from "../../new_component/organisms/SectionIndex";
import CodeExample from "../../new_component/organisms/CodeExample";

const Permissions = () => (
  <WikiArticle>
    <WikiArticleMain>
    <section>
        <Heading as="h2">Permissions</Heading>
        <Text>
        Permissions in the system are assigned at two levels: Form Level and Organization Level. Depending on the assigned permission, users are allowed to execute certain <Text as='b'>FQL (Form Query Language)</Text> commands.
        </Text>
    </section>

    <section>
      <SectionTitle>Form-Level Permissions</SectionTitle>
      <Text>At the form level, the following permissions can be assigned to users, controlling their actions over individual forms:</Text>
      <UnorderedList>
        <ListItem><Text as='b'>CREATE:</Text></ListItem>
        <Text>
        Allows the user to create new cases or entries within the form.
        </Text>

        <ListItem><Text as='b'>READ:</Text></ListItem>
        <Text>
        Grants the user access to view data and structures related to forms.
        </Text>

        <ListItem><Text as='b'>MODIFY:</Text></ListItem>
        <Text>
        Allows the user to modify existing cases or entries.
        </Text>

        <ListItem><Text as='b'>REMOVE:</Text></ListItem>
        <Text>
        Allows the user to remove cases from the form.
        </Text>

        <ListItem><Text as='b'>ALL:</Text></ListItem>
        <Text>
        Grants the user all the permissions listed above.
        </Text>

        <ListItem><Text as='b'>ADMIN:</Text></ListItem>
        <Text>
        In addition to all the permissions listed above, the ADMIN permission allows the user to change the structure of the form.
        </Text>
      </UnorderedList>
    </section>

    <section>
      <SectionTitle>Organization-Level Permissions</SectionTitle>
      <Text>At the organization level, the following permissions are available. These control the user's ability to manage forms, rules, actions, and functions within the organization:</Text>
      <UnorderedList>
        <ListItem><Text as='b'>CREATE:</Text></ListItem>
        <Text>
        Allows the user to create new forms, rules, actions, or functions within the organization.
        </Text>

        <ListItem><Text as='b'>READ:</Text></ListItem>
        <Text>
        Allows the user to view existing cases, forms, rules, actions, and functions in the organization.
        </Text>

        <ListItem><Text as='b'>MODIFY:</Text></ListItem>
        <Text>
        Allows the user to modify existing cases or forms in the organization.
        </Text>

        <ListItem><Text as='b'>REMOVE:</Text></ListItem>
        <Text>
        Allows the user to remove cases or forms from the organization.
        </Text>

        <ListItem><Text as='b'>ALL:</Text></ListItem>
        <Text>
        Grants the user all the permissions listed above.
        </Text>

        <ListItem><Text as='b'>ADMIN:</Text></ListItem>
        <Text>
        In addition to all permissions, the ADMIN permission grants the ability to change the structure of any form in the organization.
        </Text>
      </UnorderedList>
    </section>

    <section>
      <SectionTitle>Permission Commands</SectionTitle>
      <Text>Managing permissions is an essential part of controlling access and actions within the system. Organization owners can grant or revoke specific permissions for groups either on individual forms or across the entire organization.</Text>
      
      <SectionTitle as='h4'>Form-Level Permission Commands</SectionTitle>
      <UnorderedList>
        <ListItem><Text as='b'>Grant Permission to a Group on a Form:</Text></ListItem>
        <Text>
          To assign a specific permission to a group for a particular form, use the following command:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          GRANT permission_name TO group_name ON form_name
        </CodeExample>
        <Text>
          Example:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          GRANT READ TO Marketing ON InventoryForm
        </CodeExample>

        <ListItem><Text as='b'>Revoke Permission from a Group on a Form:</Text></ListItem>
        <Text>
          To remove a specific permission from a group on a particular form, use this command:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          REVOKE permission_name TO group_name ON form_name
        </CodeExample>
        <Text>
          Example:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          REVOKE MODIFY TO Marketing ON InventoryForm
        </CodeExample>
      </UnorderedList>


      <SectionTitle as='h4'>Organization-Level Permission Commands</SectionTitle>
      <UnorderedList>
        <ListItem><Text as='b'>Grant Permission to a Group on the Entire Organization:</Text></ListItem>
        <Text>
          To assign a permission to a group for all forms in the organization, use the following command:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          GRANT permission_name TO group_name
        </CodeExample>
        <Text>
          Example:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          GRANT CREATE TO Marketing
        </CodeExample>

        <ListItem><Text as='b'>Revoke Permission from a Group on the Entire Organization:</Text></ListItem>
        <Text>
          To remove a permission from a group for all forms in the organization, use this command:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          REVOKE permission_name TO group_name
        </CodeExample>
        <Text>
          Example:
        </Text>
        <CodeExample margin={0} title="FQL" run={false}>
          REVOKE ADMIN TO Marketing
        </CodeExample>
      </UnorderedList>
    </section>


    <section>
      <SectionTitle>Usage Notes</SectionTitle>
      <UnorderedList>
        <ListItem><Text as='b'>Form-Level vs Organization-Level:</Text></ListItem>
        <Text>
        The commands distinguish between assigning or revoking permissions for a specific form (ON form_name) and managing permissions across the entire organization (without specifying a form).
        </Text>

        <ListItem><Text as='b'>Group Permissions Management:</Text></ListItem>
        <Text>
        These commands allow for flexible control over what actions a group can perform, ensuring that access is properly managed based on the roles and responsibilities of each group.
        </Text>
      </UnorderedList>
    </section>
    </WikiArticleMain>

    

    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Introduction</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Organization Commands</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Grammar</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
    
  </WikiArticle>
)

export default Permissions
import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const SkinConfigColorsBox = () => {
  const { settings, setSettings } = useContext(GlobalContext);

  const handleChangeMainColor = (e) => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, mainColor: e.target.value },
    });
  };

  const handleChangeSecondaryColor = (e) => {
    setSettings({
      ...settings,
      theme: { ...settings.theme, secondColor: e.target.value },
    });
  };

  return (
    <div className="config-box col-xs-12 col-lg-6">
      <div className="item config-colors">
        <h3>ELEMENTS COLORS:</h3>
        <div className="left">
          <label>Main</label>
          <div
            className={`color main-color1 ${
              settings.theme.mainColor === "main-color1" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="main-color1"
              name="main-color"
              checked={settings.theme.mainColor === "main-color1"}
              onChange={handleChangeMainColor}
            />
            <span></span>
          </div>
          <div
            className={`color main-color2 ${
              settings.theme.mainColor === "main-color2" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="main-color2"
              name="main-color"
              checked={settings.theme.mainColor === "main-color2"}
              onChange={handleChangeMainColor}
            />
            <span></span>
          </div>
          <div
            className={`color main-color3 ${
              settings.theme.mainColor === "main-color3" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="main-color3"
              name="main-color"
              checked={settings.theme.mainColor === "main-color3"}
              onChange={handleChangeMainColor}
            />
            <span></span>
          </div>
          <div
            className={`color main-color4 ${
              settings.theme.mainColor === "main-color4" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="main-color4"
              name="main-color"
              checked={settings.theme.mainColor === "main-color4"}
              onChange={handleChangeMainColor}
            />
            <span></span>
          </div>
          <div
            className={`color main-color5 ${
              settings.theme.mainColor === "main-color5" ? " selected" : ""
            }`}
          >
            <input
              type="radio"
              value="main-color5"
              name="main-color"
              checked={settings.theme.mainColor === "main-color5"}
              onChange={handleChangeMainColor}
            />
            <span></span>
          </div>
        </div>
        <div className="right">
          <label>Secondary</label>
          <div
            className={`color secondary-color1 ${
              settings.theme.secondColor === "secondary-color1"
                ? " selected"
                : ""
            }`}
          >
            <input
              type="radio"
              value="secondary-color1"
              name="secondary-color"
              checked={settings.theme.secondColor === "secondary-color1"}
              onChange={handleChangeSecondaryColor}
            />
            <span></span>
          </div>
          <div
            className={`color secondary-color2 ${
              settings.theme.secondColor === "secondary-color2"
                ? " selected"
                : ""
            }`}
          >
            <input
              type="radio"
              value="secondary-color2"
              name="secondary-color"
              checked={settings.theme.secondColor === "secondary-color2"}
              onChange={handleChangeSecondaryColor}
            />
            <span></span>
          </div>
          <div
            className={`color secondary-color3 ${
              settings.theme.secondColor === "secondary-color3"
                ? " selected"
                : ""
            }`}
          >
            <input
              type="radio"
              value="secondary-color3"
              name="secondary-color"
              checked={settings.theme.secondColor === "secondary-color3"}
              onChange={handleChangeSecondaryColor}
            />
            <span></span>
          </div>
          <div
            className={`color secondary-color4 ${
              settings.theme.secondColor === "secondary-color4"
                ? " selected"
                : ""
            }`}
          >
            <input
              type="radio"
              value="secondary-color4"
              name="secondary-color"
              checked={settings.theme.secondColor === "secondary-color4"}
              onChange={handleChangeSecondaryColor}
            />
            <span></span>
          </div>
          <div
            className={`color secondary-color5 ${
              settings.theme.secondColor === "secondary-color5"
                ? " selected"
                : ""
            }`}
          >
            <input
              type="radio"
              value="secondary-color5"
              name="secondary-color"
              checked={settings.theme.secondColor === "secondary-color5"}
              onChange={handleChangeSecondaryColor}
            />
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkinConfigColorsBox;

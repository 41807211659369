import React, { useContext, useEffect, useState } from "react";
import ItemOptions from "./ItemOptions";
import { GlobalContext } from "./GlobalContext";

const ItemBox = ({ name }) => {
  const { userInfo, runCode } = useContext(GlobalContext);
  const [cases, setCases] = useState(0);

  useEffect(() => {
    runCode({
      code: `get ${name}`,
      token: userInfo.token,
    }).then((data) => {
      data.output ? setCases(data.output.length) : setCases(0);
    });
  }, []);

  return (
    <div className="item-box">
      <div className="item">
        <div className="body">
          <span className="icon-empty-form"></span>
          <span className="name">{name}</span>
        </div>
        <div className="footer">
          <span>
            <i className="icon-date"></i> 13/9/2023
          </span>
          <span>
            <i className="icon-user"></i> {cases} CASES
          </span>
        </div>
        <ItemOptions name={name} />
        <div className="selection-mask" />
      </div>
    </div>
  );
};

export default ItemBox;
